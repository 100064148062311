import { gql } from '@apollo/client';

import type { ALARM_DISMISS_TYPE } from '~/types/alarm';

export interface RequestDismissAlarmMutationVariables {
  alarmId: string;
  reason: ALARM_DISMISS_TYPE;
}

export interface RequestDismissAlarmMutationData {
  requestDismissAlarm: {
    __typename: 'Alarm';
    id: string;
  };
}

export default gql`
  mutation MutationRequestDismissAlarm($alarmId: ID!, $reason: AlarmDismissType!) {
    requestDismissAlarm(alarm_id: $alarmId, reason: $reason) {
      id
    }
  }
`;
