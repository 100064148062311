import { gql } from '@apollo/client';

import { getDeviceConfigurationQueryBody } from '~/apollo/operations/shared';
import type { DeviceConfigurationOverride } from '~/types/configurationDevice';

export interface DeviceConfigurationOverridesForAgentsQueryVariables {
  subsidiaryId: string;
  nextToken: string | null;
}

export interface DeviceConfigurationOverridesForAgentsQueryData {
  subsidiary: {
    carriers: {
      items: {
        configuration: DeviceConfigurationOverride;
      }[];
    };
  };
}

export default gql`
  query QueryDeviceConfigurationOverridesForAgents($subsidiaryId: ID!) {
    subsidiary(id: $subsidiaryId) {
      carriers(limit: 1000) {
        items {
          configuration(type: "device", name: "base") ${getDeviceConfigurationQueryBody({ isOverride: true })}
        }
      }
    }
  }
`;
