import { memo, useMemo } from 'react';
import { BsCameraReelsFill } from 'react-icons/bs';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { MdSettingsInputComponent } from 'react-icons/md';
import styled from 'styled-components';

import useAlarmsContext from '~/context/useAlarmsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AGENT_STATUS, type Agent } from '~/types/agent';
import { ALARM_LEVEL } from '~/types/alarm';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';
import getHighestAlarmLevel from '~/utils/alarm/getHighestAlarmLevel';

import PaperCard from './PaperCard';
import SectionAlerts from './SectionAlerts';
import SectionEquipment from './SectionEquipment';
import useAgentEquipment from './SectionEquipment/hooks/useAgentEquipment';
import SectionTitle from './SectionTitle';
import SectionVideoStreaming from './SectionVideoStreaming';

const WrapperDiv = styled.div`
  margin-top: 16px;
`;

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 16px;

  ${theme.medias.extraSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

interface Props {
  agent: Agent | undefined;
  'data-id'?: string;
}

const AgentFullCard = memo(({ agent, 'data-id': dataId }: Props) => {
  const hasAlert = agent?.status === AGENT_STATUS.alert;

  const { equipmentItems } = useAgentEquipment(agent);

  const { ongoingAlarms } = useAlarmsContext();

  const agentAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.carrier.id === agent?.id),
    [agent, ongoingAlarms],
  );

  const alarmLevel = useMemo(() => getHighestAlarmLevel(agentAlarms), [agentAlarms]);
  const alarmColor = useMemo(
    () => getAlarmColorFromLevel(alarmLevel || ALARM_LEVEL.Critical),
    [alarmLevel],
  );

  return (
    <WrapperDiv data-id={dataId}>
      <SectionTitle
        data-id="SectionTitle-SectionAlerts"
        title={
          <div
            data-id="sos-alert-title"
            style={{ color: hasAlert ? alarmColor.text : theme.colors.darkBlue }}
          >
            {i18n.t('common.alarms')}
          </div>
        }
        icon={
          <IoMdNotificationsOutline
            color={hasAlert ? alarmColor.text : theme.colors.darkBlue}
            size={25}
          />
        }
      />
      <PaperCard style={{ marginBottom: '16px' }}>
        <SectionAlerts agent={agent} />
      </PaperCard>
      <GridDiv>
        {equipmentItems.length > 0 && (
          <div>
            <SectionTitle
              data-id="SectionTitle-SectionEquipment"
              title={i18n.t('carrierDetailsPopup.informationTypes.equipment')}
              icon={<MdSettingsInputComponent color={theme.colors.darkBlue} size={23} />}
            />
            <SectionEquipment agent={agent} />
          </div>
        )}
        <div>
          <SectionTitle
            data-id="SectionTitle-SectionVideoStreaming"
            title={i18n.t('carrierDetailsPopup.informationTypes.videoStreaming')}
            icon={<BsCameraReelsFill color={theme.colors.darkBlue} size={20} />}
          />
          <SectionVideoStreaming agent={agent} />
        </div>
      </GridDiv>
    </WrapperDiv>
  );
});

AgentFullCard.displayName = 'AgentFullCard';

export default AgentFullCard;
