import type { LogsInitConfiguration } from '@datadog/browser-logs';
import type { RumInitConfiguration } from '@datadog/browser-rum';

import variables from '~/config/variables';

const sharedConfig = {
  clientToken: 'pubc84a3816970c4c8a8e85df61b97cebb3',
  site: 'datadoghq.eu',
  service: 'dashboard-v3',
  env: variables.environment,
  version: variables.version || 'development',
  sessionSampleRate: 100,
} satisfies RumInitConfiguration | LogsInitConfiguration;

export const datadogRumConfig: RumInitConfiguration = {
  ...sharedConfig,
  applicationId: 'e92a1700-6ab7-4c9b-8f40-e874b5bc3afd',
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
};

export const datadogLogsConfig: LogsInitConfiguration = {
  ...sharedConfig,
  forwardConsoleLogs: 'all',
};
