import { useQuery, type ApolloError } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useMemo, useState } from 'react';

import i18n from '~/locales/i18n';
import { AGENT_STATUS } from '~/types/agent';
import type { LocationHistoryPoint } from '~/types/locationHistory';
import logger from '~/utils/logger';
import notification from '~/utils/notification';

import QUERY_CARRIER_LOCATION_HISTORY, {
  type CarrierLocationHistoryQueryData,
  type CarrierLocationHistoryQueryVariables,
} from './queries/QueryCarrierLocationHistory';

export default function useQueryCarrierLocationHistory({
  carrierId,
}: {
  carrierId: string | undefined;
}): {
  locationPoints: LocationHistoryPoint[];
  isLoading: boolean;
  error: ApolloError | undefined;
  refetchLocationPoints: () => void;
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  fetchNextPage: () => void;
} {
  const { loading, error, data, fetchMore, refetch } = useQuery<
    CarrierLocationHistoryQueryData,
    CarrierLocationHistoryQueryVariables
  >(QUERY_CARRIER_LOCATION_HISTORY, {
    variables: {
      carrierId: carrierId || '',
      nextToken: null,
    },
    skip: !carrierId,
    fetchPolicy: 'network-only',
    onError: (e) => {
      logger.error('useQueryCarrierLocationHistory: query error', {
        error: e,
        carrierId,
      });
    },
  });

  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false);

  const nextToken = data?.carrier?.device?.location_history?.nextToken || null;

  useEffect(() => {
    setIsNextPageLoading(false);
  }, [nextToken]);

  const fetchNextPage = useCallback(() => {
    setIsNextPageLoading(true);
    fetchMore({
      variables: {
        nextToken,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => ({
        ...previousResult,
        carrier: {
          ...previousResult.carrier,
          device: {
            ...previousResult.carrier?.device,
            location_history: {
              ...previousResult.carrier?.device?.location_history,
              // Merge duplicates and sort by date
              items: [
                ...(previousResult.carrier?.device?.location_history?.items || []),
                ...(fetchMoreResult.carrier?.device?.location_history?.items || []),
              ],
              nextToken: fetchMoreResult.carrier?.device?.location_history?.nextToken,
            },
          },
        },
      }),
    }).catch((e) => {
      logger.error('useQueryCarrierRecentGps: error', e);
      notification.error({
        message: i18n.t('general.notifications.fetchDataErrorTitle'),
        description: i18n.t('general.notifications.fetchDataErrorDescription'),
      });
    });
  }, [nextToken, fetchMore]);

  const sortedLocationPoints = useMemo(
    () =>
      orderBy(data?.carrier?.device?.location_history?.items || [], ['timestamp'], ['asc']).map(
        (item) => ({
          ...item,
          // TODO: Remove this mapping when status is implemented on the location history API
          status: AGENT_STATUS.inMission,
        }),
      ),
    [data?.carrier?.device?.location_history?.items],
  );

  return useMemo(
    () => ({
      locationPoints: sortedLocationPoints,
      isLoading: loading,
      error,
      refetchLocationPoints: refetch,
      hasNextPage: !!nextToken,
      isNextPageLoading,
      fetchNextPage,
    }),
    [sortedLocationPoints, loading, error, refetch, nextToken, isNextPageLoading, fetchNextPage],
  );
}
