export default function parseBoolean(text: string): boolean | string {
  switch (text) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return text;
  }
}
