import { useQuery, useReactiveVar } from '@apollo/client';
import { useMemo, useState } from 'react';
import useInterval from 'react-use/lib/useInterval';

import QUERY_ME, { type MeQueryData } from '~/apollo/operations/queries/QueryMe';
import { reconnectTimestampVar } from '~/apollo/reactiveVariables/reconnectTimestampVar';
import type { NavigatorConnectionData } from '~/types/connection';
import logger from '~/utils/logger';

const ONLINE_STATUS_CHECK_PERIOD_MS = 1_000;
const APPSYNC_STATUS_CHECK_PERIOD_MS = 30_000;

export default function useConnectionStatus(): {
  isNavigatorOnline: boolean;
  isAppsyncOnline: boolean;
  navigatorConnection: NavigatorConnectionData | null;
  lastCheckTimestamp: number;
  lastOnlineTimestamp: number;
  appsyncReconnectedTimestamp: number;
} {
  const { error, refetch } = useQuery<MeQueryData>(QUERY_ME, { fetchPolicy: 'network-only' });

  const reconnectTimestampValue = useReactiveVar(reconnectTimestampVar);

  const [isNavigatorOnline, setIsNavigatorOnline] = useState<boolean>(true);
  const [isAppsyncOnline, setIsAppsyncOnline] = useState<boolean>(true);
  const [navigatorConnection, setNavigatorConnection] = useState<NavigatorConnectionData | null>(
    null,
  );

  const [lastCheckTimestamp, setLastCheckTimestamp] = useState<number>(0);
  const [lastOnlineTimestamp, setLastOnlineTimestamp] = useState<number>(0);
  const [appsyncReconnectedTimestamp, setAppsyncReconnectedTimestamp] = useState<number>(0);

  useInterval(() => {
    // A simple check for online / offline status.
    // It does not provice guarantee that the server is reachable.
    // It doesn't even guarantee that connection to internet works.
    // It only says there is some network connectivity if onLine is true.
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
    const online = window.navigator.onLine;
    if ('connection' in window.navigator) {
      const connection = window.navigator?.connection as NavigatorConnectionData;
      setNavigatorConnection(connection);
    } else {
      setNavigatorConnection(null);
    }
    const now = Date.now();
    if (online) {
      setLastOnlineTimestamp(now);
    }
    setLastCheckTimestamp(now);
    setIsNavigatorOnline(online);
  }, ONLINE_STATUS_CHECK_PERIOD_MS);

  useInterval(() => {
    logger.log('useConnectionStatus: testing appsync connection', {
      timestamp: new Date().toISOString(),
    });
    refetch();
    if (error) {
      logger.log('useConnectionStatus: connection error');
      setIsAppsyncOnline(false);
    } else if (!isAppsyncOnline) {
      logger.log('useConnectionStatus: reconnected');
      setIsAppsyncOnline(true);
      const now = Date.now();
      setAppsyncReconnectedTimestamp(now);
      if (now !== reconnectTimestampValue) {
        reconnectTimestampVar(now);
      }
    }
  }, APPSYNC_STATUS_CHECK_PERIOD_MS);

  return useMemo(
    () => ({
      isNavigatorOnline,
      isAppsyncOnline,
      navigatorConnection,
      lastCheckTimestamp,
      lastOnlineTimestamp,
      appsyncReconnectedTimestamp,
    }),
    [
      isNavigatorOnline,
      isAppsyncOnline,
      navigatorConnection,
      lastCheckTimestamp,
      lastOnlineTimestamp,
      appsyncReconnectedTimestamp,
    ],
  );
}
