import { Button, Modal } from 'antd';
import { memo, useCallback, useState, type ReactNode } from 'react';
import styled from 'styled-components';

import i18n from '~/locales/i18n';
import type { ModalProps } from '~/types/modal';

const DescriptionDiv = styled.div`
  margin-top: 12px;
`;

const ActionsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

export interface ConfirmationModalProps {
  title: ReactNode;
  description: ReactNode;
  actionType: 'primary' | 'danger';
  action: () => Promise<void>;
}

const ConfirmationModal = memo(
  ({
    isOpen,
    onClose,
    title,
    description,
    actionType,
    action,
  }: ModalProps & ConfirmationModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCancel = useCallback(() => {
      onClose();
    }, [onClose]);

    const handleOk = useCallback(() => {
      (async () => {
        setIsLoading(true);
        await action();
        setIsLoading(false);
        onClose();
      })();
    }, [action, onClose]);

    return (
      <Modal
        title={title || i18n.t('confirmationModal.defaultTitle')}
        footer={null}
        centered
        width={500}
        open={isOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <DescriptionDiv>
          {description || i18n.t('confirmationModal.defaultDescription')}
        </DescriptionDiv>
        <ActionsDiv>
          <Button
            data-id="ConfirmationModal-buttonCancel"
            onClick={handleCancel}
            disabled={isLoading}
          >
            {i18n.t('common.cancel')}
          </Button>
          <Button
            data-id="ConfirmationModal-buttonOk"
            onClick={handleOk}
            type="primary"
            danger={actionType === 'danger'}
            loading={isLoading}
          >
            {i18n.t('common.yes')}
          </Button>
        </ActionsDiv>
      </Modal>
    );
  },
);

ConfirmationModal.displayName = 'ConfirmationModal';

export default ConfirmationModal;
