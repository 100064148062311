import { memo } from 'react';
import styled from 'styled-components';

import EditProfileForm from '~/components/forms/EditProfileForm';
import LanguageSelect from '~/components/LanguageSelect';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import { maxSectionWidth } from '~/components/settings/config/settingsTabsConfig';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';

export const WrapperDiv = styled.div`
  width: ${maxSectionWidth}px;
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

const TabProfile = memo(() => {
  const { currentUser } = useCurrentUserContext();

  if (!currentUser) {
    return null;
  }

  return (
    <WrapperDiv>
      <TabSectionHeader
        title={i18n.t('userProfile.languageSelectLabel')}
        data-id="language-select-title"
      />
      <LanguageSelect />
      {currentUser?.company?.name && (
        <>
          <TabSectionHeader title={i18n.t('common.company')} />
          <p>{currentUser.company.name}</p>
        </>
      )}
      {currentUser?.subsidiary?.name && (
        <>
          <TabSectionHeader title={i18n.t('common.subsidiary')} />
          <p>{currentUser.subsidiary.name}</p>
        </>
      )}
      {currentUser?.__typename && (
        <>
          <TabSectionHeader title={i18n.t('common.role')} />
          <p>{i18n.t(`roles.${currentUser.__typename}`)}</p>
        </>
      )}
      <TabSectionHeader title={i18n.t('userProfile.personalInfo')} />
      <EditProfileForm />
    </WrapperDiv>
  );
});

TabProfile.displayName = 'TabProfile';

export default TabProfile;
