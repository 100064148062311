import { memo } from 'react';
import { MdCloudOff } from 'react-icons/md';
import styled from 'styled-components';

import i18n from '~/locales/i18n';
import theme from '~/theme';

const OverlayDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${theme.layers.offlineOverlay};
  user-select: none;
`;

const ContentDiv = styled.div`
  text-align: center;
  color: #ffffff;
`;

const IconDiv = styled.div`
  font-size: 48px;
  margin-bottom: 16px;
`;

const MessageH2 = styled.h2`
  padding: 0 16px;
  font-size: 24px;
  margin: 0;
`;

const OfflineOverlay = memo(() => (
  <OverlayDiv>
    <ContentDiv>
      <IconDiv>
        <MdCloudOff />
      </IconDiv>
      <MessageH2>{i18n.t('offlineOverlay.message')}</MessageH2>
    </ContentDiv>
  </OverlayDiv>
));

OfflineOverlay.displayName = 'OfflineOverlay';

export default OfflineOverlay;
