import { Button } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import i18n from '~/locales/i18n';

const BottomFlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  email: string;
  onClose: (() => void) | undefined;
}

const AccountCreated = memo(({ email, onClose }: Props) => (
  <div data-id="accountCreated_success">
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: i18n.t<string>('accountCreated.completedTextHtml', {
          email,
        }),
      }}
    />
    <div style={{ margin: '1rem 0' }}>
      <b>{i18n.t('accountCreated.completedExpiryText')}</b>
    </div>
    <BottomFlexDiv>
      <Button onClick={onClose}>{i18n.t('common.close')}</Button>
      <Button type="primary" onClick={onClose} data-id="accountCreated_okButton">
        OK
      </Button>
    </BottomFlexDiv>
  </div>
));

AccountCreated.displayName = 'AccountCreated';

export default AccountCreated;
