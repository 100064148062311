import { memo, useMemo } from 'react';

import SectionList from '~/components/DetailsPopup/components/SectionList';
import useAlarmsContext from '~/context/useAlarmsContext';
import i18n from '~/locales/i18n';
import { AGENT_STATUS, type Agent } from '~/types/agent';
import type { GasAlarmValue } from '~/types/alarm';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import getAgentStatusTranslation from '~/utils/agent/getAgentStatusTranslation';
import parseJSON from '~/utils/parse/parseJSON';

import AlertItem from './components/AlertItem';

interface Props {
  agent: Agent | undefined;
}

const SectionAlerts = memo(({ agent }: Props) => {
  const { ongoingAlarms } = useAlarmsContext();

  const agentOngoingAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.carrier.id === agent?.id),
    [agent, ongoingAlarms],
  );

  if (!agent) {
    return null;
  }

  return (
    <div data-id="SectionAlerts">
      {agentOngoingAlarms.map((alarm) => {
        const gasName = (parseJSON(alarm.value) as GasAlarmValue)?.gas;

        return (
          <AlertItem
            key={alarm.id}
            agent={agent}
            labelText={`${i18n.t(`general.generalAlarms.${alarm.type}`)}${gasName ? ` (${gasName})` : ''}`}
            alarm={alarm}
          />
        );
      })}
      {agent.status !== AGENT_STATUS.alert && (
        <SectionList
          elements={[
            {
              labelText: i18n.t(`carrierDetailsPopup.alarms.noAlarms`),
              content: agent.attributes?.plate_number ? (
                <span style={{ color: getAgentStatusColor(agent) }}>
                  {getAgentStatusTranslation(agent)}
                </span>
              ) : null,
              'data-id': 'no-alarms',
            },
          ]}
        />
      )}
    </div>
  );
});

SectionAlerts.displayName = 'SectionAlerts';

export default SectionAlerts;
