import { Alert } from 'antd';
import { memo } from 'react';

import useCurrentUserContext from '~/context/useCurrentUserContext';
import i18n from '~/locales/i18n';

const OnlyCompanyAdminsAlert = memo(() => {
  const { isCompanyAdmin, isSuperAdmin } = useCurrentUserContext();

  if (isCompanyAdmin || isSuperAdmin) {
    return null;
  }

  return (
    <Alert
      message={
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: i18n.t<string>('onlyCompanyAdminsAlert.titleHtml') }}
        />
      }
      type="info"
      showIcon
    />
  );
});

OnlyCompanyAdminsAlert.displayName = 'OnlyCompanyAdminsAlert';

export default OnlyCompanyAdminsAlert;
