import { createGlobalStyle } from 'styled-components';

import theme from '~/theme';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    font-family: sans-serif;
  }

  #root {
    position: relative;
    width: 100%;
    height: 100%;
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    border-radius: 5.5px;
    background-color: inherit;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5.5px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5.5px;
    background-color: ${theme.colors.midGrey};
  }

  /* Location history scaling dot */

  .LocationHistoryMap__dot {
    width: 6px;
    height: 6px;
    border-radius: 100%;

    &:hover {
      transform: scale(2);
    }
  }

  /* Remove the close (X) button from Google Maps info windows */

  .gm-style-iw-ch {
    padding-top: 12px !important;
  }

  .gm-ui-hover-effect {
    display: none !important;
  }
`;

export default GlobalStyles;
