import { memo } from 'react';
import { Navigate } from 'react-router-dom';

import routes from '~/config/routes';

const DashboardPage = memo(() => <Navigate to={routes.map()} />);

DashboardPage.displayName = 'DashboardPage';

export default DashboardPage;
