import { create } from 'zustand';

const useUserInteractions = create<{
  userInteractedWithDocument: boolean;
  setUserInteractedWithDocument: (interacted: boolean) => void;
}>((set) => ({
  userInteractedWithDocument: false,
  setUserInteractedWithDocument: (interacted: boolean) =>
    set((state) => ({ ...state, userInteractedWithDocument: interacted })),
}));

export default useUserInteractions;
