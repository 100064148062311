import { Flex, Radio } from 'antd';
import { memo, useMemo, useState } from 'react';
import styled from 'styled-components';

import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import { KNOWN_ALARM_TYPES } from '~/config/defaults';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { ALARM_TYPE } from '~/types/alarm';
import type { AlarmConfiguration } from '~/types/configurationAlarm';

import AlarmConfigurationCollapseList from './components/AlarmConfigurationCollapseList';

const WrapperDiv = styled.div`
  width: 100%;
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

interface Props {
  allAlarmPlatformConfigurations: AlarmConfiguration[];
}

const BottomAlarmConfigurationList = memo(({ allAlarmPlatformConfigurations }: Props) => {
  const [activeTab, setActiveTab] = useState<null | 'unknown'>(null);

  const unknownAlarmTypeConfigurations = useMemo(
    () =>
      allAlarmPlatformConfigurations.filter(
        (alarmConfiguration) => !KNOWN_ALARM_TYPES.includes(alarmConfiguration.name as ALARM_TYPE),
      ),
    [allAlarmPlatformConfigurations],
  );

  return (
    <WrapperDiv>
      <Flex vertical gap="middle">
        <Radio.Group
          size="middle"
          value={activeTab}
          style={{
            userSelect: 'none',
          }}
        >
          <Radio.Button
            value="unknown"
            onClick={() => {
              setActiveTab(activeTab === 'unknown' ? null : 'unknown');
            }}
          >
            {i18n.t('alarmConfigurations.unknownAlarmTypes')} (
            {unknownAlarmTypeConfigurations.length})
          </Radio.Button>
        </Radio.Group>
      </Flex>
      <div style={{ height: '10px' }} />
      {activeTab === 'unknown' && unknownAlarmTypeConfigurations.length > 0 && (
        <>
          <TabSectionHeader
            title={`${i18n.t('alarmConfigurations.unknownAlarmTypeConfigurations')} (${unknownAlarmTypeConfigurations.length})`}
          />
          <AlarmConfigurationCollapseList alarmConfigurations={unknownAlarmTypeConfigurations} />
        </>
      )}
    </WrapperDiv>
  );
});

BottomAlarmConfigurationList.displayName = 'BottomAlarmConfigurationList';

export default BottomAlarmConfigurationList;
