import UsergroupAddOutlined from '@ant-design/icons/UsergroupAddOutlined';
import { Button, Empty, Tooltip } from 'antd';
import { memo } from 'react';

import AgentsTable from '~/components/AgentsTable';
import Container from '~/components/Container';
import Link from '~/components/Link';
import PageContentWrapper from '~/components/PageContentWrapper';
import PageHeader from '~/components/PageHeader';
import SettingsSelectSubsidiary from '~/components/settings/components/SettingsSelectSubsidiary';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import { SettingsTopFlexDiv } from '~/components/settings/shared';
import routes from '~/config/routes';
import useAgentsContext from '~/context/useAgentsContext';
import useInitialLoading from '~/hooks/useInitialLoading';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';

const AgentsPage = memo(() => {
  const { isInitialLoading } = useInitialLoading();
  const { agents, isLoading: isAgentsLoading } = useAgentsContext();
  const { currentSubsidiary } = useSubsidiary();

  const isLoading = isInitialLoading || isAgentsLoading;

  return (
    <PageContentWrapper>
      <Container>
        <PageHeader
          title={i18n.t('agentsPage.title')}
          subtitle={i18n.t('agentsPage.subtitle')}
          style={{ paddingBottom: currentSubsidiary?.id ? 0 : undefined }}
        />
        {currentSubsidiary?.id && (
          <SettingsTopFlexDiv>
            <>
              <TabSectionHeader
                title={
                  isLoading
                    ? i18n.t('common.loading')
                    : `${i18n.t('common.agents')} (${agents.length})`
                }
              />
              <Tooltip title={i18n.t('agentForm.manageAgentsTooltip')} placement="left">
                <div>
                  <Link to={routes.companySettings({ tab: 'agents' })}>
                    <Button
                      data-id={isLoading ? undefined : 'manage-agents-btn'}
                      type="primary"
                      disabled={!currentSubsidiary?.id}
                      loading={isLoading}
                      size="middle"
                      icon={<UsergroupAddOutlined />}
                    >
                      {i18n.t('agentForm.manageAgents')}
                    </Button>
                  </Link>
                </div>
              </Tooltip>
            </>
          </SettingsTopFlexDiv>
        )}
        <AgentsTable />
        {!isLoading && agents.length === 0 && (
          <Empty
            style={{
              background: 'white',
              margin: 0,
              padding: '3rem 16px',
              borderRadius: '10px',
            }}
            description={
              !currentSubsidiary?.id
                ? i18n.t('header.selectSubsidiary')
                : i18n.t('agentForm.noAgentsFound', {
                    subsidiaryName: currentSubsidiary?.name,
                  })
            }
          >
            {!currentSubsidiary?.id ? (
              <SettingsSelectSubsidiary />
            ) : (
              <Link to={routes.companySettings({ tab: 'agents' })}>
                <Button type="primary" icon={<UsergroupAddOutlined />} size="middle">
                  {i18n.t('agentForm.manageAgents')}
                </Button>
              </Link>
            )}
          </Empty>
        )}
      </Container>
    </PageContentWrapper>
  );
});

AgentsPage.displayName = 'AgentsPage';

export default AgentsPage;
