import { useMemo } from 'react';

import useAgentsContext from '~/context/useAgentsContext';
import useTeams from '~/hooks/useTeams';
import { AGENT_STATUS } from '~/types/agent';

export default function useAgentsStatistics(): {
  agentsCount: number;
  agentsStatusCounts: Record<
    AGENT_STATUS,
    {
      all: number;
      missingGPS: number;
    }
  >;
} {
  const { agents } = useAgentsContext();
  const { selectedTeams } = useTeams();

  const activeAgents = useMemo(
    () =>
      agents.filter(
        (agent) =>
          (!agent.isOffline || agent.status === AGENT_STATUS.alert) &&
          (!selectedTeams.length || selectedTeams.includes(agent.team)),
      ),
    [agents, selectedTeams],
  );

  const agentsStatusCounts = useMemo(() => {
    const counts: Record<AGENT_STATUS, { all: number; missingGPS: number }> = {
      [AGENT_STATUS.alert]: { all: 0, missingGPS: 0 },
      [AGENT_STATUS.inMission]: { all: 0, missingGPS: 0 },
      [AGENT_STATUS.inSafeZone]: { all: 0, missingGPS: 0 },
      [AGENT_STATUS.connectionLost]: { all: 0, missingGPS: 0 },
    };
    activeAgents.forEach((agent) => {
      counts[agent.status].all += 1;
      if (!agent.sensors?.gps?.value?.lat || !agent.sensors?.gps?.value?.lng) {
        counts[agent.status].missingGPS += 1;
      }
    });
    return counts;
  }, [activeAgents]);

  return useMemo(
    () => ({
      agentsCount: activeAgents.length,
      agentsStatusCounts,
    }),
    [activeAgents.length, agentsStatusCounts],
  );
}
