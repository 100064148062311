import { memo, type ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import theme from '~/theme';

const WrapperDiv = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const TitleH2 = styled.h2`
  line-height: 1;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.darkBlue};
`;

interface Props {
  icon: ReactNode;
  title: ReactNode;
  style?: CSSProperties;
  'data-id'?: string;
}

const SectionTitle = memo(({ icon, title, style, 'data-id': dataId }: Props) => (
  <WrapperDiv style={style} data-id={dataId}>
    {icon}
    <TitleH2>{title}</TitleH2>
  </WrapperDiv>
));

SectionTitle.displayName = 'SectionTitle';

export default SectionTitle;
