import type { Alarm } from '~/types/alarm';
import { formatRelativeFromNow, formatTime } from '~/utils/dateTime';

export default function computeAlarmStartingTime(alarm: Alarm | undefined): {
  alertTime: string | false;
  alertTimeFormatted: string | false;
  relativeAlertTime: string | false;
} {
  if (!alarm) {
    return {
      alertTime: false,
      alertTimeFormatted: false,
      relativeAlertTime: false,
    };
  }

  const alertTime = alarm.created_at;
  const alertTimeFormatted = alertTime ? formatTime(alertTime, 'readable') : false;
  const relativeAlertTime = alertTime ? formatRelativeFromNow(alertTime) : false;

  return {
    alertTime,
    alertTimeFormatted,
    relativeAlertTime,
  };
}
