import { Select } from 'antd';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import useAgentsContext from '~/context/useAgentsContext';
import i18n from '~/locales/i18n';
import getAgentsOptions from '~/utils/agent/getAgentsOptions';

const StyledAgentSelect = styled(Select)`
  display: block;
`;

interface Props {
  activeAgentId: string;
  setActiveAgentId: (agentId: string) => void;
}

const AgentSelect = memo(({ activeAgentId, setActiveAgentId }: Props) => {
  const { agents } = useAgentsContext();

  const searchAgentOptions = useMemo(
    () =>
      getAgentsOptions(agents).map((agent) =>
        agent.value !== activeAgentId ? agent : { ...agent, disabled: true },
      ),
    [activeAgentId, agents],
  );

  return (
    <StyledAgentSelect
      showSearch
      placeholder={i18n.t('locationHistory.searchAgents')}
      optionFilterProp="children"
      value={activeAgentId}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={searchAgentOptions}
      onChange={(value) => {
        if (value) {
          setActiveAgentId(value as string);
        }
      }}
    />
  );
});

AgentSelect.displayName = 'AgentSelect';

export default AgentSelect;
