import { Tooltip } from 'antd';
import { memo, useMemo } from 'react';

import useReverseGeocoding from '~/hooks/useReverseGeocoding';
import type { GpsCoordinates } from '~/types/sensor';

interface Props {
  coordinates: GpsCoordinates | null | undefined;
}

const LocationItem = memo(({ coordinates }: Props) => {
  const { address } = useReverseGeocoding(coordinates);

  const coordinatesText = useMemo(
    () => [coordinates?.lat, coordinates?.lng].filter(Number.isFinite).join(', ') || '-',
    [coordinates?.lat, coordinates?.lng],
  );

  const textToShow = address || coordinatesText;

  const isDifferent = textToShow !== coordinatesText;

  return (
    <Tooltip title={isDifferent ? coordinatesText : undefined} placement="right">
      <span style={{ cursor: isDifferent ? 'help' : 'default' }}>{textToShow}</span>
    </Tooltip>
  );
});

LocationItem.displayName = 'LocationItem';

export default LocationItem;
