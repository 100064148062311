import type { TableProps } from 'antd';

import i18n from '~/locales/i18n';

const defaultTablePaginationProps: TableProps['pagination'] = {
  defaultPageSize: 50,
  showSizeChanger: false,
  pageSizeOptions: undefined,
  showTotal: (total, range) =>
    i18n.t('table.rangeOfTotal', {
      range: `${range[0]}-${range[1]}`,
      total: total.toString(),
    }),
  hideOnSinglePage: true,
  position: ['bottomLeft'],
  onChange: () => {
    window.scrollTo({
      top: 0,
    });
  },
};

export default defaultTablePaginationProps;
