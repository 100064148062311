import {
  createHttpLink,
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  type NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { memo, useEffect, useState, type ReactNode } from 'react';

import variables from '~/config/variables';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';

interface Props {
  children: ReactNode;
}

const QuickSightReportsWithApi = memo(({ children }: Props) => {
  const { getUserSession, isAuthenticated } = useAuthenticationContext();
  const { currentUser } = useCurrentUserContext();
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | null>(null);

  useEffect(() => {
    const createClient = async () => {
      const token = (await getUserSession())?.getIdToken().getJwtToken();
      const httpLink = createHttpLink({
        uri: variables.datalakeApiUrl,
      });
      const authLink = setContext((_, { headers }) => ({
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : undefined,
        },
      }));
      setClient(
        new ApolloClient({
          link: authLink.concat(httpLink),
          cache: new InMemoryCache(),
          connectToDevTools: false,
        }),
      );
    };
    createClient();
  }, [getUserSession]);

  if (!isAuthenticated || !currentUser || !client) {
    return null;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
});

QuickSightReportsWithApi.displayName = 'QuickSightReportsWithApi';

export default QuickSightReportsWithApi;
