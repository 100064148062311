import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { Button, ConfigProvider, Tooltip } from 'antd';
import { memo, useMemo, useState } from 'react';
import styled from 'styled-components';

import AlarmSteps from '~/components/AlarmSteps';
import GenericItemList, { type GenericItem } from '~/components/GenericItemList';
import TimeAgo from '~/components/TimeAgo';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { ALARM_LEVEL, AlarmWithCarrier } from '~/types/alarm';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';
import isAlarmOngoing from '~/utils/alarm/isAlarmOngoing';
import { formatDateTime } from '~/utils/dateTime';
import getYesOrNo from '~/utils/parse/getYesOrNo';

const GridDiv = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, auto);
  gap: 16px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
    margin-bottom: 8px;
  }
`;

const ViewMoreSpan = styled.span`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const TooltipUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const BottomDiv = styled.div`
  font-size: 12px;
  opacity: 0.5;
`;

const AlarmDetailsTab = memo(
  ({
    alarm,
    openAlarmManageTab,
  }: {
    alarm: AlarmWithCarrier | undefined;
    openAlarmManageTab: () => void;
  }) => {
    const [isValueOpen, setIsValueOpen] = useState<boolean>(false);
    const [isDismissReasonDetailsOpen, setIsDismissReasonDetailsOpen] = useState<boolean>(false);

    const dismissReasonDetailsToggleLink = useMemo(
      () =>
        isDismissReasonDetailsOpen ? (
          alarm?.dismiss_reason_details
        ) : (
          <ViewMoreSpan
            onClick={() => {
              setIsDismissReasonDetailsOpen(true);
            }}
          >
            {i18n.t('alarmsPage.card.viewMore')}
          </ViewMoreSpan>
        ),
      [alarm?.dismiss_reason_details, isDismissReasonDetailsOpen],
    );

    const valueToggleLink = useMemo(
      () =>
        isValueOpen ? (
          alarm?.value
        ) : (
          <ViewMoreSpan
            onClick={() => {
              setIsValueOpen(true);
            }}
          >
            {i18n.t('alarmsPage.card.viewMore')}
          </ViewMoreSpan>
        ),
      [alarm?.value, isValueOpen],
    );

    const descriptions: GenericItem[] = useMemo(
      () => [
        {
          label: i18n.t('alarmsPage.alarmFields.created_at'),
          value: alarm?.created_at ? formatDateTime(alarm.created_at) : undefined,
          tooltip: alarm?.created_at ? (
            <TimeAgo date={alarm?.created_at || new Date()} />
          ) : undefined,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.agent'),
          value: alarm?.carrier?.completeName || undefined,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.status'),
          value: alarm?.status
            ? i18n.t(`general.alarm.status.${alarm.status.toLowerCase()}`)
            : undefined,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.type'),
          value: alarm?.type ? i18n.t(`general.alarm.type.${alarm.type}`) : undefined,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.level'),
          value: alarm?.level
            ? i18n.t(`general.alarm.level.${alarm.level.toLowerCase()}`)
            : undefined,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.source'),
          value: alarm?.source
            ? i18n.t(`general.alarm.source.${alarm.source.toLowerCase()}`)
            : undefined,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.activateVideo'),
          value: getYesOrNo(alarm?.activate_video || false),
        },
        {
          label: i18n.t('alarmsPage.alarmFields.assigned_at'),
          value: alarm?.assigned_at ? formatDateTime(alarm.assigned_at) : undefined,
          tooltip: alarm?.assigned_at ? (
            <TimeAgo date={alarm?.assigned_at || new Date()} />
          ) : undefined,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.administrator'),
          value: alarm?.administrator?.email || alarm?.administrator?.id || undefined,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.dismiss_requested_at'),
          value: alarm?.dismiss_requested_at
            ? formatDateTime(alarm.dismiss_requested_at)
            : undefined,
          tooltip: alarm?.dismiss_requested_at ? (
            <TimeAgo date={alarm?.dismiss_requested_at || new Date()} />
          ) : undefined,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.dismissed_at'),
          value: alarm?.dismissed_at ? formatDateTime(alarm.dismissed_at) : undefined,
          tooltip: alarm?.dismissed_at ? (
            <TimeAgo date={alarm?.dismissed_at || new Date()} />
          ) : undefined,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.dismissed_by'),
          value: alarm?.dismissed_by?.email || alarm?.dismissed_by?.id || undefined,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.dismiss_reason'),
          value: alarm?.dismiss_reason || undefined,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.dismiss_reason_details'),
          value:
            alarm?.dismiss_reason_details && alarm?.dismiss_reason_details !== '{}'
              ? dismissReasonDetailsToggleLink
              : undefined,
        },
        {
          label: i18n.t('alarmsPage.alarmFields.value'),
          value: alarm?.value && alarm?.value !== 'undefined' ? valueToggleLink : undefined,
        },
      ],
      [alarm, dismissReasonDetailsToggleLink, valueToggleLink],
    );

    const isOngoing = isAlarmOngoing(alarm);

    return (
      <>
        <GridDiv>
          <GenericItemList items={descriptions} />
          <RightDiv>
            {isOngoing && (
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: getAlarmColorFromLevel(alarm?.level || ALARM_LEVEL.Warning).text,
                  },
                }}
              >
                <Button
                  style={{ marginBottom: '16px' }}
                  type="primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    openAlarmManageTab();
                  }}
                >
                  {i18n.t('carrierDetailsPopup.alarmControlModal.title')}
                </Button>
              </ConfigProvider>
            )}
            <AlarmSteps alarm={alarm} />
          </RightDiv>
        </GridDiv>
        <BottomDiv>
          <Tooltip
            title={
              <TooltipUl>
                <li>
                  <b>{i18n.t('alarmsPage.alarmFields.assignable')}:</b>{' '}
                  {getYesOrNo(!!alarm?.can_assign)}
                </li>
                <li>
                  <b>{i18n.t('alarmsPage.alarmFields.cancellable')}:</b>{' '}
                  {getYesOrNo(!!alarm?.can_cancel)}
                </li>
                <li>
                  <b>{i18n.t('alarmsPage.alarmFields.dismissable')}:</b>{' '}
                  {getYesOrNo(!!alarm?.can_dismiss_dashboard)}
                </li>
              </TooltipUl>
            }
          >
            <span style={{ cursor: 'help' }}>
              <InfoCircleOutlined />
            </span>
          </Tooltip>{' '}
          <b>{i18n.t('alarmsPage.alarmFields.alarmId')}:</b> {alarm?.id}
        </BottomDiv>
      </>
    );
  },
);

AlarmDetailsTab.displayName = 'AlarmDetailsTab';

export default AlarmDetailsTab;
