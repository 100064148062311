import { useReactiveVar } from '@apollo/client';
import { useEffect } from 'react';

import { NEW_ALERT_DEFAULT_VALUE, newAlertVar } from '~/apollo/reactiveVariables/newAlertVar';
import useAlarmsContext from '~/context/useAlarmsContext';
import useUserInteractions from '~/store/useUserInteractions';
import { playAlarmSound, stopAlarmSound, unmuteAlarmSound } from '~/utils/sounds';

export default function useAlarmSound(): void {
  const { hasAlertBottomBanner, ongoingCriticalAlarms } = useAlarmsContext();

  const { carrierId } = useReactiveVar(newAlertVar);
  const userInteractedWithDocument = useUserInteractions(
    (state) => state.userInteractedWithDocument,
  );

  // Stop alarm sound when the component is unmounted (logout for example)
  useEffect(
    () => () => {
      stopAlarmSound();
    },
    [],
  );

  useEffect(() => {
    if (carrierId) {
      unmuteAlarmSound();
      // The alarm has been shown and it is not "new" anymore so we clear the state
      newAlertVar(NEW_ALERT_DEFAULT_VALUE);
    }
  }, [carrierId]);

  useEffect(() => {
    if (hasAlertBottomBanner && ongoingCriticalAlarms.length > 0) {
      playAlarmSound();
    } else {
      stopAlarmSound();
    }
  }, [userInteractedWithDocument, ongoingCriticalAlarms.length, hasAlertBottomBanner]);
}
