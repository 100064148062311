import { useMemo } from 'react';

import useAgentsContext from '~/context/useAgentsContext';
import useAlarmsContext from '~/context/useAlarmsContext';

export default function useInitialLoading(): { isInitialLoading: boolean } {
  const { isInitialLoading: isAlarmsInitialLoading, isLoading: isAlarmsLoading } =
    useAlarmsContext();
  const { isInitialLoading: isAgentsInitialLoading, isLoading: isAgentsLoading } =
    useAgentsContext();

  return useMemo(
    () => ({
      isInitialLoading:
        isAlarmsInitialLoading || isAlarmsLoading || isAgentsInitialLoading || isAgentsLoading,
    }),
    [isAlarmsInitialLoading, isAlarmsLoading, isAgentsInitialLoading, isAgentsLoading],
  );
}
