import { gql } from '@apollo/client';

import type { VideoRecording } from '~/types/videoRecording';

export interface VideoRecordingQueryVariables {
  videoRecordingId: string;
}

export interface VideoRecordingQueryData {
  video_recording: VideoRecording;
}

export default gql`
  query QueryVideoRecording($videoRecordingId: ID!) {
    video_recording(id: $videoRecordingId) {
      id
      start_recording
      duration_ms
      view_url
      view_cookies {
        name
        value
      }
    }
  }
`;
