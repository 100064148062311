import { gql } from '@apollo/client';

import { MEASUREMENT_QUERY_BODY } from '~/apollo/operations/shared';
import type { SENSOR_NAME_QUERY, SENSOR_STATUS_QUERY } from '~/types/sensor';

export interface SubsidiaryMeasurementSubscriptionVariables {
  subsidiaryID: string;
  type: SENSOR_NAME_QUERY | SENSOR_STATUS_QUERY;
}

export interface SubsidiaryMeasurementSubscriptionData {
  measurements: {
    carrier_id: string;
    device_name: string;
    type: string;
    measurements: {
      timestamp: string;
      value: string; // JSON string
    }[];
  };
}

export default gql`
  subscription SubscribeToSubsidiaryMeasurementType($subsidiaryID: ID!, $type: String!) {
    measurements(subsidiary_id: $subsidiaryID, type: $type) {
      carrier_id
      device_name
      type
      measurements ${MEASUREMENT_QUERY_BODY}
    }
  }
`;
