import { currentLanguage } from '~/locales/i18n';

type Timestamp = string | number | Date;
type FormatType = 'standard' | 'readable';

/**
 * Standard: 2022/12/25
 * Readable: Dec 25. 2022 (en) / 25 déc. 2022 (fr)
 */
export function formatDate(timestamp: Timestamp, format: FormatType = 'standard'): string {
  const isStandard = format === 'standard';

  return new Intl.DateTimeFormat(isStandard ? 'en-ZA' : currentLanguage(), {
    dateStyle: isStandard ? 'short' : 'medium',
  })
    .format(new Date(timestamp))
    .replaceAll(',', '.');
}

/**
 * Standard: 14:30:45
 * Readable: 14:30
 */
export function formatTime(timestamp: Timestamp, format: FormatType = 'standard'): string {
  return new Intl.DateTimeFormat('en-GB', {
    timeStyle: format === 'standard' ? 'medium' : 'short',
    hour12: false,
  }).format(new Date(timestamp));
}

/**
 * Standard: 2022/12/25 14:30:45
 * Readable: Dec 25. 2022, 14:30 (en) / 25 déc. 2022, 14:30 (fr)
 */
export function formatDateTime(timestamp: Timestamp, format: FormatType = 'standard'): string {
  const date = formatDate(timestamp, format);
  const time = formatTime(timestamp, format);
  return `${date}${format === 'standard' ? ' ' : ', '}${time}`;
}

/**
 * Return example: 1671960645
 */
export function formatUnix(timestamp: Timestamp): number {
  return Math.floor(new Date(timestamp).getTime() / 1000);
}

/**
 * Return example: 1 hour ago (en) / il y a 1 heure (fr)
 */
export function formatRelativeFromNow(timestamp: Timestamp): string {
  const relativeTimeFormat = new Intl.RelativeTimeFormat(currentLanguage(), { style: 'long' });

  const seconds = formatUnix(timestamp) - formatUnix(new Date().toISOString());
  const minutes = Math.trunc(seconds / 60);
  const hours = Math.trunc(minutes / 60);
  const days = Math.trunc(hours / 24);
  const months = Math.trunc(days / 30);
  const years = Math.trunc(months / 12);

  if (years !== 0) {
    return relativeTimeFormat.format(years, 'year');
  }
  if (months !== 0) {
    return relativeTimeFormat.format(months, 'month');
  }
  if (days !== 0) {
    return relativeTimeFormat.format(days, 'day');
  }
  if (hours !== 0) {
    return relativeTimeFormat.format(hours, 'hour');
  }
  if (minutes !== 0) {
    return relativeTimeFormat.format(minutes, 'minute');
  }
  return relativeTimeFormat.format(seconds, 'second');
}

export function isToday(timestamp: Timestamp): boolean {
  return (
    !!timestamp &&
    new Date(timestamp).setUTCHours(0, 0, 0, 0) === new Date().setUTCHours(0, 0, 0, 0)
  );
}

export function getBeginningOfTheDayISO() {
  return new Date(new Date().setUTCHours(2, 0, 0, 0)).toISOString();
}

export function computeDuration(timestamp: string, timestampNow?: string): string {
  let duration: string;

  const now = timestampNow ? new Date(timestampNow).getTime() : new Date().getTime();
  const then = new Date(timestamp).getTime();
  const seconds = Math.floor((now - then) / 1000);

  if (seconds < 60) {
    duration = `< 1m`;
  } else {
    let minutes = Math.floor(seconds / 60);

    if (minutes < 60) {
      duration = `${minutes}m`;
    } else {
      const hours = Math.floor(minutes / 60);

      minutes = minutes % 60; // eslint-disable-line

      duration = `${hours}h ${minutes}m`;
    }
  }

  return duration;
}

export function computeDurationDetailed(timestamp: string): string {
  let duration: string;

  const now = new Date().getTime();
  const then = new Date(timestamp).getTime();

  let seconds = Math.floor((now - then) / 1000);

  if (seconds < 60) {
    if (seconds < 1) {
      duration = `< 1s`;
    } else {
      duration = `${seconds}s`;
    }
  } else {
    let minutes = Math.floor(seconds / 60);

    if (minutes < 60) {
      seconds %= 60;
      duration = `${minutes}m ${seconds}s`;
    } else {
      let hours = Math.floor(minutes / 60);
      minutes %= 60;

      if (hours < 24) {
        duration = `${hours}h ${minutes}m`;
      } else {
        const days = Math.floor(hours / 24);
        hours %= 24;

        duration = `${days}d ${hours}h`;
      }
    }
  }

  return duration;
}

export const computeMissionTime = (timestamp: string) =>
  timestamp ? formatTime(timestamp, 'readable') : '';
