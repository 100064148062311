import { memo } from 'react';
import { Navigate } from 'react-router-dom';

import routes from '~/config/routes';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import type { RouteProps } from '~/types/route';

const PublicRoute = memo(({ element }: RouteProps) => {
  const { isAuthenticated } = useAuthenticationContext();

  if (isAuthenticated) {
    return <Navigate to={routes.map()} />;
  }

  return element;
});

PublicRoute.displayName = 'PublicRoute';

export default PublicRoute;
