import { Select } from 'antd';
import { memo, useCallback } from 'react';
import styled from 'styled-components';

import { currentLanguage, getLocaleList, setLocale } from '~/locales/i18n';
import { LOCALE_CODE, type LocaleType } from '~/types/locale';
import curateUrl from '~/utils/parse/curateUrl';

const ContainerDiv = styled.div`
  display: inline-grid;
`;

const FlagImg = styled.img`
  width: 16px;
  margin-right: 6px;
`;

const LOCALE_OPTIONS =
  getLocaleList().map((localeItem: LocaleType) => (
    <Select.Option
      key={localeItem.code}
      value={localeItem.code}
      data-id={`${localeItem.code}-language`}
    >
      <FlagImg src={curateUrl(`/icons/locales/${localeItem.code}.svg`)} alt={localeItem.label} />
      {localeItem.label}
    </Select.Option>
  )) || [];

interface Props {
  label?: string;
  className?: string;
}

const LanguageSelect = memo(({ label, className }: Props) => {
  const handleChange = useCallback((value: LOCALE_CODE) => {
    setLocale(value);
    window.location.reload();
  }, []);

  if (!LOCALE_OPTIONS.length) {
    return null;
  }

  return (
    <ContainerDiv className={className}>
      {label && <h4>{label}</h4>}
      <Select
        style={{ width: 120 }}
        defaultValue={currentLanguage()}
        onChange={handleChange}
        data-id="language-select"
      >
        {LOCALE_OPTIONS}
      </Select>
    </ContainerDiv>
  );
});

LanguageSelect.displayName = 'LanguageSelect';

export default LanguageSelect;
