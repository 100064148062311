import { memo } from 'react';

import ChangePasswordForm from '~/components/forms/ChangePasswordForm';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import i18n from '~/locales/i18n';

import { WrapperDiv } from '../TabProfile/TabProfile';

const TabSecurity = memo(() => (
  <>
    <TabSectionHeader title={i18n.t('changePassword.title')} />
    <WrapperDiv>
      <ChangePasswordForm />
    </WrapperDiv>
  </>
));

TabSecurity.displayName = 'TabSecurity';

export default TabSecurity;
