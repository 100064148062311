import { Tooltip } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import ConnectionIcon from '~/components/icons/ConnectionIcon';
import useConnectionStatus from '~/hooks/useConnectionStatus';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { NavigatorConnectionData, ConnectionEffectiveType } from '~/types/connection';
import getYesOrNo from '~/utils/parse/getYesOrNo';

function getEffectiveTypeLabel(effectiveType: ConnectionEffectiveType | undefined): string {
  switch (effectiveType) {
    case '4g':
      return i18n.t('connectionStatus.effectiveType.4g');
    case '3g':
      return i18n.t('connectionStatus.effectiveType.3g');
    case '2g':
      return i18n.t('connectionStatus.effectiveType.2g');
    case 'slow-2g':
      return i18n.t('connectionStatus.effectiveType.slow-2g');
    default:
      return i18n.t('connectionStatus.effectiveType.unknown');
  }
}

function getEffectiveTypeNumber(effectiveType: ConnectionEffectiveType | undefined): number {
  switch (effectiveType) {
    case '4g':
      return 4;
    case '3g':
      return 3;
    case '2g':
      return 2;
    case 'slow-2g':
      return 1;
    default:
      return 0;
  }
}

function getBarsFromConnectionData(connectionData: NavigatorConnectionData | null): number {
  if (
    !connectionData ||
    typeof connectionData?.rtt !== 'number' ||
    typeof connectionData?.downlink !== 'number'
  ) {
    return 0;
  }

  const effectiveTypeNumber = getEffectiveTypeNumber(connectionData?.effectiveType);

  if (effectiveTypeNumber >= 4 && connectionData?.rtt < 50 && connectionData?.downlink > 20) {
    return 4;
  }
  if (effectiveTypeNumber >= 3 && connectionData?.rtt <= 100 && connectionData?.downlink >= 10) {
    return 3;
  }
  if (effectiveTypeNumber >= 2 && connectionData?.rtt <= 300 && connectionData?.downlink >= 2) {
    return 2;
  }
  if (
    effectiveTypeNumber >= 1 ||
    Number.isFinite(connectionData?.rtt) ||
    Number.isFinite(connectionData?.downlink)
  ) {
    return 1;
  }
  return 0;
}

const WrapperDiv = styled.div`
  transform: translateY(2px);
`;

const IconSpan = styled.span`
  color: ${theme.colors.white};
  cursor: help;

  & svg {
    font-size: 18px;
  }
`;

const NavbarDesktopConnectionIndicator = memo(() => {
  const { isNavigatorOnline, isAppsyncOnline, navigatorConnection } = useConnectionStatus();

  const bars = isNavigatorOnline ? getBarsFromConnectionData(navigatorConnection) : 0;

  return (
    <WrapperDiv>
      <Tooltip
        title={
          <div>
            <b>{i18n.t('connectionStatus.connected')}:</b> {getYesOrNo(isAppsyncOnline)}
            {navigatorConnection && (
              <>
                <br />
                <b>{i18n.t('connectionStatus.networkQuality')}:</b>{' '}
                {getEffectiveTypeLabel(navigatorConnection?.effectiveType)}
                <br />
                <b>{i18n.t('connectionStatus.bandwidth')}:</b> {navigatorConnection?.downlink} Mbps
                <br />
                <b>{i18n.t('connectionStatus.latency')}:</b> {navigatorConnection?.rtt} ms
              </>
            )}
          </div>
        }
      >
        <IconSpan>
          <ConnectionIcon
            bars={isAppsyncOnline ? bars : 4}
            status={isAppsyncOnline ? 'connected' : 'error'}
          />
        </IconSpan>
      </Tooltip>
    </WrapperDiv>
  );
});

NavbarDesktopConnectionIndicator.displayName = 'NavbarDesktopConnectionIndicator';

export default NavbarDesktopConnectionIndicator;
