import { Tooltip } from 'antd';
import isBoolean from 'lodash/isBoolean';
import { memo, type ReactNode } from 'react';
import styled from 'styled-components';

import theme from '~/theme';

const BORDER_COLOR = 'rgba(5, 5, 5, 0.1)';

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid ${BORDER_COLOR};
  border-radius: 6px;
  overflow: hidden;
`;

const Li = styled.li`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  font-size: 14px;

  & > span {
    padding: 4px 8px;

    ${theme.medias.lteSmall} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:first-of-type {
      font-weight: 600;
      background-color: #fbfbfb;
      border-right: 1px solid ${BORDER_COLOR};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${BORDER_COLOR};
  }
`;

export const DEFAULT_VALUE = <span style={{ opacity: 0.15 }}>n/a</span>;

export interface GenericItem {
  label: string;
  value: ReactNode;
  tooltip?: ReactNode;
}

interface Props {
  items: GenericItem[];
}

const GenericItemList = memo(({ items }: Props) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <Ul>
      {items.map(({ label, value, tooltip }) => {
        const actualValue = isBoolean(value) ? value?.toString() : value ?? DEFAULT_VALUE;

        return (
          <Li key={label}>
            <span title={label}>{label}</span>
            <span>
              {tooltip ? (
                <Tooltip title={tooltip} placement="right">
                  <span style={{ cursor: 'help' }}>{actualValue}</span>
                </Tooltip>
              ) : (
                actualValue
              )}
            </span>
          </Li>
        );
      })}
    </Ul>
  );
});

GenericItemList.displayName = 'GenericItemList';

export default GenericItemList;
