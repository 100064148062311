import { gql } from '@apollo/client';

import type { UserType } from '~/types/user';

export interface CurrentUserQueryData {
  me: Omit<UserType, 'type'>;
}

export default gql`
  query QueryCurrentUser {
    me {
      __typename
      ... on SubsidiaryAdmin {
        id
        email
        first_name
        last_name
        subsidiary {
          id
          name
          location {
            lat
            lng
          }
          company {
            id
            name
            attributes {
              name
              value
            }
          }
        }
      }
      ... on CompanyAdmin {
        id
        email
        first_name
        last_name
        company {
          id
          name
          attributes {
            name
            value
          }
        }
      }
      ... on SuperAdmin {
        id
        email
        first_name
        last_name
      }
    }
  }
`;
