import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

export interface CreateSubsidiaryVariables {
  company_id: string;
  name: string;
  location: {
    lat: number;
    lng: number;
  };
}

export interface CreateSubsidiaryData {
  createSubsidiary: {
    __typename: 'Subsidiary';
    id: string;
  };
}

export default function useMutationCreateSubsidiary() {
  const [createSubsidiary, { data, loading, error }] = useMutation<
    CreateSubsidiaryData,
    CreateSubsidiaryVariables
  >(
    gql`
      mutation MutationCreateSubsidiary(
        $company_id: ID!
        $name: String!
        $location: LocationInput!
      ) {
        createSubsidiary(company_id: $company_id, name: $name, location: $location) {
          id
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return useMemo(
    () => ({
      createSubsidiary,
      result: data?.createSubsidiary,
      isLoading: loading,
      error,
    }),
    [createSubsidiary, data?.createSubsidiary, error, loading],
  );
}
