import { memo, useMemo } from 'react';
import styled from 'styled-components';

import BatteryIcon from '~/components/icons/BatteryIcon';
import LteSignalIcon from '~/components/icons/LteSignalIcon';
import type { Agent } from '~/types/agent';

import getPopupSectionStatus from './BrainBatteryLteSignalPopover/utils/getPopupSectionStatus';

const ContainerDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

interface Props {
  agent: Agent | undefined;
}

const BatteryAndLteSingalIcons = memo(({ agent }: Props) => {
  const lteSignalStatus = useMemo(
    () =>
      getPopupSectionStatus({
        agent,
        status: agent?.equipmentStatus?.lteSignalStrength?.status,
      }),
    [agent],
  );

  const batteryStatus = useMemo(() => {
    const status = getPopupSectionStatus({
      agent,
      status: agent?.equipmentStatus?.battery?.status,
    });
    if (status === 'disconnected' || status === 'error') {
      return 'disconnected';
    }
    if (
      typeof agent?.sensors?.battery?.value?.battery_level === 'number' &&
      agent.sensors.battery.value.battery_level < 25 &&
      agent.sensors.battery.value.is_charging !== 1
    ) {
      return 'error';
    }
    return status;
  }, [agent]);

  if (!agent) {
    return null;
  }

  const networkSignalBars = agent?.sensors?.lteSignalStrength?.value?.bars;
  const hasNetworkSignalBars =
    typeof networkSignalBars === 'number' &&
    Number.isFinite(networkSignalBars) &&
    networkSignalBars >= 0 &&
    networkSignalBars <= 5;

  const batteryLevel = agent?.sensors?.battery?.value?.battery_level;
  const hasBatteryLevel =
    typeof batteryLevel === 'number' &&
    Number.isFinite(batteryLevel) &&
    batteryLevel >= 0 &&
    batteryLevel <= 100;

  if (!hasNetworkSignalBars && !hasBatteryLevel) {
    return null;
  }

  const isChargingBattery = agent?.sensors?.battery?.value?.is_charging === 1;

  return (
    <ContainerDiv>
      {hasNetworkSignalBars && <LteSignalIcon bars={networkSignalBars} status={lteSignalStatus} />}
      {hasBatteryLevel && (
        <BatteryIcon level={batteryLevel} isCharging={isChargingBattery} status={batteryStatus} />
      )}
    </ContainerDiv>
  );
});

BatteryAndLteSingalIcons.displayName = 'BatteryAndLteSingalIcons';

export default BatteryAndLteSingalIcons;
