import ApiOutlined from '@ant-design/icons/ApiOutlined';
import MinusOutlined from '@ant-design/icons/MinusOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { Table, Tooltip, Button, type TableColumnsType } from 'antd';
import { memo, useCallback, useMemo, type Key, type ReactNode } from 'react';
import ReactAvatar from 'react-avatar';
import styled from 'styled-components';

import useMutationAssociateBrainToAgent from '~/apollo/hooks/brain/useMutationAssociateBrainToAgent';
import useMutationDisassociateBrainFromAgent from '~/apollo/hooks/brain/useMutationDisassociateBrainFromAgent';
import useQueryAllBrains from '~/apollo/hooks/brain/useQueryAllBrains';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import {
  SettingsTopFlexDiv,
  SettingsButtonsFlexDiv,
  SelectCompanySubsidiaryDiv,
} from '~/components/settings/shared';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useTableSearch from '~/hooks/useTableSearch';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import getAgentCompleteNameFromCarrier from '~/utils/agent/getAgentCompleteNameFromCarrier';
import notification from '~/utils/notification';
import curateUrl from '~/utils/parse/curateUrl';
import defaultTablePaginationProps from '~/utils/table/defaultTablePaginationProps';
import sortTableColumnWithEmptyValues from '~/utils/table/sortTableColumnWithEmptyValues';

import { TextAvatarDiv } from '../TabSuperAdmins/TabSuperAdmins';

interface TableDataType {
  key: Key;
  deviceName: string;
  agentId: string | null;
  agentName: string | null;
  agentEmail: string | null;
  companyId: string | null;
  companyName: string | null;
  subsidiaryId: string | null;
  subsidiaryName: string | null;
  requestedAssociation: string | null;
  actions: ReactNode;
}

const WrapperDiv = styled.div`
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

const StyledTable = styled(Table<TableDataType>)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const ActionsDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

const TabBrains = memo(() => {
  const { isLoading: isLoadingCurrentUser } = useCurrentUserContext();
  const { openModal } = useModalsContext();

  const { allBrains, isLoading: isQueryAllDevicesLoading, refetchAllBrains } = useQueryAllBrains();

  const { isAssociateBrainToAgentLoading } = useMutationAssociateBrainToAgent();
  const { disassociateBrainFromAgent, isDisassociateBrainFromAgentLoading } =
    useMutationDisassociateBrainFromAgent();

  const isLoading =
    isLoadingCurrentUser ||
    isQueryAllDevicesLoading ||
    isAssociateBrainToAgentLoading ||
    isDisassociateBrainFromAgentLoading;

  const totalRefetch = useCallback(async () => {
    await Promise.all([refetchAllBrains()]);
  }, [refetchAllBrains]);

  const { getColumnSearchProps } = useTableSearch<TableDataType>();

  const columns: TableColumnsType<TableDataType> = useMemo(
    () => [
      {
        dataIndex: 'deviceName',
        title: i18n.t('common.wearinBrain'),
        sorter: (a, b) => a.deviceName.toLowerCase().localeCompare(b.deviceName.toLowerCase()),
        defaultSortOrder: 'ascend',
        ...getColumnSearchProps({
          dataIndex: 'deviceName',
          title: i18n.t('common.wearinBrain'),
          renderWithHighlight: ({ text, highlightedNode }) =>
            text ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <img
                  src={curateUrl('/icons/brain.svg')}
                  alt="Brain"
                  style={{
                    color: theme.colors.darkBlue,
                    height: 24,
                  }}
                />
                <b>{highlightedNode}</b>
              </div>
            ) : (
              '-'
            ),
        }),
      },
      {
        dataIndex: 'agentName',
        title: i18n.t('common.agentName'),
        sorter: (a, b, sortOrder) =>
          sortTableColumnWithEmptyValues(a.agentName, b.agentName, sortOrder),
        ...getColumnSearchProps({
          dataIndex: 'agentName',
          title: i18n.t('common.agentName'),
          renderWithHighlight: ({ text, highlightedNode }) =>
            text ? <b>{highlightedNode}</b> : '-',
        }),
      },
      {
        dataIndex: 'agentEmail',
        title: i18n.t('common.agentEmail'),
        sorter: (a, b, sortOrder) =>
          sortTableColumnWithEmptyValues(a.agentEmail, b.agentEmail, sortOrder),
        ...getColumnSearchProps({
          dataIndex: 'agentEmail',
          title: i18n.t('common.agentEmail'),
          renderWithHighlight: ({ text, highlightedNode }) => (text ? highlightedNode : '-'),
        }),
      },
      {
        dataIndex: 'companyName',
        title: i18n.t('common.company'),
        sorter: (a, b, sortOrder) =>
          sortTableColumnWithEmptyValues(a.companyName, b.companyName, sortOrder),
        ...getColumnSearchProps({
          dataIndex: 'companyName',
          title: i18n.t('common.company'),
          renderWithHighlight: ({ text, highlightedNode }) =>
            text ? (
              <TextAvatarDiv>
                <ReactAvatar name={text} size="26px" /> {highlightedNode}
              </TextAvatarDiv>
            ) : (
              '-'
            ),
        }),
      },
      {
        dataIndex: 'subsidiaryName',
        title: i18n.t('common.subsidiary'),
        sorter: (a, b, sortOrder) =>
          sortTableColumnWithEmptyValues(a.subsidiaryName, b.subsidiaryName, sortOrder),
        ...getColumnSearchProps({
          dataIndex: 'subsidiaryName',
          title: i18n.t('common.subsidiary'),
          renderWithHighlight: ({ text, highlightedNode }) =>
            text ? (
              <TextAvatarDiv>
                <ReactAvatar name={text} size="26px" /> {highlightedNode}
              </TextAvatarDiv>
            ) : (
              '-'
            ),
        }),
      },
      {
        dataIndex: 'actions',
        title: i18n.t('common.actions'),
        fixed: 'right',
      },
    ],
    [getColumnSearchProps],
  );

  const data: TableDataType[] = useMemo(
    () =>
      allBrains.map((brain) => ({
        key: brain.name,
        deviceName: brain.name,
        agentId: brain.carrier?.id || null,
        agentName: brain.carrier ? getAgentCompleteNameFromCarrier(brain.carrier) : null,
        agentEmail: brain.carrier?.email || null,
        companyId: brain.carrier?.subsidiary?.company?.id || null,
        companyName: brain.carrier?.subsidiary?.company?.name || null,
        subsidiaryId: brain.carrier?.subsidiary?.id || null,
        subsidiaryName: brain.carrier?.subsidiary?.name || null,
        requestedAssociation: brain.carrier?.requested_association || null,
        actions: (
          <ActionsDiv>
            <Tooltip
              title={
                brain.carrier?.id
                  ? i18n.t('backofficePage.brainsTab.brainAlreadyAssociated')
                  : i18n.t('backofficePage.brainsTab.associateBrain')
              }
            >
              <Button
                disabled={isLoading || !!brain.carrier?.id}
                onClick={() => {
                  openModal({
                    type: 'brainAssociateToAgent',
                    allBrains,
                    initialValues: {
                      deviceName: brain.name,
                    },
                    refetchOnCompleted: totalRefetch,
                  });
                }}
                icon={<PlusOutlined />}
              />
            </Tooltip>
            <Tooltip
              title={
                !brain.carrier?.id
                  ? i18n.t('backofficePage.brainsTab.brainAlreadyDisassociated')
                  : i18n.t('backofficePage.brainsTab.disassociateBrain')
              }
            >
              <Button
                danger
                disabled={isLoading || !brain.carrier?.id}
                onClick={() => {
                  openModal({
                    type: 'confirmation',
                    title: i18n.t('backofficePage.brainsTab.disassociateBrain'),
                    description: (
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: i18n.t<string>(
                            'backofficePage.brainsTab.areYouSureYouWantToDisassociateBrainHtml',
                            {
                              brain: brain.name,
                              agent: [
                                brain.carrier
                                  ? getAgentCompleteNameFromCarrier(brain.carrier)
                                  : undefined,
                                brain.carrier?.email ? ` (${brain.carrier?.email})` : undefined,
                              ]
                                .filter(Boolean)
                                .join(' '),
                              subsidiary: `${brain.carrier?.subsidiary?.name} (${brain.carrier?.subsidiary?.company?.name})`,
                            },
                          ),
                        }}
                      />
                    ),
                    actionType: 'danger',
                    action: async () => {
                      if (!brain?.carrier?.id) {
                        return;
                      }
                      try {
                        await disassociateBrainFromAgent({
                          variables: {
                            carrier_id: brain.carrier.id,
                            device_name: brain.name,
                          },
                        });
                        await totalRefetch();
                        notification.success({
                          message: i18n.t('common.success'),
                          description: i18n.t(
                            'backofficePage.brainsTab.brainDisassociatedSuccessfully',
                          ),
                        });
                      } catch (error) {
                        notification.error({
                          message: (error as any)?.message || i18n.t('common.error'),
                        });
                      }
                    },
                  });
                }}
                icon={<MinusOutlined />}
              />
            </Tooltip>
          </ActionsDiv>
        ),
      })),
    [allBrains, disassociateBrainFromAgent, isLoading, openModal, totalRefetch],
  );

  return (
    <WrapperDiv>
      <SelectCompanySubsidiaryDiv />
      <SettingsTopFlexDiv>
        <TabSectionHeader
          title={
            isLoading
              ? i18n.t('common.loading')
              : `${i18n.t('backofficePage.brainsTab.listOfBrains')} (${allBrains.length})`
          }
        />
        <SettingsButtonsFlexDiv>
          <Button
            loading={isLoading}
            icon={<ReloadOutlined />}
            onClick={async () => {
              await totalRefetch();
            }}
          >
            {i18n.t('common.refresh')}
          </Button>
          <Button
            loading={isLoading}
            type="primary"
            icon={<ApiOutlined />}
            onClick={() => {
              openModal({
                type: 'brainAssociateToAgent',
                allBrains,
                initialValues: undefined,
                refetchOnCompleted: totalRefetch,
              });
            }}
          >
            {i18n.t('backofficePage.brainsTab.associateBrain')}
          </Button>
        </SettingsButtonsFlexDiv>
      </SettingsTopFlexDiv>
      <StyledTable
        tableLayout="auto"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        sortDirections={['ascend', 'descend', 'ascend']}
        pagination={defaultTablePaginationProps}
      />
    </WrapperDiv>
  );
});

TabBrains.displayName = 'TabBrains';

export default TabBrains;
