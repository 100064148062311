import { useReactiveVar } from '@apollo/client';
import { useEffect, useCallback, useMemo } from 'react';

import type { CompanyListQueryData } from '~/apollo/hooks/company/queries/QueryCompanyList';
import useQueryCompanyDetails from '~/apollo/hooks/company/useQueryCompanyDetails';
import useQueryCompanyList from '~/apollo/hooks/company/useQueryCompanyList';
import { currentCompanyIdentifierVar } from '~/apollo/reactiveVariables/currentCompanyIdentifierVar';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import type { Company } from '~/types/company';
import browserStorage from '~/utils/browserStorage';

const COMPANY_STORAGE_KEY = 'cachedCompany';

export default function useCompany(): {
  companyList: CompanyListQueryData['getAllCompanies']['items'];
  currentCompany?: Company;
  setCurrentCompanyId: (companyId: string) => void;
  refetchCompanyDetails: () => void;
  refetchCompanyList: () => void;
} {
  const { currentUser, isCompanyAdmin, isSubsidiaryAdmin } = useCurrentUserContext();

  const { companyList, refetch: refetchCompanyList } = useQueryCompanyList(
    !currentUser || isCompanyAdmin || isSubsidiaryAdmin,
  );

  const currentCompanyIdentifier = useReactiveVar(currentCompanyIdentifierVar);

  const { companyDetails, refetch: refetchCompanyDetails } = useQueryCompanyDetails({
    companyID: currentCompanyIdentifier,
    skip: !currentCompanyIdentifier || !currentUser || isSubsidiaryAdmin,
  });

  const setCurrentCompanyId = useCallback(
    (companyId: string) => {
      currentCompanyIdentifierVar(companyId);
      if (!currentUser?.id) {
        return;
      }
      const cachedCompanyIds = browserStorage.local.get(COMPANY_STORAGE_KEY, true) || {};
      cachedCompanyIds[currentUser.id] = companyId;
      browserStorage.local.set(COMPANY_STORAGE_KEY, cachedCompanyIds, true);
    },
    [currentUser?.id],
  );

  useEffect(() => {
    if (!currentUser?.id) {
      return;
    }

    const cachedCompanyIds = browserStorage.local.get(COMPANY_STORAGE_KEY, true);
    const cachedCompanyId = cachedCompanyIds?.[currentUser.id];
    const preselectedCompanyId =
      currentUser.company?.id || companyList.find(({ id }) => id === cachedCompanyId)?.id;
    if (preselectedCompanyId) {
      setCurrentCompanyId(preselectedCompanyId);
    } else if (companyList.length > 0) {
      // If there's no preselected company, set the first company on the list
      setCurrentCompanyId(companyList[0].id);
    }
  }, [setCurrentCompanyId, currentUser?.id, currentUser?.company?.id, companyList]);

  return useMemo(
    () => ({
      companyList,
      currentCompany: companyDetails || currentUser?.company,
      setCurrentCompanyId,
      refetchCompanyDetails,
      refetchCompanyList,
    }),
    [
      companyDetails,
      companyList,
      currentUser?.company,
      setCurrentCompanyId,
      refetchCompanyDetails,
      refetchCompanyList,
    ],
  );
}
