import {
  ApolloClient,
  ApolloLink,
  useReactiveVar,
  type NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import type { GraphQLError } from 'graphql/error/GraphQLError';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { useEffect, useMemo, useRef } from 'react';

import { getNewCacheInstance } from '~/apollo/cache';
import { reconnectTimestampVar } from '~/apollo/reactiveVariables/reconnectTimestampVar';
import { refetchDataOnReconnectVar } from '~/apollo/reactiveVariables/refetchDataOnReconnectVar';
import variables from '~/config/variables';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import { APOLLO_AUTH_TYPE } from '~/types/awsService';
import browserStorage, { BROWSER_STORAGE_KEY } from '~/utils/browserStorage';
import logger from '~/utils/logger';

const throttledLogToken = throttle(
  (exp?: number) => logger.log('useAppSyncApolloClient: expired jwtToken', { exp }),
  60_000,
);

interface ApolloGraphQLError extends GraphQLError {
  errorType?: string;
}

export default function useAppSyncApolloClient(): ApolloClient<NormalizedCacheObject> | undefined {
  const { getIdToken, isAuthenticated, isLoggingOut, logout, refreshSession } =
    useAuthenticationContext();

  // changing reconnectTimestamp from any place will trigger this hook
  // this will lead to new client being created and connections being established again
  const reconnectTimestampValue = useReactiveVar(reconnectTimestampVar);

  // need to have client on initial render because PrivateLayout(whose children use
  // apollo query hooks) is mounted before ApolloProvider(react child components
  // mount before parent ones and we are creating apollo client on this hook's mount).
  const clientRef = useRef<ApolloClient<NormalizedCacheObject> | undefined>(
    new ApolloClient({
      cache: getNewCacheInstance(),
      connectToDevTools: process.env.NODE_ENV !== 'production',
    }),
  );

  const reconnect = useMemo(
    () =>
      debounce(() => {
        logger.log('useAppSyncApolloClient: reconnecting');
        reconnectTimestampVar(Date.now());
      }, 100),
    [],
  );

  useEffect(
    () => () => {
      reconnect.cancel();
    },
    [reconnect],
  );

  const stopClient = () => {
    const client = clientRef.current;
    clientRef.current = undefined;
    logger.log('useAppSyncApolloClient: clearing store', { hasClient: !!client });
    client?.clearStore().finally(() => {
      logger.log('useAppSyncApolloClient: stopping previous apollo client');
      client?.stop();
    });
  };

  if (!isLoggingOut) {
    stopClient();
  }

  if (isAuthenticated && !isLoggingOut) {
    const auth = {
      type: variables.awsConfig.aws_appsync_authenticationType as APOLLO_AUTH_TYPE,
      apiKey: variables.awsConfig.aws_appsync_apiKey ?? '',
      jwtToken: async () => {
        const token = await getIdToken();
        const expiration = token?.getExpiration();
        if ((expiration || 0) * 1000 < Date.now()) {
          throttledLogToken(expiration);
        }
        return token?.jwtToken || '';
      },
    };

    const authLink = createAuthLink({ url: variables.mainApiUrl, region: variables.region, auth });

    // https://docs.aws.amazon.com/appsync/latest/devguide/aws-appsync-real-time-data.html
    // Solution for Pure WebSockets https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/628#issue-834140203
    const subscriptionLink = createSubscriptionHandshakeLink({
      url: variables.mainApiUrl,
      region: variables.region,
      auth,
      keepAliveTimeoutMs: 5 * 60 * 1000, // 5 minutes
    });

    const customLink = new ApolloLink((operation, forward) => {
      const context = operation.getContext();
      if (!context.headers?.Authorization) {
        logger.log('useAppSyncApolloClient: missing Authorization header request', {
          operationName: operation.operationName,
        });
        return null;
      }
      return forward(operation);
    });

    const errorLink = onError(({ graphQLErrors, networkError, ...rest }) => {
      logger.error('useAppSyncApolloClient: errorLink', {
        graphQLErrors,
        networkError,
        rest,
      });

      if (
        graphQLErrors?.some(
          (graphQLError) =>
            (graphQLError as ApolloGraphQLError).errorType === 'UnauthorizedException',
        )
      ) {
        logger.log('useAppSyncApolloClient: UnauthorizedException refreshing session');
        refreshSession().catch(() => {
          logger.log('useAppSyncApolloClient: refresh session unsuccessful, logout');
          browserStorage.session.set(BROWSER_STORAGE_KEY.TOKEN_EXPIRED, true);
          logout();
        });
        // TODO: retry
        // return rest.forward(rest.operation);
      } else if (networkError) {
        const networkErrorMessage: string = (networkError as any).errors?.[0]?.message || '';
        if (
          networkErrorMessage.includes('Connection closed') ||
          networkErrorMessage.includes('Timeout disconnect')
        ) {
          logger.log('useAppSyncApolloClient: connection closed/timeout');
          reconnect();
        }
      }
    });

    // retryLink could be added before errorLink but in case of
    // MaxSubscriptionsReachedError it would multiply it several times.
    const link = ApolloLink.from([errorLink, authLink, customLink, subscriptionLink]);

    clientRef.current = new ApolloClient({ link, cache: getNewCacheInstance() });

    logger.log('useAppSyncApolloClient: created new apollo client');

    window.getApolloStoreCache = (): Record<string, unknown> => {
      try {
        return JSON.parse(JSON.stringify(clientRef.current?.cache.extract() || {}));
      } catch (error) {
        logger.error('useAppSyncApolloClient: window.getApolloStoreCache', { error });
        return {};
      }
    };
  }

  if (reconnectTimestampValue) {
    logger.log('useAppSyncApolloClient: reconnected at', {
      date: new Date(reconnectTimestampValue),
    });
    refetchDataOnReconnectVar(Date.now().toString());
  }

  return clientRef.current;
}
