import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

export interface DeleteCompanyVariables {
  company_id: string;
}

export interface DeleteCompanyData {
  deleteCompany: {
    __typename: 'Company';
    id: string;
  };
}

export default function useMutationDeleteCompany() {
  const [deleteCompany, { data, loading, error }] = useMutation<
    DeleteCompanyData,
    DeleteCompanyVariables
  >(
    gql`
      mutation MutationDeleteCompany($company_id: ID!) {
        deleteCompany(company_id: $company_id)
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return useMemo(
    () => ({
      deleteCompany,
      result: data?.deleteCompany,
      isLoading: loading,
      error,
    }),
    [deleteCompany, data?.deleteCompany, error, loading],
  );
}
