import { AGENT_STATUS } from '~/types/agent';
import { MARKER_TYPE } from '~/types/marker';

export default function computeAnchorPoint(
  markerType: string,
  status: AGENT_STATUS,
  isHighlighted?: boolean,
) {
  const alert = status === AGENT_STATUS.alert;
  const vehicle = markerType === MARKER_TYPE.vehicle;

  let anchorPointX;
  let anchorPointY;

  if (isHighlighted) {
    anchorPointX = 0;

    if (vehicle) {
      anchorPointY = -98;
    } else if (alert) {
      anchorPointY = -102;
    } else {
      anchorPointY = -66;
    }
  } else {
    anchorPointX = 0;

    if (vehicle) {
      anchorPointY = -80;
    } else if (alert) {
      anchorPointY = -86;
    } else {
      anchorPointY = -56;
    }
  }

  return new google.maps.Point(anchorPointX, anchorPointY);
}
