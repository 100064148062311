import { currentLanguage } from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import { ALARM_LEVEL } from '~/types/alarm';
import getAgentStatusWeight from '~/utils/agent/getAgentStatusWeight';

function getAlertLevelWeight(agent: Agent): number {
  switch (agent.alertLevel) {
    case ALARM_LEVEL.Critical:
      return 3;
    case ALARM_LEVEL.Warning:
      return 2;
    case ALARM_LEVEL.Information:
      return 1;
    default:
      return 0;
  }
}

export default function sortAgentsByStatusAndName(agents: Agent[]): Agent[] {
  const language = currentLanguage();

  return (agents || [])?.slice(0).sort((agentA, agentB) => {
    const agentAStatusWeight = getAgentStatusWeight(agentA);
    const agentBStatusWeight = getAgentStatusWeight(agentB);

    if (agentAStatusWeight !== agentBStatusWeight) {
      return agentBStatusWeight - agentAStatusWeight;
    }

    const agentAAlertLevel = getAlertLevelWeight(agentA);
    const agentBAlertLevel = getAlertLevelWeight(agentB);

    if (agentAAlertLevel !== agentBAlertLevel) {
      return agentBAlertLevel - agentAAlertLevel;
    }

    return agentB.completeName.toLocaleLowerCase(language) >
      agentA.completeName.toLocaleLowerCase(language)
      ? -1
      : 1;
  });
}
