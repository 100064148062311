import { ALARM_LEVEL, type AlarmWithCarrier } from '~/types/alarm';

export default function getHighestAlarmLevel(alarms: AlarmWithCarrier[]): ALARM_LEVEL | null {
  if (alarms.some((alarm) => alarm.level === ALARM_LEVEL.Critical)) {
    return ALARM_LEVEL.Critical;
  }
  if (alarms.some((alarm) => alarm.level === ALARM_LEVEL.Warning)) {
    return ALARM_LEVEL.Warning;
  }
  if (alarms.some((alarm) => alarm.level === ALARM_LEVEL.Information)) {
    return ALARM_LEVEL.Information;
  }
  return null;
}
