import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

export interface CompanyListWithDetailsQueryData {
  getAllCompanies: {
    __typename: 'CompanyConnection';
    nextToken: string | null;
    items: {
      __typename: 'Company';
      id: string;
      name: string;
      created_at: string;
      admins: {
        __typename: 'AdministratorConnection';
        items: {
          __typename: 'CompanyAdmin';
          id: string;
          email: string;
        }[];
      };
      subsidiaries: {
        __typename: 'SubsidiaryConnection';
        items: {
          __typename: 'Subsidiary';
          id: string;
          name: string;
        }[];
      };
    }[];
  };
}

export default function useQueryCompanyListWithDetails() {
  const { data, loading, error, refetch } = useQuery<CompanyListWithDetailsQueryData>(
    gql`
      query QueryCompanyListWithDetails($nextToken: String) {
        getAllCompanies(limit: 1000, nextToken: $nextToken) {
          nextToken
          items {
            id
            name
            created_at
            admins {
              items {
                id
                email
              }
            }
            subsidiaries {
              items {
                id
                name
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        nextToken: null,
      },
    },
  );

  return useMemo(
    () => ({
      companyListWithDetails: data?.getAllCompanies?.items || [],
      isLoading: loading,
      error,
      refetchCompanyListWithDetails: refetch,
    }),
    [data?.getAllCompanies?.items, loading, error, refetch],
  );
}
