import type { AgentAlertLevel } from '~/types/agent';
import { ALARM_LEVEL, type Alarm } from '~/types/alarm';

export default function getMaximumAlertLevelFromAlarms(alarms: Alarm[]): AgentAlertLevel | null {
  const alertLevel: AgentAlertLevel = (() => {
    if (alarms.some((alarm) => alarm.level === ALARM_LEVEL.Critical)) {
      return ALARM_LEVEL.Critical;
    }
    if (alarms.some((alarm) => alarm.level === ALARM_LEVEL.Warning)) {
      return ALARM_LEVEL.Warning;
    }
    if (alarms.some((alarm) => alarm.level === ALARM_LEVEL.Information)) {
      return ALARM_LEVEL.Information;
    }
    return null;
  })();

  return alertLevel;
}
