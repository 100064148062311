import { memo, type CSSProperties } from 'react';
import styled from 'styled-components';

import Link from '~/components/Link';
import routes from '~/config/routes';
import curateUrl from '~/utils/parse/curateUrl';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

const Img = styled.img`
  width: 90px;
  transform: translateY(-1px);
`;

interface Props {
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
}

const NavbarLogo = memo(({ onClick, className, style }: Props) => (
  <StyledLink to={routes.map()} onClick={onClick} className={className} style={style}>
    <Img src={curateUrl('/images/logo-wearin-white.png')} />
  </StyledLink>
));

NavbarLogo.displayName = 'NavbarLogo';

export default NavbarLogo;
