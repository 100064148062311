import { gql } from '@apollo/client';

export interface UpdateSubsidiaryMutationVariables {
  subsidiaryId: string;
  name: string;
  location: {
    lat: number;
    lng: number;
  };
}

export interface UpdateSubsidiaryMutationData {
  updateSubsidiary: {
    __typename: 'Subsidiary';
    id: string;
    name: string;
    location: {
      __typename: 'Location';
      lat: number;
      lng: number;
    };
    company: {
      __typename: 'Company';
      id: string;
    };
  };
}

export default gql`
  mutation MutationUpdateSubsidiary($subsidiaryId: ID!, $name: String, $location: LocationInput) {
    updateSubsidiary(subsidiary_id: $subsidiaryId, name: $name, location: $location) {
      id
      name
      location {
        lat
        lng
      }
      company {
        id
      }
    }
  }
`;
