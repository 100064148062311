import { memo, type ReactNode } from 'react';
import styled from 'styled-components';

const Div = styled.div`
  padding-bottom: 4rem;
`;

interface Props {
  children: ReactNode;
}

const PageContentWrapper = memo(({ children }: Props) => <Div>{children}</Div>);

PageContentWrapper.displayName = 'PageContentWrapper';

export default PageContentWrapper;
