import { gql } from '@apollo/client';

export interface CompanyListQueryData {
  getAllCompanies: {
    __typename: 'CompanyConnection';
    nextToken: string | null;
    items: {
      __typename: 'Company';
      id: string;
      name: string;
    }[];
  };
}

export default gql`
  query QueryCompanyList($nextToken: String) {
    getAllCompanies(limit: 1000, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
      }
    }
  }
`;
