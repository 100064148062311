import { gql } from '@apollo/client';

import type { STREAM_TYPE, STREAM_STATE } from '~/types/videoStream';

export interface CarrierVideoStreamQueryVariables {
  carrierID: string;
}

export interface CarrierVideoStreamQueryData {
  carrier: {
    __typename: 'Carrier_Cognito';
    id: string;
    video_stream: {
      __typename: 'VideoStream';
      ivs_stream_type: STREAM_TYPE | null;
      ivs_stream_state: STREAM_STATE | null;
      // IVS Low Latency
      playback_url: string;
      playback_key: string;
      // IVS Realtime
      playback_participant_token: string;
    };
  };
}

export default gql`
  query QueryCarrierVideoStream($carrierID: ID!) {
    carrier(id: $carrierID) {
      id
      video_stream {
        ivs_stream_type
        ivs_stream_state
        playback_url
        playback_key
        playback_participant_token
      }
    }
  }
`;
