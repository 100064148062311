import { gql } from '@apollo/client';

import { getAlarmConfigurationQueryBody } from '~/apollo/operations/shared';
import type { ALARM_TYPE } from '~/types/alarm';
import type { AlarmConfigurationOverride } from '~/types/configurationAlarm';

export interface AlarmConfigurationOverrideForSubsidiaryQueryVariables {
  subsidiaryId: string;
  alarmConfigurationName: ALARM_TYPE;
  nextToken: string | null;
}

export interface AlarmConfigurationOverrideForSubsidiaryQueryData {
  subsidiary: {
    configuration: AlarmConfigurationOverride;
  };
}

export default gql`
  query QueryAlarmConfigurationOverrideForSubsidiary($subsidiaryId: ID!, $alarmConfigurationName: String!) {
    subsidiary(id: $subsidiaryId) {
      configuration(type: "alarm", name: $alarmConfigurationName) ${getAlarmConfigurationQueryBody({ isOverride: true })}
    }
  }
`;
