import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { Table, Tooltip, Button, Tag, type TableColumnsType } from 'antd';
import { memo, useCallback, useMemo, type Key, type ReactNode } from 'react';
import ReactAvatar from 'react-avatar';
import styled from 'styled-components';

import useMutationDeleteAdmin from '~/apollo/hooks/admins/useMutationDeleteAdmin';
import useQueryCompanyAndSubsidiaryAdmins from '~/apollo/hooks/admins/useQueryCompanyAndSubsidiaryAdmins';
import useQuerySubsidiaryAdmins from '~/apollo/hooks/admins/useQuerySubsidiaryAdmins';
import SettingsSelectCompany from '~/components/settings/components/SettingsSelectCompany';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import {
  SelectCompanySubsidiaryDiv,
  SettingsButtonsFlexDiv,
  SettingsTopFlexDiv,
} from '~/components/settings/shared';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import useTableSearch from '~/hooks/useTableSearch';
import i18n from '~/locales/i18n';
import { TextAvatarDiv } from '~/pages/BackofficePage/components/TabSuperAdmins/TabSuperAdmins';
import theme from '~/theme';
import { USER_TYPE } from '~/types/user';
import notification from '~/utils/notification';
import defaultTablePaginationProps from '~/utils/table/defaultTablePaginationProps';
import sortTableColumnWithEmptyValues from '~/utils/table/sortTableColumnWithEmptyValues';

interface TableDataType {
  key: Key;
  id: string;
  email: string;
  name: string | null;
  role: string;
  subsidiary: string | null;
  actions: ReactNode;
}

const WrapperDiv = styled.div`
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

const StyledTable = styled(Table<TableDataType>)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const ActionsDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

interface Props {
  isBackofficePage: boolean;
}

const TabAdmins = memo(({ isBackofficePage }: Props) => {
  const {
    isSubsidiaryAdmin,
    isLoading: isLoadingCurrentUser,
    currentUser,
  } = useCurrentUserContext();
  const { openModal } = useModalsContext();
  const { currentCompany } = useCompany();
  const { currentSubsidiary } = useSubsidiary();

  const {
    isLoading: isCompanyAndSubsidiaryAdminsLoading,
    admins: companyAndSubsidiaryAdmins,
    refetchCompanyAndSubsidiaryAdmins,
  } = useQueryCompanyAndSubsidiaryAdmins({
    companyId: isSubsidiaryAdmin ? undefined : currentCompany?.id,
  });

  const {
    isLoading: isSubsidiaryAdminsLoading,
    admins: subsidiaryAdmins,
    refetchSubsidiaryAdmins,
  } = useQuerySubsidiaryAdmins({
    subsidiaryId: isSubsidiaryAdmin ? currentSubsidiary?.id : undefined,
    nextToken: null,
  });

  const { deleteAdmin, isLoading: isDeleteAdminLoading } = useMutationDeleteAdmin();

  const admins = isSubsidiaryAdmin ? subsidiaryAdmins : companyAndSubsidiaryAdmins;

  const isLoading =
    isLoadingCurrentUser ||
    isCompanyAndSubsidiaryAdminsLoading ||
    isSubsidiaryAdminsLoading ||
    isDeleteAdminLoading;

  const totalRefetch = useCallback(async () => {
    await Promise.all(
      isSubsidiaryAdmin ? [refetchSubsidiaryAdmins()] : [refetchCompanyAndSubsidiaryAdmins()],
    );
  }, [isSubsidiaryAdmin, refetchSubsidiaryAdmins, refetchCompanyAndSubsidiaryAdmins]);

  const { getColumnSearchProps } = useTableSearch<TableDataType>();

  const columns: TableColumnsType<TableDataType> = useMemo(
    () => [
      {
        dataIndex: 'email',
        title: i18n.t('common.email'),
        sorter: (a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
        defaultSortOrder: 'ascend',
        ...getColumnSearchProps({
          dataIndex: 'email',
          title: i18n.t('common.email'),
          renderWithHighlight: ({ text, record, highlightedNode }) =>
            text ? (
              <TextAvatarDiv>
                <ReactAvatar name={record.name || text} round size="26px" /> {highlightedNode}
                {currentUser?.email === text && <Tag color="green">{i18n.t('common.you')}</Tag>}
              </TextAvatarDiv>
            ) : (
              '-'
            ),
        }),
      },
      {
        dataIndex: 'name',
        title: i18n.t('common.name'),
        sorter: (a, b, sortOrder) => sortTableColumnWithEmptyValues(a.name, b.name, sortOrder),
        ...getColumnSearchProps({
          dataIndex: 'name',
          title: i18n.t('common.name'),
          renderWithHighlight: ({ text, highlightedNode }) => (text ? highlightedNode : '-'),
        }),
      },
      {
        dataIndex: 'role',
        title: i18n.t('common.role'),
        sorter: (a, b) => {
          const roleComparison = a.role.toLowerCase().localeCompare(b.role.toLowerCase());
          if (roleComparison !== 0) {
            return roleComparison;
          }
          return a.email.toLowerCase().localeCompare(b.email.toLowerCase());
        },
        render: (text) => (
          <Tag color={text === USER_TYPE.CompanyAdmin ? 'blue' : 'default'}>
            {i18n.t(`roles.${text}`)}
          </Tag>
        ),
      },
      {
        dataIndex: 'subsidiary',
        title: i18n.t('common.subsidiary'),
        sorter: (a, b, sortOrder) =>
          sortTableColumnWithEmptyValues(a.subsidiary, b.subsidiary, sortOrder),
        ...getColumnSearchProps({
          dataIndex: 'subsidiary',
          title: i18n.t('common.subsidiary'),
          renderWithHighlight: ({ text, highlightedNode }) =>
            text ? (
              <TextAvatarDiv>
                <ReactAvatar name={text} size="26px" /> {highlightedNode}
              </TextAvatarDiv>
            ) : (
              '-'
            ),
        }),
      },
      {
        dataIndex: 'actions',
        title: i18n.t('common.actions'),
        fixed: 'right',
      },
    ],
    [currentUser?.email, getColumnSearchProps],
  );

  const data: TableDataType[] = useMemo(
    () =>
      admins.map((admin) => {
        const canDelete = currentUser?.email !== admin.email;
        return {
          key: admin.id,
          id: admin.id,
          email: admin.email,
          name:
            [admin.first_name, admin.last_name]
              ?.map((name) => name?.trim())
              ?.filter(Boolean)
              ?.join(' ') || null,
          role: admin.__typename,
          subsidiary: admin.subsidiaryName || null,
          actions: (
            <ActionsDiv>
              <Tooltip
                title={
                  canDelete
                    ? i18n.t('companySettingsPage.adminsTab.deleteAdmin')
                    : i18n.t('companySettingsPage.adminsTab.cannotDeleteYourself')
                }
              >
                <Button
                  danger
                  disabled={!canDelete || isLoading}
                  onClick={() => {
                    openModal({
                      type: 'confirmation',
                      title: i18n.t('companySettingsPage.adminsTab.deleteAdmin'),
                      description: (
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: i18n.t<string>(
                              'companySettingsPage.adminsTab.areYouSureYouWantToDeleteAdminHtml',
                              {
                                email: admin.email,
                              },
                            ),
                          }}
                        />
                      ),
                      actionType: 'danger',
                      action: async () => {
                        try {
                          await deleteAdmin({
                            variables: {
                              adminId: admin.id,
                            },
                          });
                          await totalRefetch();
                          notification.success({
                            message: i18n.t('common.success'),
                            description: i18n.t(
                              'companySettingsPage.adminsTab.adminDeletedSuccess',
                            ),
                          });
                        } catch (error) {
                          notification.error({
                            message: (error as any)?.message || i18n.t('common.error'),
                          });
                        }
                      },
                    });
                  }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </ActionsDiv>
          ),
        };
      }),
    [admins, currentUser?.email, isLoading, openModal, deleteAdmin, totalRefetch],
  );

  return (
    <WrapperDiv>
      <SelectCompanySubsidiaryDiv>
        {isBackofficePage && (
          <div>
            <TabSectionHeader title={i18n.t('companyFeatures.selectedCompany')} />
            <SettingsSelectCompany isLoading={isLoading} />
          </div>
        )}
      </SelectCompanySubsidiaryDiv>
      <SettingsTopFlexDiv>
        <TabSectionHeader
          title={
            isLoading
              ? i18n.t('common.loading')
              : `${i18n.t('companySettingsPage.adminsTab.listOfAdmins')} (${admins.length})`
          }
        />
        <SettingsButtonsFlexDiv>
          <Button
            loading={isLoading}
            icon={<ReloadOutlined />}
            onClick={async () => {
              await totalRefetch();
            }}
          >
            {i18n.t('common.refresh')}
          </Button>
          <Button
            loading={isLoading}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              openModal({
                type: 'adminAddEdit',
                initialValues: undefined,
                refetchOnCompleted: totalRefetch,
              });
            }}
          >
            {i18n.t('companySettingsPage.adminsTab.createAdmin')}
          </Button>
        </SettingsButtonsFlexDiv>
      </SettingsTopFlexDiv>
      <StyledTable
        tableLayout="auto"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        sortDirections={['ascend', 'descend', 'ascend']}
        pagination={defaultTablePaginationProps}
      />
    </WrapperDiv>
  );
});

TabAdmins.displayName = 'TabAdmins';

export default TabAdmins;
