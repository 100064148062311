import { Button, Typography } from 'antd';
import { memo, useEffect } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import routes from '~/config/routes';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';

const { Title, Text } = Typography;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100dvh - ${theme.dimensions.navbarHeight}px);
  background-color: #f0f2f5;
`;

const StyledIcon = styled(FaExclamationTriangle)`
  font-size: 64px;
  color: #ffa940;
  margin-bottom: 24px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 16px !important;
`;

const StyledText = styled(Text)`
  margin-bottom: 24px;
  text-align: center;
  max-width: 400px;
`;

const NotFoundPage = memo(() => {
  const { isAuthenticated } = useAuthenticationContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(routes.login);
    }
  }, [navigate, isAuthenticated]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledIcon />
      <StyledTitle level={2}>{i18n.t('404.title')}</StyledTitle>
      <StyledText>{i18n.t('404.description')}</StyledText>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          navigate(routes.index);
        }}
      >
        {i18n.t('404.button')}
      </Button>
    </StyledContainer>
  );
});

NotFoundPage.displayName = 'NotFoundPage';

export default NotFoundPage;
