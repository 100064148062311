import { gql } from '@apollo/client';

export interface CreateCompanyM2MTokenMutationVariables {
  companyId: string;
  comment: string;
}

export interface CreateCompanyM2MTokenMutationData {
  generateM2MToken: {
    __typename: 'GenerateM2MTokenResult';
    id: string;
    token: string;
    m2m_token: {
      __typename: 'M2MToken';
      comment: string;
      created_at: string;
    };
  };
}

export default gql`
  mutation MutationCreateCompanyM2MToken($companyId: ID!, $comment: String!) {
    generateM2MToken(comment: $comment, company_id: $companyId) {
      id
      token
      m2m_token {
        comment
        created_at
      }
    }
  }
`;
