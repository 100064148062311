import { keyframes } from 'styled-components';

export const shine = keyframes`
  to {
    background-position-x: -400%;
  }
`;

export const fadeInWithDelay = keyframes`
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const floating = keyframes`
  0% {
    transform: translate(0,  0); 
  }
  25% {
    transform: translate(0, 3px);
  }
  50% {
    transform: translate(0,  -1px); 
  }
  75% {
    transform: translate(0, 3px);
  }
  100% {
    transform: translate(0, 0);
  }
`;
