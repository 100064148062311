import type { ReactNode } from 'react';
import {
  LuBellMinus,
  LuBellPlus,
  LuBookmarkMinus,
  LuBookmarkPlus,
  LuClock,
  LuFileVideo,
  LuFolderMinus,
  LuFolderPlus,
  LuHome,
  LuUserCheck,
  LuUserPlus,
  LuUserX,
  LuVideo,
  LuVideoOff,
} from 'react-icons/lu';

import type { LogType } from '~/types/log';

export default function getLogTypeIcon(type: LogType): ReactNode {
  switch (type) {
    // Alarm
    case 'ALARM_ADMINISTRATOR_ASSOCIATED':
      return <LuBellPlus />;
    case 'ALARM_ADMINISTRATOR_DISASSOCIATED':
      return <LuBellMinus />;
    // Agent
    case 'CREATE_CARRIER':
      return <LuUserPlus />;
    case 'CARRIER_ATTRIBUTE_SET':
    case 'CARRIER_ATTRIBUTES_SET':
      return <LuUserCheck />;
    case 'DELETE_CARRIER':
      return <LuUserX />;
    // Device
    case 'CARRIER_DEVICE_ASSOCIATED':
      return <LuFolderPlus />;
    case 'CARRIER_DEVICE_DISASSOCIATED':
      return <LuFolderMinus />;
    // Video stream
    case 'CARRIER_START_VIDEO_STREAM':
      return <LuVideo />;
    case 'CARRIER_STOP_VIDEO_STREAM':
      return <LuVideoOff />;
    case 'REQUEST_VIDEO_STREAM_STATUS':
      return <LuFileVideo />;
    // Subsidiary
    case 'CREATED':
    case 'SUBSIDIARY_UPDATED':
    case 'DELETED':
      return <LuHome />;
    case 'SUBSIDIARY_ADMIN_CREATED':
      return <LuBookmarkPlus />;
    case 'SUBSIDIARY_ADMIN_DELETED':
      return <LuBookmarkMinus />;
    default:
      return <LuClock />;
  }
}
