import i18n from '~/locales/i18n';
import { ALARM_TYPE, type Alarm } from '~/types/alarm';

export default function computeAlarmMessage(alarm?: Alarm): string {
  switch (alarm?.type) {
    case ALARM_TYPE.fall:
    case ALARM_TYPE.emergency:
    case ALARM_TYPE.attack:
    case ALARM_TYPE.traak_front:
    case ALARM_TYPE.traak_back:
    case ALARM_TYPE.stress:
    case ALARM_TYPE.abnormal_stops:
    case ALARM_TYPE.long_stops:
    case ALARM_TYPE.gas_danger:
    case ALARM_TYPE.gas_high:
      return i18n.t(`general.generalAlarms.${alarm.type}`);
    default:
      return '';
  }
}
