import { Space } from 'antd';
import { memo } from 'react';
import { BsSpeedometer2 } from 'react-icons/bs';

import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { GpsCoordinates } from '~/types/sensor';
import convertMpsToKmh from '~/utils/parse/convertMpsToKmh';

import LabelWithValue from './LabelWithValue';

interface Props {
  gps: GpsCoordinates | null | undefined;
}

const InfoSpeed = memo(({ gps }: Props) => {
  const speedMps = gps?.sp ?? 0;

  return (
    <Space align="center">
      <BsSpeedometer2 color={theme.colors.darkBlue} size={20} />
      <LabelWithValue
        data-id="DetailsPopup-InfoSpeed"
        label={i18n.t('carrierDetailsPopup.speed')}
        value={speedMps < 0 ? 'n/a' : `${convertMpsToKmh(speedMps).toFixed(2)} km/h`}
        hasCopyToClipboard={false}
      />
    </Space>
  );
});

InfoSpeed.displayName = 'InfoSpeed';

export default InfoSpeed;
