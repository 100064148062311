import { AGENT_STATUS, type Agent } from '~/types/agent';

export const agentStatusPriority = Object.freeze({
  [AGENT_STATUS.alert]: 4,
  [AGENT_STATUS.connectionLost]: 3,
  [AGENT_STATUS.inMission]: 2,
  [AGENT_STATUS.inSafeZone]: 1,
} satisfies Record<AGENT_STATUS, number>);

export default function getAgentStatusWeight({
  isOffline,
  status,
}: Pick<Agent, 'isOffline' | 'status'>): number {
  return isOffline && status !== AGENT_STATUS.alert ? 0 : agentStatusPriority[status];
}
