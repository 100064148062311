import { memo, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import RecenterMapButton from '~/components/maps/RecenterMapButton';
import {
  DEFAULT_MAP_ZOOM_LEVEL,
  DEFAULT_MAP_ZOOM_LEVEL_MIN,
  DEFAULT_MAP_ZOOM_LEVEL_MAX,
  DEFAULT_GPS_POSITION,
} from '~/config/defaults';
import mapThemes from '~/config/mapThemes';
import useMapSettings from '~/store/useMapSettings';
import theme from '~/theme';
import { AGENT_STATUS } from '~/types/agent';
import { AlarmWithCarrier } from '~/types/alarm';
import type { LocationHistoryPoint } from '~/types/locationHistory';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import computeAlarmMessage from '~/utils/alarm/computeAlarmMessage';
import { formatDateTime } from '~/utils/dateTime';
import getCenterFromCoordinates from '~/utils/map/getCenterFromCoordinates';
import mapFitBounds from '~/utils/map/mapFitBounds';

const WrapperDiv = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  background: ${theme.colors.thinGrey};
`;

const MapDiv = styled.div`
  width: 100%;
  height: 100%;
`;

interface Props {
  alarm: AlarmWithCarrier | undefined;
  pinPoint: LocationHistoryPoint;
}

const PinPointAlarmMapElement = memo(({ alarm, pinPoint }: Props) => {
  const [map, setMap] = useState<google.maps.Map>();

  const mapDivRef = useRef<HTMLDivElement>(null);

  const mapTheme = useMapSettings((state) => state.mapTheme);

  useEffect(() => {
    (async () => {
      if (!!map || !alarm?.carrier?.id || !mapDivRef.current || !window.google) {
        return;
      }

      const newMap = new window.google.maps.Map(mapDivRef.current, {
        mapId: mapThemes[mapTheme].id,
        mapTypeId: mapThemes[mapTheme].type,
        // A Map's styles property cannot be set when a mapId is present. When a mapId is present, Map styles are controlled via the cloud console.
        // styles: mapThemes[mapTheme].styles,
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: false,
        streetViewControl: false,
        mapTypeControlOptions: { position: window.google.maps.ControlPosition.TOP_RIGHT },
        zoomControlOptions: { position: window.google.maps.ControlPosition.RIGHT_TOP },
        minZoom: DEFAULT_MAP_ZOOM_LEVEL_MIN,
        maxZoom: DEFAULT_MAP_ZOOM_LEVEL_MAX,
        controlSize: 32,
      });
      newMap.setOptions({
        center: pinPoint ? getCenterFromCoordinates([pinPoint]) : DEFAULT_GPS_POSITION,
        zoom: DEFAULT_MAP_ZOOM_LEVEL,
      });
      const marker = new google.maps.Marker({
        position: pinPoint,
        map: newMap,
        icon: {
          path: 'M -8,-8 8,8 M 8,-8 -8,8',
          strokeColor: getAgentStatusColor({
            status: AGENT_STATUS.alert,
            isOffline: false,
            alertLevel: null,
          }),
          strokeWeight: 4,
        },
      });

      const infowindow = new google.maps.InfoWindow({
        content: `
          <div>
            <b>${window?.agentsCompleteNameMap?.[alarm?.carrier?.id] || alarm?.carrier?.completeName}</b>
            <br />
            ${formatDateTime(alarm?.created_at)}
            <br />
            ${computeAlarmMessage(alarm)}
          </div>
        `,
        ariaLabel: 'Uluru',
      });

      marker.addListener('click', () => {
        infowindow.open({
          anchor: marker,
          map: newMap,
        });
      });

      mapFitBounds({
        map: newMap,
        points: [pinPoint],
        lockZoomLevel: false,
      });

      setMap(newMap);
    })();
  }, [alarm, pinPoint, map, mapTheme]);

  return (
    <WrapperDiv>
      <RecenterMapButton map={map} locationPoints={[pinPoint]} lockZoomLevel={false} />
      <MapDiv ref={mapDivRef} data-id="pinpointMap" />
    </WrapperDiv>
  );
});

PinPointAlarmMapElement.displayName = 'PinPointAlarmMapElement';

export default PinPointAlarmMapElement;
