import { Space } from 'antd';
import type { TextProps } from 'antd/lib/typography/Text';
import Text from 'antd/lib/typography/Text';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import type { Alarm } from '~/types/alarm';
import computeAlarmStartingTime from '~/utils/alarm/computeAlarmStartingTime';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';
import getUserName from '~/utils/user/getUserName';

const FullWidthDiv = styled.div<{ align?: string }>`
  position: relative;
  width: 100%;
  text-align: ${(props) => (props.align ? props.align : '')};
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: start;
  justify-content: space-between;
`;

const UnshrinkableBoldRedText = styled(Text)<{ $alarmColor: string }>`
  color: ${(props) => props.$alarmColor};
  font-weight: 500;
  white-space: nowrap;
`;

const BoldRedClickableText = styled(Text)<{ $alarmColor: string }>`
  color: ${(props) => props.$alarmColor};
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

const AlarmAdminSpan = styled.span`
  text-align: right;
  margin-left: 8px;
`;

const AlarmAdminNameStrong = styled.strong`
  white-space: nowrap;
`;

interface Props extends TextProps {
  agent: Agent | undefined;
  alarm: Alarm;
  labelText: string;
}

const AlertItem = memo(({ agent, alarm, labelText }: Props) => {
  const { currentUser } = useCurrentUserContext();
  const { openModal } = useModalsContext();

  const { alertTimeFormatted } = useMemo(() => computeAlarmStartingTime(alarm), [alarm]);

  const alarmColor = useMemo(() => getAlarmColorFromLevel(alarm.level), [alarm.level]);

  return (
    <FullWidthDiv>
      <Space style={{ flexGrow: 1 }}>
        <UnshrinkableBoldRedText data-id="alarm-name" $alarmColor={alarmColor.text}>
          {labelText}
        </UnshrinkableBoldRedText>
        <UnshrinkableBoldRedText $alarmColor={alarmColor.text}>
          {alertTimeFormatted}
        </UnshrinkableBoldRedText>
      </Space>
      {alarm.administrator && alarm.administrator.id !== currentUser?.id ? (
        <AlarmAdminSpan data-id="alarm-admin">
          {i18n.t('general.generalAlarms.alarmAssigned')}{' '}
          <AlarmAdminNameStrong>{getUserName(alarm.administrator)}</AlarmAdminNameStrong>
        </AlarmAdminSpan>
      ) : (
        <BoldRedClickableText
          $alarmColor={alarmColor.text}
          onClick={() => {
            openModal({
              type: 'alarm',
              defaultTab: 'manage',
              agentId: agent?.id,
              alarmId: alarm?.id,
            });
          }}
          data-id="manage-alert-btn"
        >
          {i18n.t('carrierDetailsPopup.manageAlarm')}
        </BoldRedClickableText>
      )}
    </FullWidthDiv>
  );
});

AlertItem.displayName = 'AlertItem';

export default AlertItem;
