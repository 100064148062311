import { ALARM_LEVEL, type AlarmWithCarrier } from '~/types/alarm';

function getLevelNumber(level: ALARM_LEVEL) {
  switch (level) {
    case ALARM_LEVEL.Critical:
      return 1;
    case ALARM_LEVEL.Warning:
      return 2;
    case ALARM_LEVEL.Information:
      return 3;
    default:
      return 4;
  }
}

export default function getAlarmsSortedByLevelAndCreatedAt(
  alarms: AlarmWithCarrier[],
): AlarmWithCarrier[] {
  return alarms.sort((a, b) => {
    const levelA = getLevelNumber(a.level);
    const levelB = getLevelNumber(b.level);
    if (levelA !== levelB) {
      return levelA - levelB;
    }
    return b.created_at.localeCompare(a.created_at);
  });
}
