import type { Company } from '~/types/company';
import type { Location } from '~/types/location';

export enum USER_TYPE {
  SuperAdmin = 'SuperAdmin',
  CompanyAdmin = 'CompanyAdmin',
  SubsidiaryAdmin = 'SubsidiaryAdmin',
}

export interface UserType {
  __typename: USER_TYPE;
  id: string;
  type: USER_TYPE;
  email: string;
  first_name: string | null;
  last_name: string | null;
  company?: Company;
  subsidiary?: {
    __typename: 'Subsidiary';
    id: string;
    name: string;
    location: Location;
    company: Company;
  };
}
