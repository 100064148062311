import { memo } from 'react';

import MainMapElement, { type Props } from './MainMapElement';

const MainMap = memo(
  ({
    isLoading,
    isBlurred,
    initialZoom,
    initialLat,
    initialLng,
    followingLat,
    followingLng,
    isFocusedOnAgent,
  }: Props) => (
    <MainMapElement
      key={`${isLoading}${isBlurred}`}
      isLoading={isLoading}
      isBlurred={isBlurred}
      initialZoom={initialZoom}
      initialLat={initialLat}
      initialLng={initialLng}
      followingLat={followingLat}
      followingLng={followingLng}
      isFocusedOnAgent={isFocusedOnAgent}
    />
  ),
);

MainMap.displayName = 'MainMap';

export default MainMap;
