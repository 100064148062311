import { useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import type { ALARM_TYPE } from '~/types/alarm';
import type { AlarmConfigurationOverride } from '~/types/configurationAlarm';

import QUERY_ALARM_CONFIGURATION_OVERRIDES_FOR_AGENTS, {
  type AlarmConfigurationOverridesForAgentsQueryData,
  type AlarmConfigurationOverridesForAgentsQueryVariables,
} from './queries/QueryAlarmConfigurationOverridesForAgents';

export default function useQueryAlarmConfigurationOverridesForAgents({
  subsidiaryId,
  alarmConfigurationName,
  skip,
}: {
  subsidiaryId: string | undefined;
  alarmConfigurationName: ALARM_TYPE;
  skip: boolean;
}): {
  alarmConfigurationOverridesForAgents: AlarmConfigurationOverride[];
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => void;
} {
  const { data, loading, error, refetch } = useQuery<
    AlarmConfigurationOverridesForAgentsQueryData,
    AlarmConfigurationOverridesForAgentsQueryVariables
  >(QUERY_ALARM_CONFIGURATION_OVERRIDES_FOR_AGENTS, {
    variables: {
      subsidiaryId: subsidiaryId || '',
      alarmConfigurationName,
      nextToken: null,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: skip || !subsidiaryId,
  });

  return useMemo(
    () => ({
      alarmConfigurationOverridesForAgents: (data?.subsidiary?.carriers?.items || []).map(
        (item) => item.configuration,
      ),
      isLoading: loading,
      error,
      refetch,
    }),
    [data?.subsidiary?.carriers?.items, loading, error, refetch],
  );
}
