import { gql, useMutation, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

interface CreateCompanyAdminInput {
  email: string;
  companyId: string;
}

interface CreateCompanyAdminOutput {
  createAdministrator: {
    administrator: {
      id: string;
      email: string;
      first_name: string | null;
      last_name: string | null;
    };
  };
}

export default function useMutationCreateCompanyAdmin(): {
  createCompanyAdmin: (params: { variables: CreateCompanyAdminInput }) => Promise<unknown>;
  isLoading: boolean;
  error?: ApolloError;
  createdCompanyAdmin?: CreateCompanyAdminOutput['createAdministrator'];
} {
  const [createCompanyAdmin, { loading, error, data }] = useMutation<
    CreateCompanyAdminOutput,
    CreateCompanyAdminInput
  >(
    gql`
      mutation MutationCreateCompanyAdmin($email: AWSEmail!, $companyId: ID!) {
        createAdministrator(email: $email, company_id: $companyId) {
          administrator {
            id
            email
            first_name
            last_name
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return useMemo(
    () => ({
      createCompanyAdmin,
      isLoading: loading,
      error,
      createdCompanyAdmin: data?.createAdministrator,
    }),
    [createCompanyAdmin, data?.createAdministrator, error, loading],
  );
}
