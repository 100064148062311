import { DEFAULT_GPS_SENSOR } from '~/config/defaults';
import type { Agent } from '~/types/agent';
import type { GpsSensorType } from '~/types/sensor';

export default function computeVehicleLocation(agents: Agent[]): GpsSensorType {
  if (!agents) {
    return DEFAULT_GPS_SENSOR;
  }

  // Sort agents by location timestamp and then return the most recent one
  const sortedAgents = agents
    .filter((agent) => !!agent?.sensors?.gps)
    .sort((a, b) => {
      const aTimestamp = a.sensors?.gps?.timestamp
        ? new Date(a.sensors.gps.timestamp).getTime()
        : 0;
      const bTimestamp = b.sensors?.gps?.timestamp
        ? new Date(b.sensors.gps.timestamp).getTime()
        : 0;
      return bTimestamp - aTimestamp;
    });

  return sortedAgents[0]?.sensors?.gps || DEFAULT_GPS_SENSOR;
}
