import type { SortOrder } from 'antd/es/table/interface';

export default function sortTableColumnWithEmptyValues(
  a: string | null,
  b: string | null,
  sortOrder: SortOrder | undefined,
) {
  if (a === null) {
    return sortOrder === 'ascend' ? 1 : -1;
  }
  if (b === null) {
    return sortOrder === 'ascend' ? -1 : 1;
  }
  return a.toLowerCase().localeCompare(b.toLowerCase());
}
