import Popover from 'antd/lib/popover';
import { memo, type ReactNode } from 'react';
import styled from 'styled-components';

import i18n from '~/locales/i18n';

const H4 = styled.h4`
  margin: 0 0 0.5rem;
  font-size: 16px;
`;

const H5 = styled.h5`
  margin: 0 0 0.5rem;
  font-size: 14px;
`;

const Ul = styled.ul`
  margin: 0;
  padding-inline-start: 24px;
`;

interface Props {
  children: ReactNode;
}

const PasswordPolicyPopover = memo(({ children }: Props) => (
  <Popover
    placement="right"
    trigger="focus"
    content={
      <>
        <H4>{i18n.t('passwordPolicyPopover.title')}</H4>
        <H5>{i18n.t('passwordPolicyPopover.rulesTitle')}</H5>
        <Ul>
          <li>{i18n.t('passwordPolicyPopover.minLength')}</li>
          <li>{i18n.t('passwordPolicyPopover.number')}</li>
          <li>{i18n.t('passwordPolicyPopover.specialCharacter')}</li>
          <li>{i18n.t('passwordPolicyPopover.uppercaseLetter')}</li>
          <li>{i18n.t('passwordPolicyPopover.lowercaseLetter')}</li>
        </Ul>
      </>
    }
  >
    {children}
  </Popover>
));

PasswordPolicyPopover.displayName = 'PasswordPolicyPopover';

export default PasswordPolicyPopover;
