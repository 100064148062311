import { memo, useMemo, useCallback } from 'react';

import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';

import { StyledSelect } from './SettingsSelectCompany';

interface Props {
  disabled?: boolean;
  isLoading?: boolean;
}

const SettingsSelectSubsidiary = memo(({ disabled = false, isLoading }: Props) => {
  const { setCurrentSubsidiaryId, currentSubsidiary, subsidiaryList } = useSubsidiary();

  const options = useMemo(
    () =>
      subsidiaryList.map((subsidiary) => ({
        label: subsidiary.name,
        value: subsidiary.id,
        disabled: false,
      })),
    [subsidiaryList],
  );

  const value: string = useMemo(
    () =>
      subsidiaryList.find((subsidiary) => subsidiary.id === currentSubsidiary?.id)?.name ||
      i18n.t('header.selectSubsidiary'),
    [subsidiaryList, currentSubsidiary?.id],
  );

  const handleChange = useCallback(
    (subsidiaryId: string) => {
      setCurrentSubsidiaryId(subsidiaryId);
    },
    [setCurrentSubsidiaryId],
  );

  const hasNoSubsidiaries = subsidiaryList.length === 0;

  return (
    <StyledSelect
      value={hasNoSubsidiaries ? currentSubsidiary?.id : value}
      onChange={handleChange}
      placeholder={i18n.t('header.selectSubsidiary')}
      options={
        hasNoSubsidiaries
          ? [
              {
                label: currentSubsidiary?.name || '',
                value: currentSubsidiary?.id || '',
                disabled: true,
              },
            ]
          : options
      }
      disabled={disabled || isLoading}
      loading={isLoading}
    />
  );
});

SettingsSelectSubsidiary.displayName = 'SettingsSelectSubsidiary';

export default SettingsSelectSubsidiary;
