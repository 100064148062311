import { gql } from '@apollo/client';

export interface DeleteAgentMutationVariables {
  agentId: string;
}

export interface DeleteAgentMutationData {
  deleteCarrier: string;
}

export default gql`
  mutation MutationDeleteAgent($agentId: ID!) {
    deleteCarrier(carrier_id: $agentId)
  }
`;
