import DownOutlined from '@ant-design/icons/DownOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import UpOutlined from '@ant-design/icons/UpOutlined';
import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined';
import { ConfigProvider, Button, Tooltip } from 'antd';
import { memo, useState, type CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import AlarmSteps from '~/components/AlarmSteps';
import AlarmTypePill from '~/components/AlarmTypePill';
import TimeAgo from '~/components/TimeAgo';
import routes from '~/config/routes';
import useModalsContext from '~/context/useModalsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n, { currentLanguage } from '~/locales/i18n';
import theme from '~/theme';
import type { ALARM_LEVEL, AlarmWithCarrier } from '~/types/alarm';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';
import getTranslatedAlarmDismissReason from '~/utils/alarm/getTranslatedAlarmDismissReason';
import isAlarmOngoing from '~/utils/alarm/isAlarmOngoing';
import { formatDateTime } from '~/utils/dateTime';

const Article = styled.article<{ $alarmLevel: ALARM_LEVEL }>`
  background-color: ${theme.colors.white};
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.thinGrey};
  border-bottom: 2px solid ${(props) => getAlarmColorFromLevel(props.$alarmLevel).text};
  cursor: pointer;

  &:hover {
    background-color: #fbfbfb;
  }
`;

const TopDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  ${theme.medias.lteSmall} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TopRightDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${theme.medias.lteSmall} {
    width: 100%;
    margin-top: 16px;
    justify-content: space-between;
  }
`;

const TopActionsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const NameDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const AgentNameH4 = styled.h4`
  font-size: 1rem;
  margin: 0;
  line-height: 1;
`;

const ReasonDiv = styled.div`
  display: inline-block;
`;

const BottomDiv = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

const BottomActionsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${theme.medias.lteSmall} {
    flex-direction: column;
  }
`;

interface Props {
  alarm: AlarmWithCarrier;
  initialOpen: boolean;
  className?: string;
  style?: CSSProperties;
}

const AlarmNotificationCard = memo(({ alarm, initialOpen, className, style }: Props) => {
  const { companyFeatures } = useCompanyFeatures();
  const navigate = useNavigate();
  const { openModal } = useModalsContext();
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen);

  const isOngoing = isAlarmOngoing(alarm);

  return (
    <Article
      $alarmLevel={alarm.level}
      style={style}
      className={className}
      role="button"
      onClick={() => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
      }}
    >
      <TopDiv>
        <div>
          <NameDiv>
            <AgentNameH4>
              {alarm?.carrier?.completeName || i18n.t(`general.generalAlarms.${alarm.type}`)}
            </AgentNameH4>
            <AlarmTypePill alarm={alarm} />
          </NameDiv>
          <Tooltip
            placement="left"
            title={new Date(alarm?.created_at).toLocaleString(currentLanguage(), {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            })}
          >
            <ReasonDiv>
              <b>
                {alarm?.dismiss_reason
                  ? getTranslatedAlarmDismissReason(alarm.dismiss_reason)
                  : i18n.t('alarmsPage.steps.ongoing')}
              </b>{' '}
              <TimeAgo date={alarm?.created_at} />
            </ReasonDiv>
          </Tooltip>
        </div>
        <TopRightDiv>
          {isOngoing && (
            <TopActionsDiv>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: getAlarmColorFromLevel(alarm.level).text,
                  },
                }}
              >
                <Button
                  type="default"
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(routes.status({ id: alarm?.carrier?.id }));
                  }}
                >
                  {i18n.t('alarmsPage.card.viewAgent')}
                </Button>
                <Button
                  type="primary"
                  onClick={(event) => {
                    event.stopPropagation();
                    openModal({
                      type: 'alarm',
                      defaultTab: 'manage',
                      agentId: alarm?.carrier?.id,
                      alarmId: alarm?.id,
                    });
                  }}
                >
                  {i18n.t('carrierDetailsPopup.alarmControlModal.title')}
                </Button>
              </ConfigProvider>
            </TopActionsDiv>
          )}
          {isOpen ? <UpOutlined /> : <DownOutlined />}
        </TopRightDiv>
      </TopDiv>
      {isOpen && (
        <BottomDiv>
          <AlarmSteps alarm={alarm} style={{ marginBottom: '-12px' }} />
          <BottomActionsDiv>
            {!isOngoing && companyFeatures.playbackRecordedVideos && (
              <Tooltip
                title={
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: i18n.t<string>('alarmsPage.card.videosTooltipHtml', {
                        agentName: alarm?.carrier?.completeName,
                      }),
                    }}
                  />
                }
                placement="left"
              >
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    openModal({
                      type: 'videoRecordings',
                      defaultAgentId: alarm?.carrier?.id,
                      initialDateRange: [
                        formatDateTime(alarm?.created_at),
                        formatDateTime(alarm?.dismissed_at || ''),
                      ],
                    });
                  }}
                  icon={<VideoCameraOutlined />}
                >
                  {i18n.t('alarmsPage.card.videos')}
                </Button>
              </Tooltip>
            )}
            <Tooltip
              title={i18n.t('alarmsPage.card.detailsTooltip')}
              placement={isOngoing ? 'left' : 'topRight'}
            >
              <Button
                type="default"
                onClick={(event) => {
                  event.stopPropagation();
                  openModal({
                    type: 'alarm',
                    defaultTab: 'details',
                    alarmId: alarm?.id,
                    agentId: alarm?.carrier?.id,
                  });
                }}
                icon={<InfoCircleOutlined />}
              >
                {i18n.t('common.details')}
              </Button>
            </Tooltip>
          </BottomActionsDiv>
        </BottomDiv>
      )}
    </Article>
  );
});

AlarmNotificationCard.displayName = 'AlarmNotificationCard';

export default AlarmNotificationCard;
