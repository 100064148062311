import { memo, type CSSProperties } from 'react';
import ReactPhoneNumberInput, { type Country, type Value } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';

import isValidPhoneNumber from '~/utils/phoneNumber/isValidPhoneNumber';

const Div = styled.div<{ $isValid: boolean }>`
  padding-left: 4px;

  & .PhoneInputCountrySelect {
    &:disabled {
      cursor: not-allowed !important;
    }
  }

  & .PhoneInputInput {
    height: 30px;
    padding: 0 11px;
    border: 1px solid ${(props) => (props.$isValid ? '#d9d9d9' : 'red')} !important;
    background-color: #ffffff;
    border-radius: 6px;
    outline: none !important;

    &:disabled {
      color: rgba(0, 0, 0, 0.25) !important;
      background: rgba(0, 0, 0, 0.04) !important;
      cursor: not-allowed !important;
    }
  }
`;

interface Props {
  value: string | undefined;
  onChange(value: string): void;
  disabled?: boolean;
  placeholder?: string;
  defaultCountry?: Country;
  className?: string;
  style?: CSSProperties;
  'data-id'?: string;
}

const PhoneNumberInput = memo(
  ({
    value,
    onChange,
    disabled = false,
    placeholder = 'Enter phone number',
    defaultCountry = 'CH',
    className,
    style,
    'data-id': dataId,
  }: Props) => {
    const isPossible = value ? isValidPhoneNumber(value) : true;

    return (
      <Div $isValid={isPossible}>
        <ReactPhoneNumberInput
          data-id={dataId}
          className={className}
          style={style}
          international
          countryCallingCodeEditable={false}
          defaultCountry={defaultCountry}
          placeholder={placeholder}
          value={value as Value}
          disabled={disabled}
          onChange={(number) => {
            onChange(number || '');
          }}
        />
      </Div>
    );
  },
);

PhoneNumberInput.displayName = 'PhoneNumberInput';

export default PhoneNumberInput;
