import { Tooltip } from 'antd';
import { memo, type ReactNode, type CSSProperties } from 'react';
import styled from 'styled-components';

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Li = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

export interface ListItem {
  title?: string;
  description?: ReactNode;
  descriptionTooltip?: ReactNode;
}

interface Props {
  className?: string;
  style?: CSSProperties;
  items: ListItem[];
}

const PopupSectionList = memo(({ items, className, style }: Props) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <Ul className={className} style={style}>
      {items.map((item) => (
        <Li key={item.title}>
          {item.title && <b>{item.title}:</b>}
          {item.description && (
            <span style={{ cursor: item.descriptionTooltip ? 'help' : 'auto' }}>
              {item.descriptionTooltip ? (
                <Tooltip title={item.descriptionTooltip} placement="right">
                  {item.description}
                </Tooltip>
              ) : (
                item.description
              )}
            </span>
          )}
        </Li>
      ))}
    </Ul>
  );
});

PopupSectionList.displayName = 'PopupSectionList';

export default PopupSectionList;
