import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { gql, useQuery } from '@apollo/client';
import { Button, Spin } from 'antd';
import kebabCase from 'lodash/kebabCase';
import { useEffect, useState, useMemo, type ReactNode, memo } from 'react';
import styled from 'styled-components';

import Container from '~/components/Container';
import PageHeader from '~/components/PageHeader';
import PageSideTabs from '~/components/PageSideTabs';
import type { CompanyFeatures } from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Company } from '~/types/company';
import type { Subsidiary } from '~/types/subsidiary';
import logger from '~/utils/logger';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 300px) minmax(0, 1fr);

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const VerticalContainer = styled(Container)`
  padding-top: 16px;
  padding-bottom: 16px;
`;

const LeftContainer = styled(Container)`
  padding-bottom: 0;

  ${theme.medias.lteSmall} {
    padding-bottom: 16px;
  }
`;

const IframeDiv = styled.div`
  background: ${theme.colors.lightGrey};
  width: 100%;
  min-height: calc(100dvh - ${theme.dimensions.navbarHeight}px);
`;

const Iframe = styled.iframe<{ $active?: boolean }>`
  display: ${(props) => (props.$active ? 'block' : 'none')};
  border: 0;
  width: 100%;
  min-height: 100%;
`;

const HelpDiv = styled.div`
  display: inline-flex;
  gap: 8px;
  font-size: 16px;
`;

const RetryStyledHelpDiv = styled(HelpDiv)`
  flex-direction: column;
`;

const RetryButton = styled(Button)`
  margin-top: 16px;
`;

interface QuickSightDashboard {
  name: string;
  embedUrl: string;
}

interface Props {
  companyFeatures: CompanyFeatures;
  currentCompany: Company | undefined;
  currentSubsidiary: Subsidiary | undefined;
}

const QuickSightReports = memo(({ companyFeatures, currentCompany, currentSubsidiary }: Props) => {
  const [activeReport, setActiveReport] = useState<QuickSightDashboard | null>(null);
  const [loadedIframes, setLoadedIframes] = useState<Record<string, boolean>>({});

  const { loading, error, data, refetch } = useQuery<
    {
      getDashboards: QuickSightDashboard[];
    },
    {
      companyId: string;
      subsidiaryId?: string;
    }
  >(
    gql`
      query ($companyId: String, $subsidiaryId: String) {
        getDashboards(companyId: $companyId, subsidiaryId: $subsidiaryId) {
          name
          embedUrl
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      skip: !currentCompany,
      variables: { companyId: currentCompany?.id || '', subsidiaryId: currentSubsidiary?.id },
    },
  );

  const reports = useMemo(() => data?.getDashboards || [], [data?.getDashboards]);

  useEffect(() => {
    setLoadedIframes({});
    refetch();
  }, [refetch, currentCompany, currentSubsidiary]);

  useEffect(() => {
    if (reports[0]) {
      setActiveReport(reports[0]);
    }
  }, [reports]);

  const hasLoaded =
    reports.length === Object.keys(loadedIframes).length &&
    Object.values(loadedIframes).every((loaded) => loaded);

  const loadingComponent = (
    <HelpDiv>
      <Spin />
      <span>{i18n.t('common.loading')}</span>
    </HelpDiv>
  );

  const renderHelpWithRetry = (node: ReactNode) => (
    <RetryStyledHelpDiv>
      <div>
        <div>{node}</div>
        <RetryButton
          icon={<ReloadOutlined />}
          onClick={() => {
            refetch();
          }}
        >
          {i18n.t('reportsPage.helpRetry')}
        </RetryButton>
      </div>
    </RetryStyledHelpDiv>
  );

  if (!currentCompany) {
    return (
      <VerticalContainer>
        <HelpDiv>{i18n.t('reportsPage.helpNoCompany')}</HelpDiv>
      </VerticalContainer>
    );
  }

  if (!companyFeatures.dataAnalysisReports) {
    return (
      <VerticalContainer>
        <HelpDiv>{i18n.t('reportsPage.helpNoFeatureFlag')}</HelpDiv>
      </VerticalContainer>
    );
  }

  const isIframeLoading = !hasLoaded || loading;
  const isIframeNoReportsError = hasLoaded && !loading && reports.length === 0;
  const isIframeGeneralError = !!error;

  return (
    <GridDiv>
      <LeftContainer>
        <PageHeader title={i18n.t('reportsPage.title')} subtitle={i18n.t('reportsPage.subtitle')} />
        {loading ? (
          loadingComponent
        ) : (
          <PageSideTabs
            activeItemKey={activeReport?.name}
            items={reports.map((report) => ({
              key: report.name,
              dataId: `reports-${kebabCase(report.name)}-link`,
              icon: null,
              label: report.name,
              onClick: () => {
                setActiveReport(report);
                logger.log('QuickSightReports: open report', { name: report.name });
              },
            }))}
          />
        )}
      </LeftContainer>
      <IframeDiv>
        {(isIframeLoading || isIframeNoReportsError || isIframeGeneralError) && (
          <VerticalContainer>
            {isIframeLoading && loadingComponent}
            {(isIframeGeneralError || isIframeNoReportsError) &&
              renderHelpWithRetry(
                isIframeGeneralError && error.message
                  ? error.message
                  : i18n.t('reportsPage.helpNoReports'),
              )}
          </VerticalContainer>
        )}
        {reports.map((report) => (
          <Iframe
            key={report.name}
            title={report.name}
            src={report.embedUrl}
            $active={!isIframeLoading && hasLoaded && activeReport?.name === report.name}
            onLoad={() => {
              setLoadedIframes((prev) => ({ ...prev, [report.name]: true }));
            }}
            onError={() => {
              setLoadedIframes((prev) => ({ ...prev, [report.name]: true }));
              logger.log('QuickSightReports: iframe report error', { report });
            }}
          />
        ))}
      </IframeDiv>
    </GridDiv>
  );
});

QuickSightReports.displayName = 'QuickSightReports';

export default QuickSightReports;
