import isBoolean from 'lodash/isBoolean';
import type { ReactNode } from 'react';

import i18n from '~/locales/i18n';

export default function getYesOrNo(
  value: boolean | null | undefined,
  placeholderValue?: ReactNode,
): ReactNode {
  if (isBoolean(value)) {
    return value ? i18n.t('common.yes') : i18n.t('common.no');
  }
  return placeholderValue || value;
}
