import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

import type { ALARM_TYPE } from '~/types/alarm';
import type { AlarmConfiguration } from '~/types/configurationAlarm';

export interface SetConfigurationAlarmDefaultVariables {
  name: ALARM_TYPE | undefined;
  configuration: AlarmConfiguration | undefined;
}

export interface SetConfigurationAlarmDefaultData {
  setConfigurationAlarmDefault: {
    name: string;
  };
}

export default function useMutationSetConfigurationAlarmDefault() {
  const [setConfigurationAlarmDefault, { data, loading, error }] = useMutation<
    SetConfigurationAlarmDefaultData,
    SetConfigurationAlarmDefaultVariables
  >(
    gql`
      mutation MutationSetConfigurationAlarmDefault(
        $name: String!
        $configuration: setConfigurationAlarmDefaultInput
      ) {
        setConfigurationAlarmDefault(name: $name, configuration: $configuration) {
          name
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return useMemo(
    () => ({
      setConfigurationAlarmDefault,
      result: data?.setConfigurationAlarmDefault,
      isLoading: loading,
      error,
    }),
    [setConfigurationAlarmDefault, data?.setConfigurationAlarmDefault, error, loading],
  );
}
