import { gql } from '@apollo/client';

import type { Location } from '~/types/location';

export interface CompanySubsidiaryListQueryVariables {
  companyID: string;
}

export interface CompanySubsidiaryListQueryData {
  company: {
    __typename: 'Company';
    id: string;
    subsidiaries: {
      __typename: 'SubsidiaryConnection';
      items: {
        __typename?: 'Subsidiary';
        id: string;
        name: string;
        location: Location;
      }[];
    };
  };
}

export default gql`
  query QueryCompanySubsidiaryList($companyID: ID!, $nextToken: String) {
    company(id: $companyID) {
      id
      subsidiaries(limit: 1000, nextToken: $nextToken) {
        nextToken
        items {
          id
          name
          location {
            lat
            lng
          }
        }
      }
    }
  }
`;
