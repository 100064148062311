import { useMutation } from '@apollo/client';
import { useMemo } from 'react';

import MUTATION_DISASSOCIATE_ALARM_ADMIN, {
  type DisassociateAlarmAdminMutationData,
  type DisAssociateAlarmAdminMutationVariables,
} from './mutations/MutationDisassociateAlarmAdmin';

export default function useMutationDisassociateAlarmAdmin() {
  const [disassociateAlarmAdmin, { loading, error }] = useMutation<
    DisassociateAlarmAdminMutationData,
    DisAssociateAlarmAdminMutationVariables
  >(MUTATION_DISASSOCIATE_ALARM_ADMIN);

  return useMemo(
    () => ({
      disassociateAlarmAdmin,
      loading,
      error,
    }),
    [disassociateAlarmAdmin, error, loading],
  );
}
