import { memo, useState, useEffect, type ReactNode } from 'react';

import useAuthenticationContext from '~/context/useAuthenticationContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';

import PrivateLayout from './components/PrivateLayout';
import PublicLayout from './components/PublicLayout';

interface Props {
  children: ReactNode;
}

const AppLayout = memo(({ children }: Props) => {
  const { currentUser, isLoading } = useCurrentUserContext();
  const { isAuthenticated } = useAuthenticationContext();

  const [hasLoaded, setHasLoaded] = useState<boolean>(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setHasLoaded(true);
    }, 2_500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      {(hasLoaded || !isLoading) && (
        <div data-id="loadedLayout" data-logged-in-email={currentUser?.email} />
      )}
      {isAuthenticated ? (
        <PrivateLayout>{children}</PrivateLayout>
      ) : (
        <PublicLayout>{children}</PublicLayout>
      )}
    </>
  );
});

AppLayout.displayName = 'AppLayout';

export default AppLayout;
