import { Tooltip } from 'antd';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import useAlarmsContext from '~/context/useAlarmsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AGENT_STATUS, type Agent } from '~/types/agent';
import type { Vehicle } from '~/types/vehicle';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import sortAgentsByStatusAndName from '~/utils/agent/sortAgentsByStatusAndName';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';
import getHighestAlarmLevel from '~/utils/alarm/getHighestAlarmLevel';
import curateUrl from '~/utils/parse/curateUrl';

const BadgeDiv = styled.div`
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
`;

const AlarmIconImg = styled.img`
  height: 14px;
  transform: translateY(-1px);
`;

const H4 = styled.h4`
  margin: 0;
  font-size: 14px;
  line-height: 1;
  color: ${theme.colors.white};
`;

const TooltipH5 = styled.h5`
  margin: 0 0 8px;
  font-size: 14px;
`;

const TooltipUl = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 12px;
`;

interface Props {
  agent: Agent | undefined;
  vehicle: Vehicle | undefined;
}

const StatusBadge = memo(({ agent, vehicle }: Props) => {
  const { ongoingAlarms } = useAlarmsContext();

  const hasAlarm = agent?.status === AGENT_STATUS.alert;

  const status = agent?.status ?? AGENT_STATUS.connectionLost;
  const isOffline = agent?.isOffline;

  const hasTooltip = vehicle && vehicle?.agents?.length > 0;

  const agentAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.carrier.id === agent?.id),
    [agent, ongoingAlarms],
  );

  const highestLevelAlarm = useMemo(() => getHighestAlarmLevel(agentAlarms), [agentAlarms]);

  const backgroundColor = highestLevelAlarm
    ? getAlarmColorFromLevel(highestLevelAlarm).text
    : getAgentStatusColor(agent);

  const content = (
    <BadgeDiv style={{ backgroundColor, cursor: hasTooltip ? 'help' : 'default' }}>
      {hasAlarm && <AlarmIconImg src={curateUrl('/icons/alert/alert-small-white.png')} alt="" />}
      <H4 data-id="status-badge">
        {i18n.t(
          `general.generalAlarms.${isOffline && status !== AGENT_STATUS.alert ? 'offline' : status}`,
        )}
      </H4>
    </BadgeDiv>
  );

  if (hasTooltip) {
    const sortedAgents = sortAgentsByStatusAndName(vehicle.agents);

    return (
      <Tooltip
        placement="right"
        title={
          <>
            <TooltipH5>{`${i18n.t('common.vehicle')} (${vehicle.plateNumber})`}</TooltipH5>
            <TooltipUl>
              {sortedAgents.map((a) => (
                <li key={a.id}>
                  <b>{a.completeName}:</b> {i18n.t(`general.generalAlarms.${a.status}`)}
                </li>
              ))}
            </TooltipUl>
          </>
        }
      >
        {content}
      </Tooltip>
    );
  }

  return content;
});

export default StatusBadge;
