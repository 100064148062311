import AimOutlined from '@ant-design/icons/AimOutlined';
import { Button, Tooltip } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import i18n from '~/locales/i18n';
import type { LocationHistoryPoint } from '~/types/locationHistory';
import mapFitBounds from '~/utils/map/mapFitBounds';

const RecenterButton = styled(Button)`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;
`;

export interface Props {
  map: google.maps.Map | undefined;
  locationPoints: LocationHistoryPoint[];
  lockZoomLevel: boolean;
}

const RecenterMapButton = memo(({ map, locationPoints, lockZoomLevel }: Props) => {
  if (!map) {
    return null;
  }

  return (
    <Tooltip placement="right" title={i18n.t('map.recenter')}>
      <RecenterButton
        icon={<AimOutlined />}
        onClick={() => {
          mapFitBounds({
            map,
            points: locationPoints,
            lockZoomLevel,
          });
        }}
      />
    </Tooltip>
  );
});

RecenterMapButton.displayName = 'RecenterMapButton';

export default RecenterMapButton;
