import { gql } from '@apollo/client';

export interface MeQueryData {
  __typename: 'Query';
}

export default gql`
  query QueryMe {
    __typename
  }
`;
