import { alertSoundMutedVar } from '~/apollo/reactiveVariables/alertSoundMutedVar';
import logger from '~/utils/logger';
import curateUrl from '~/utils/parse/curateUrl';

const REACTIVATE_TIMEOUT_MS = 10 * 60 * 1000;

const alarm = new Audio(curateUrl('/sounds/alarm.wav'));
const notificationSmall = new Audio(curateUrl('/sounds/notification-small.wav'));

let muted = false;
let timeoutId: number;

window.alarm = alarm; // used in e2e tests

alarm.loop = true;
notificationSmall.loop = false;

export function playAlarmSound(): void {
  if (muted) {
    return;
  }
  alarm.play()?.catch((error) => {
    logger.log('utils/sounds/playAlarm: alarm play failed', { error });
  });
}

export function stopAlarmSound(): void {
  window.clearTimeout(timeoutId);
  alarm.pause();
  alarm.currentTime = 0;
}

export function unmuteAlarmSound(): void {
  window.clearTimeout(timeoutId);
  muted = false;
  alertSoundMutedVar(false);
  playAlarmSound();
}

export function muteAlarmSound(): void {
  window.clearTimeout(timeoutId);
  timeoutId = window.setTimeout(unmuteAlarmSound, REACTIVATE_TIMEOUT_MS);
  muted = true;
  alertSoundMutedVar(true);
  stopAlarmSound();
}

export function playNotificationSound(): void {
  notificationSmall.pause();
  notificationSmall.currentTime = 0;
  notificationSmall.play();
}
