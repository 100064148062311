import { gql } from '@apollo/client';

export interface CreateAgentNonCognitoMutationVariables {
  subsidiaryId: string;
  name: string;
  attributes: {
    name: string;
    value: string;
  }[];
}

export interface CreateAgentNonCognitoMutationData {
  createNonCognitoCarrier: {
    __typename: 'CreateNonCognitoCarrierResult';
    id: string;
  };
}

export default gql`
  mutation MutationCreateAgentNonCognito(
    $subsidiaryId: ID!
    $name: String!
    $attributes: [AttributeInput!]
  ) {
    createNonCognitoCarrier(subsidiary_id: $subsidiaryId, name: $name, attributes: $attributes) {
      id
    }
  }
`;
