import { notification as antdNotification } from 'antd';

type NotificationApi = typeof antdNotification;

type Options = Parameters<NotificationApi['open']>[0];

const defaultOptions: Partial<Options> = {
  placement: 'topRight',
  duration: 3,
};

const notification: Pick<NotificationApi, 'success' | 'info' | 'warning' | 'error' | 'destroy'> = {
  ...antdNotification,
  success: (options: Options) => {
    antdNotification.success({
      ...defaultOptions,
      ...options,
    });
  },
  info: (options: Options) => {
    antdNotification.info({
      ...defaultOptions,
      ...options,
    });
  },
  warning: (options: Options) => {
    antdNotification.warning({
      ...defaultOptions,
      ...options,
    });
  },
  error: (options: Options) => {
    antdNotification.error({
      ...defaultOptions,
      ...options,
    });
  },
};

export default notification;
