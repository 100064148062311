import { useMemo } from 'react';

import routes from '~/config/routes';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';

interface NavbarLink {
  key: string;
  to: string;
  title: string;
  badgeCount: number;
}

export default function useNavbarLinks(): NavbarLink[] {
  const { companyFeatures } = useCompanyFeatures();

  const navbarLinks: NavbarLink[] = useMemo(
    () => [
      {
        key: 'map',
        to: routes.map(),
        title: i18n.t('header.menu.map'),
        badgeCount: 0,
      },
      {
        key: 'agents',
        to: routes.agents,
        title: i18n.t('header.menu.agents'),
        badgeCount: 0,
      },
      ...(companyFeatures.dataAnalysisReports
        ? [
            {
              key: 'reports',
              to: routes.reports,
              title: i18n.t('header.menu.reports'),
              badgeCount: 0,
            },
          ]
        : []),
    ],
    [companyFeatures.dataAnalysisReports],
  );

  return navbarLinks;
}
