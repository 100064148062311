import styled from 'styled-components';

import theme from '~/theme';

// Settings list page

export const SettingsGridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 310px) minmax(0, 1fr);
  grid-gap: 16px;

  ${theme.medias.lteSmall} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

export const SettingsStickyDiv = styled.div`
  position: sticky;
  top: calc(${theme.dimensions.navbarHeight}px + 16px);

  ${theme.medias.lteSmall} {
    position: initial;
    top: 0;
  }
`;

export const SettingsTabTitleH3 = styled.h3`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 8px;

  & > span {
    font-size: 14px;
    font-weight: normal;
  }
`;

export const SettingsTabContentDiv = styled.div`
  padding-top: 22px;
  padding-bottom: 64px;
`;

// Settings content page

export const SelectCompanySubsidiaryDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
  margin-bottom: 8px;

  ${theme.medias.lteSmall} {
    display: block;
  }
`;

export const SettingsTopFlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${theme.medias.lteMedium} {
    display: block;
    margin-bottom: 16px;
  }
`;

export const SettingsButtonsFlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  flex-wrap: wrap;
`;
