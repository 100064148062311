import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

export interface DeleteSubsidiaryVariables {
  subsidiary_id: string;
}

export interface DeleteSubsidiaryData {
  deleteSubsidiary: {
    __typename: 'Subsidiary';
    id: string;
  };
}

export default function useMutationDeleteSubsidiary() {
  const [deleteSubsidiary, { data, loading, error }] = useMutation<
    DeleteSubsidiaryData,
    DeleteSubsidiaryVariables
  >(
    gql`
      mutation MutationDeleteSubsidiary($subsidiary_id: ID!) {
        deleteSubsidiary(subsidiary_id: $subsidiary_id)
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return useMemo(
    () => ({
      deleteSubsidiary,
      result: data?.deleteSubsidiary,
      isLoading: loading,
      error,
    }),
    [deleteSubsidiary, data?.deleteSubsidiary, error, loading],
  );
}
