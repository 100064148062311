import BookOutlined from '@ant-design/icons/BookOutlined';
import { createGraphiQLFetcher, type Fetcher } from '@graphiql/toolkit';
import { Button, Input, Modal, Tooltip } from 'antd';
import { GraphiQL } from 'graphiql';
import { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'graphiql/graphiql.min.css';

import Link from '~/components/Link';
import routes from '~/config/routes';
import variables from '~/config/variables';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';

const M2M_TOKEN_SESSION_KEY = 'm2mToken';

const WrapperDiv = styled.div`
  height: calc(100dvh - ${theme.dimensions.navbarHeight}px);
`;

const FormHelpP = styled.p`
  margin: 8px 0;
  font-style: italic;
`;

const FormFooterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const DeveloperToolsPage = memo(() => {
  const { isSuperAdmin } = useCurrentUserContext();
  const { openModal } = useModalsContext();

  const [m2mToken, setM2mToken] = useState<string>(
    sessionStorage.getItem(M2M_TOKEN_SESSION_KEY) || '',
  );

  const isModalClosable = !!m2mToken;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(isSuperAdmin ? false : !isModalClosable);

  const [fetcher, setFetcher] = useState<{ fn?: Fetcher }>({});
  const { getIdToken, isAuthenticated } = useAuthenticationContext();

  const createFetcher = useCallback(async () => {
    const authToken = await getIdToken?.();
    const tokenToUse = isSuperAdmin ? authToken?.jwtToken : m2mToken;

    if (tokenToUse) {
      const graphiQLFetcher = createGraphiQLFetcher({
        url: variables.mainApiUrl,
        headers: {
          authorization: tokenToUse,
        },
      });
      setFetcher({ fn: graphiQLFetcher });
    }
  }, [isSuperAdmin, m2mToken, getIdToken]);

  useEffect(() => {
    if (isAuthenticated) {
      createFetcher();
    }
  }, [createFetcher, isAuthenticated]);

  return (
    <>
      <WrapperDiv>
        {fetcher?.fn && (
          <GraphiQL fetcher={fetcher.fn}>
            <GraphiQL.Toolbar>
              <Tooltip title={i18n.t('integrations.apiDocumentation')} placement="bottomLeft">
                <Button
                  style={{ width: '100%', height: '40px', borderRadius: '8px' }}
                  size="small"
                  onClick={() => {
                    openModal({ type: 'apiDocumentation' });
                  }}
                >
                  <BookOutlined />
                </Button>
              </Tooltip>
            </GraphiQL.Toolbar>
            {m2mToken && (
              <GraphiQL.Footer>
                <div style={{ paddingTop: '8px' }}>
                  <Button
                    style={{ marginRight: '8px' }}
                    size="small"
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  >
                    {i18n.t('developerToolsPage.edit')}
                  </Button>
                  <b>{i18n.t('developerToolsPage.m2mToken')}:</b> {m2mToken}
                </div>
              </GraphiQL.Footer>
            )}
          </GraphiQL>
        )}
      </WrapperDiv>
      <Modal
        title={i18n.t('developerToolsPage.tokenFormLabel')}
        centered
        open={isModalOpen}
        footer={null}
        onCancel={() => {
          if (isModalClosable) {
            setIsModalOpen(false);
          }
        }}
        closable={isModalClosable}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            setIsModalOpen(false);
          }}
        >
          <Input
            placeholder={i18n.t<string>('developerToolsPage.tokenFormPlaceholder')}
            value={m2mToken}
            onChange={(event) => {
              setM2mToken(event.target.value);
              sessionStorage.setItem(M2M_TOKEN_SESSION_KEY, event.target.value);
            }}
          />
          <FormHelpP>{i18n.t('developerToolsPage.tokenFormHelp')}</FormHelpP>
          <FormFooterDiv>
            <Button htmlType="submit" type="primary" disabled={!m2mToken?.trim()}>
              {i18n.t('common.save')}
            </Button>
            {!isModalClosable && (
              <Link to={routes.companySettings({ tab: 'integrations' })}>
                <Button type="primary" danger>
                  {i18n.t('developerToolsPage.cancel')}
                </Button>
              </Link>
            )}
          </FormFooterDiv>
        </form>
      </Modal>
    </>
  );
});

DeveloperToolsPage.displayName = 'DeveloperToolsPage';

export default DeveloperToolsPage;
