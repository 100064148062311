/** @deprecated Do not use this function as it is not type safe */
export default function parseJSON(variable: unknown): any {
  try {
    return typeof variable === 'string' ? JSON.parse(variable) : variable;
  } catch (error) {
    // logger.log('utils/parse/parseJSON: error', { error });
    // TODO: should error be logged?
    return variable;
  }
}
