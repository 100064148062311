import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { Button, Empty } from 'antd';
import { memo, useEffect } from 'react';
import styled from 'styled-components';

import SettingsSelectCompany from '~/components/settings/components/SettingsSelectCompany';
import SettingsSelectSubsidiary from '~/components/settings/components/SettingsSelectSubsidiary';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import {
  SettingsTopFlexDiv,
  SettingsButtonsFlexDiv,
  SelectCompanySubsidiaryDiv,
} from '~/components/settings/shared';
import useBrainConfigurationsContext from '~/context/useBrainConfigurationsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import theme from '~/theme';

import BrainBaseConfigurationTable from './components/BrainBaseConfigurationTable';

const WrapperDiv = styled.div`
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

interface Props {
  isBackofficePage: boolean;
}

const TabBrainConfigurations = memo(({ isBackofficePage }: Props) => {
  const { isSubsidiaryAdmin } = useCurrentUserContext();
  const { currentCompany } = useCompany();
  const { currentSubsidiary } = useSubsidiary();

  const {
    brainConfigurations,
    isLoading: isBrainConfigurationsContextLoading,
    refetchBrainConfigurations,
  } = useBrainConfigurationsContext();

  useEffect(() => {
    (async () => {
      if (brainConfigurations.length > 0) {
        await refetchBrainConfigurations();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { openModal } = useModalsContext();

  const { isLoading: isLoadingCurrentUser } = useCurrentUserContext();

  const isLoading = isLoadingCurrentUser || isBrainConfigurationsContextLoading;

  const hasCompanyAndSubsidiary = !!currentCompany?.id && !!currentSubsidiary?.id;

  if (isSubsidiaryAdmin) {
    return null;
  }

  return (
    <WrapperDiv>
      <SelectCompanySubsidiaryDiv>
        {isBackofficePage && (
          <div>
            <TabSectionHeader title={i18n.t('companyFeatures.selectedCompany')} />
            <SettingsSelectCompany isLoading={isLoading} />
          </div>
        )}
        <div>
          <TabSectionHeader title={i18n.t('companyFeatures.selectedSubsidiary')} />
          <SettingsSelectSubsidiary isLoading={isLoading} />
        </div>
      </SelectCompanySubsidiaryDiv>
      {hasCompanyAndSubsidiary ? (
        <>
          <SettingsTopFlexDiv>
            <TabSectionHeader
              title={
                isLoading
                  ? i18n.t('common.loading')
                  : `${i18n.t('brainConfiguration.brainConfigurations')} (${brainConfigurations.length})`
              }
            />
            <SettingsButtonsFlexDiv>
              <Button
                loading={isLoading}
                icon={<ReloadOutlined />}
                onClick={async () => {
                  await refetchBrainConfigurations();
                }}
              >
                {i18n.t('common.refresh')}
              </Button>
              <Button
                type="primary"
                loading={isLoading}
                icon={<PlusOutlined />}
                onClick={async () => {
                  openModal({
                    type: 'brainConfigurationAddEdit',
                    isEdit: false,
                    itemScope: undefined,
                    itemId: undefined,
                    isBackofficePage,
                  });
                }}
              >
                {i18n.t('configurationsGeneric.createConfiguration')}
              </Button>
            </SettingsButtonsFlexDiv>
          </SettingsTopFlexDiv>
          <BrainBaseConfigurationTable
            deviceConfigurations={brainConfigurations}
            isLoading={isLoading}
            isBackofficePage={isBackofficePage}
          />
        </>
      ) : (
        <Empty
          style={{
            background: 'white',
            margin: '16px 0 0',
            padding: '3rem 16px',
            borderRadius: '10px',
          }}
          description={i18n.t('configurationsGeneric.pleaseSelectACompanyAndASubsidiary')}
        />
      )}
    </WrapperDiv>
  );
});

TabBrainConfigurations.displayName = 'TabBrainConfigurations';

export default TabBrainConfigurations;
