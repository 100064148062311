import type { Renderer } from '@googlemaps/markerclusterer';

import type { AGENT_STATUS, AgentAlertLevel } from '~/types/agent';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';

import getMarkerZIndex from './getMarkerZIndex';

export default function getMarkerClusterRender({
  status,
  alertLevel,
}: {
  status: AGENT_STATUS;
  alertLevel: AgentAlertLevel;
}) {
  return ({ count, position }: Renderer['render']['arguments']) => {
    const svg = window.btoa(`
        <svg fill="${getAgentStatusColor({
          status,
          alertLevel,
          isOffline: false,
        })}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
          <circle cx="120" cy="120" opacity="1" r="70" />
          <circle cx="120" cy="120" opacity=".5" r="90" />
          <circle cx="120" cy="120" opacity=".25" r="110" />
        </svg>
      `);

    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(64, 64),
      },
      label: { text: `${count}`, color: 'white', fontSize: '16px', fontWeight: 'bold' },
      zIndex: getMarkerZIndex(status, false, 'cluster') + count,
    });
  };
}
