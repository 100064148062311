import { Select } from 'antd';
import { useMemo, type ReactNode, memo } from 'react';

import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';

import NavbarSelect from './NavbarSelect';

const NavbarSelectSubsidiary = memo(() => {
  const { currentSubsidiary, subsidiaryList, setCurrentSubsidiaryId } = useSubsidiary();

  const options: ReactNode[] = useMemo(
    () =>
      subsidiaryList.map(({ id, name }, index: number) => (
        <Select.Option
          key={id}
          value={id}
          selected={index === 0}
          data-id={`subsidiary-select-option-${id}`}
        >
          {name}
        </Select.Option>
      )),
    [subsidiaryList],
  );

  const value: string = currentSubsidiary?.name || i18n.t('header.selectSubsidiary');

  return (
    <NavbarSelect value={value} onChange={setCurrentSubsidiaryId} data-id="subsidiary-select">
      {options}
    </NavbarSelect>
  );
});

NavbarSelectSubsidiary.displayName = 'NavbarSelectSubsidiary';

export default NavbarSelectSubsidiary;
