function computeSearchParams(params: Record<string, string | undefined> | undefined): string {
  if (!params) {
    return '';
  }
  const searchParamsQuery = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => value && searchParamsQuery.set(key, value));
  return `?${searchParamsQuery}`;
}

export type MyAccountPageTab = 'profile' | 'security';

type SharedTab = 'subsidiaries' | 'admins' | 'agents' | 'brain-configurations';

export type CompanySettingsTab =
  | SharedTab
  | 'company-details'
  | 'subsidiary-details'
  | 'emergency-contacts'
  | 'integrations';

export type BackofficeTab =
  | SharedTab
  | 'companies'
  | 'brains'
  | 'dashboard-features'
  | 'app-features'
  | 'super-admins'
  | 'alarm-configurations';

export type AgentOrVehicleType = 'agent' | 'vehicle';

const routes = {
  // Index Route
  index: '/',
  // Public Routes
  login: '/login',
  forgotPassword: '/forgot-password',
  verifyConfirmationCode: '/verify-confirmation-code',
  mfa: (params?: { mfaType?: string; base64Image?: string }) =>
    `/mfa${computeSearchParams(params)}`,
  newPasswordChallenge: (params?: { email: string }) =>
    `/new-password-challenge${computeSearchParams(params)}`,
  resetPassword: (params?: { email?: string; confirmationCode?: string }) =>
    `/reset-password${computeSearchParams(params)}`,
  // Private Routes
  map: (params?: { id?: string; vehicle?: string }) => `/map${computeSearchParams(params)}`,
  status: (params?: { id: string }) => `/status${computeSearchParams(params)}`,
  agents: '/agents',
  reports: '/reports',
  alarms: '/alarms',
  logs: '/logs',
  userManual: '/user-manual',
  customerSupport: '/customer-support',
  developerTools: '/developer-tools',
  myAccount: (params?: { tab: MyAccountPageTab }) => `/my-account${computeSearchParams(params)}`,
  companySettings: (params?: { tab: CompanySettingsTab }) =>
    `/company-settings${computeSearchParams(params)}`,
  backoffice: (params?: { tab: BackofficeTab }) => `/backoffice${computeSearchParams(params)}`,
  /** @deprecated Use routes.map instead */
  dashboard: '/map',
} satisfies Record<string, `/${string}` | (() => `/${string}`)>;

export default routes;
