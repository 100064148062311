import { memo, useMemo } from 'react';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import styled from 'styled-components';

import type { PopupSectionStatus } from '~/components/DetailsPopup/components/BrainBatteryLteSignalPopover/utils/getPopupSectionStatus';
import theme from '~/theme';

const Div = styled.div`
  position: relative;
  display: inline-flex;
  height: 17px;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const ValueSpan = styled.span`
  position: absolute;
  top: 50%;
  left: 46%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 10px;
  font-weight: 600;
`;

interface Props {
  level: number;
  isCharging: boolean;
  status: PopupSectionStatus;
}

const BatteryIcon = memo(({ level, isCharging, status }: Props) => {
  const { colorDark, colorLight } = useMemo(() => {
    if (status === 'connected') {
      return {
        colorDark: theme.colors.darkBlue,
        colorLight: '#A7BFF1',
      };
    }
    if (status === 'disconnected') {
      return {
        colorDark: 'rgba(0, 0, 0, 0.4)',
        colorLight: 'rgba(0, 0, 0, 0.1)',
      };
    }
    return {
      colorDark: theme.colors.red,
      colorLight: 'rgba(255, 0, 0, 0.1)',
    };
  }, [status]);

  return (
    <Div>
      <svg
        width="26"
        height="12"
        viewBox="0 0 26 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1.30864"
          y="0.646163"
          width="21.7607"
          height="10.7076"
          rx="2.24515"
          stroke={colorDark}
          strokeWidth="1.03622"
        />
        <path
          d="M24.6238 3.92719V8.07207C25.4577 7.72103 25.9999 6.90439 25.9999 5.99963C25.9999 5.09487 25.4577 4.27823 24.6238 3.92719Z"
          fill={colorDark}
        />
        <rect
          x="2.86328"
          y="2.20062"
          width="18.652"
          height="7.59896"
          rx="1.38163"
          fill={colorLight}
        />
      </svg>
      <ValueSpan style={{ color: colorDark }}>
        {isCharging ? (
          <BsFillLightningChargeFill style={{ transform: 'translateY(1px)' }} />
        ) : (
          level
        )}
      </ValueSpan>
    </Div>
  );
});

BatteryIcon.displayName = 'BatteryIcon';

export default BatteryIcon;
