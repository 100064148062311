import { gql } from '@apollo/client';

import { ALARM_QUERY_BODY } from '~/apollo/operations/shared';
import type { Alarm, ALARM_TYPE } from '~/types/alarm';

export interface SubsidiaryAlarmSubscriptionVariables {
  subsidiaryID: string;
  type: ALARM_TYPE;
}

export interface SubsidiaryAlarmSubscriptionData {
  alarm: {
    carrier_id: string;
    subsidiary_id: string;
    type: string;
    alarm: Alarm;
  };
}

export default gql`
  subscription SubscribeToSubsidiaryAlarmType($subsidiaryID: ID!, $type: String!) {
    alarm(subsidiary_id: $subsidiaryID, type: $type) {
      carrier_id
      subsidiary_id
      type
      alarm ${ALARM_QUERY_BODY}
    }
  }
`;
