import { useQuery, type ApolloError } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { useCallback, useMemo } from 'react';

import i18n from '~/locales/i18n';
import type { VideoRecording } from '~/types/videoRecording';
import logger from '~/utils/logger';
import notification from '~/utils/notification';

import QUERY_CARRIER_VIDEO_RECORDINGS, {
  type CarrierVideoRecordingsQueryData,
  type CarrierVideoRecordingsQueryVariables,
} from './queries/QueryCarrierVideoRecordings';

export default function useQueryCarrierVideoRecordings({
  agentId,
  startAfter,
  startBefore,
}: {
  agentId: string;
  startAfter: string | null;
  startBefore: string | null;
}): {
  videoRecordings: VideoRecording[];
  videoRecordingsNextToken: string | null;
  isLoading: boolean;
  error: ApolloError | undefined;
  refetchVideoRecordings: () => void;
  fetchNextPage: (nextToken: string | null) => void;
} {
  const { fetchMore, loading, error, data, refetch } = useQuery<
    CarrierVideoRecordingsQueryData,
    CarrierVideoRecordingsQueryVariables
  >(QUERY_CARRIER_VIDEO_RECORDINGS, {
    variables: {
      carrierId: agentId,
      startAfter,
      startBefore,
      limit: 25,
      nextToken: null,
    },
    fetchPolicy: 'network-only',
    skip: !agentId,
  });

  const fetchNextPage = useCallback(
    (nextToken: string | null) => {
      fetchMore({
        variables: {
          nextToken,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          ...previousResult,
          carrier: {
            ...previousResult.carrier,
            video_recordings: {
              ...previousResult.carrier?.video_recordings,
              // Merge duplicates and sort by date
              items: orderBy(
                Object.values(
                  [
                    ...(previousResult.carrier?.video_recordings?.items || []),
                    ...(fetchMoreResult.carrier?.video_recordings?.items || []),
                  ].reduce(
                    (acc, cur) => ({
                      ...acc,
                      [cur.id]: cur,
                    }),
                    {},
                  ),
                ),
                ['start_recording'],
                ['desc'],
              ),
              nextToken: fetchMoreResult.carrier?.video_recordings?.nextToken,
            },
          },
        }),
      }).catch((e) => {
        logger.error('useQueryWithSubscriptionCarrierVideoRecordings: error', e);
        notification.error({
          message: i18n.t('general.notifications.fetchDataErrorTitle'),
          description: i18n.t('general.notifications.fetchDataErrorDescription'),
        });
      });
    },
    [fetchMore],
  );

  return useMemo(
    () => ({
      videoRecordings: (data?.carrier?.video_recordings?.items || []).filter(
        (item) => !!item.duration_ms && !!item.view_url,
      ),
      videoRecordingsNextToken: data?.carrier?.video_recordings?.nextToken || null,
      isLoading: loading,
      error,
      refetchVideoRecordings: refetch,
      fetchNextPage,
    }),
    [
      data?.carrier?.video_recordings?.items,
      data?.carrier?.video_recordings?.nextToken,
      loading,
      error,
      refetch,
      fetchNextPage,
    ],
  );
}
