import i18n from '~/locales/i18n';

export default function getLteSignalStrengthTranslation(
  value: number | undefined,
): string | undefined {
  if (typeof value !== 'number' || !Number.isFinite(value)) {
    return undefined;
  }
  if (value > -81) {
    return i18n.t<string>('brainBatteryLteSignal.grade.excellent');
  }
  if (value > -110) {
    return i18n.t<string>('brainBatteryLteSignal.grade.good');
  }
  return i18n.t<string>('brainBatteryLteSignal.grade.poor');
}
