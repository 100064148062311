import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Button, Form, Input, Modal } from 'antd';
import { memo, useState } from 'react';
import styled from 'styled-components';

import PhoneNumberInput from '~/components/PhoneNumberInput';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompanyEmergencyContacts from '~/hooks/useCompanyEmergencyContacts';
import i18n from '~/locales/i18n';
import { EmergencyContact } from '~/types/emergencyContact';
import type { ModalProps } from '~/types/modal';
import notification from '~/utils/notification';
import isValidPhoneNumber from '~/utils/phoneNumber/isValidPhoneNumber';

const BottomActionsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface FormValues {
  name: string;
}

const EmergencyContactAddModal = memo(({ isOpen, onClose }: ModalProps) => {
  const { currentUser } = useCurrentUserContext();
  const { emergencyContactsList, setEmergencyContactsList, isLoading } =
    useCompanyEmergencyContacts();

  const [phoneNumberText, setPhoneNumberText] = useState<string>('');

  const [form] = Form.useForm<FormValues>();

  const handleSubmit = async ({ name }: FormValues) => {
    const contact: EmergencyContact = {
      name: name.trim(),
      phone: phoneNumberText,
      createdBy: currentUser?.email || '',
      createdAt: new Date().toISOString(),
    };
    if (!contact.name || !contact.phone || !isValidPhoneNumber(contact.phone)) {
      notification.error({
        message: i18n.t('common.error'),
        description: i18n.t('companySettingsPage.emergencyContactsTab.invalidPhoneNumber'),
      });
      return;
    }
    try {
      await setEmergencyContactsList([...emergencyContactsList, contact]);
      onClose();
      notification.success({
        message: i18n.t('common.success'),
        description: i18n.t('companySettingsPage.emergencyContactsTab.contactAddeddSucessfully'),
      });
    } catch (error) {
      notification.error({
        message: (error as any)?.message || i18n.t('common.error'),
      });
    }
  };

  return (
    <Modal
      title={i18n.t('companySettingsPage.emergencyContactsTab.addNewContact')}
      footer={null}
      centered
      width={500}
      open={isOpen}
      onCancel={onClose}
    >
      <Form
        form={form}
        layout="vertical"
        validateTrigger="onBlur"
        initialValues={undefined}
        autoComplete="off"
        onFinish={handleSubmit}
      >
        <Form.Item name="name" label={i18n.t('common.name')}>
          <Input required placeholder={i18n.t<string>('common.name')} disabled={isLoading} />
        </Form.Item>
        <Form.Item name="phoneNumber" label={i18n.t('common.phoneNumber')}>
          <PhoneNumberInput
            disabled={isLoading}
            value={phoneNumberText}
            onChange={(number) => {
              setPhoneNumberText(number || '');
            }}
          />
        </Form.Item>
        <BottomActionsDiv>
          <Button size="middle" disabled={isLoading} onClick={onClose}>
            {i18n.t('common.cancel')}
          </Button>
          <Button
            size="middle"
            type="primary"
            htmlType="submit"
            loading={isLoading}
            icon={<SaveOutlined />}
          >
            {i18n.t('common.save')}
          </Button>
        </BottomActionsDiv>
      </Form>
    </Modal>
  );
});

EmergencyContactAddModal.displayName = 'EmergencyContactAddModal';

export default EmergencyContactAddModal;
