import { useMutation, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import MUTATION_REQUEST_DISMISS_ALARM, {
  type RequestDismissAlarmMutationData,
  type RequestDismissAlarmMutationVariables,
} from './mutations/MutationRequestDismissAlarm';

export default function useMutationRequestDismissAlarm(): {
  requestDismissAlarm: (params: {
    variables: RequestDismissAlarmMutationVariables;
  }) => Promise<unknown>;
  dismissedAlarm?: RequestDismissAlarmMutationData | null;
  isLoading: boolean;
  error?: ApolloError;
} {
  const [requestDismissAlarm, { data, loading, error }] = useMutation<
    RequestDismissAlarmMutationData,
    RequestDismissAlarmMutationVariables
  >(MUTATION_REQUEST_DISMISS_ALARM);

  return useMemo(
    () => ({
      requestDismissAlarm,
      dismissedAlarm: data,
      isLoading: loading,
      error,
    }),
    [data, error, loading, requestDismissAlarm],
  );
}
