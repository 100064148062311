import type { CompanyFeatures } from '~/hooks/useCompanyFeatures';
import type { Agent } from '~/types/agent';
import type { EquipmentStatusDetails } from '~/types/equipment';

import getEquipmentStatusDysfunctionsColor from './getEquipmentStatusDysfunctionsColor';

function hasDysfunction(equipmentStatusDetails: EquipmentStatusDetails): boolean {
  return getEquipmentStatusDysfunctionsColor(equipmentStatusDetails.status).state === 'dysfunction';
}

export default function getDysfunctionalEquipment({
  agent,
  companyFeatures,
}: {
  agent: Agent;
  companyFeatures: CompanyFeatures;
}): EquipmentStatusDetails[] {
  return [
    ...(companyFeatures.heartRateSensor && hasDysfunction(agent.equipmentStatus.heartRate)
      ? [agent.equipmentStatus.heartRate]
      : []),
    ...(companyFeatures.physiologicalTemperatureSensor &&
    hasDysfunction(agent.equipmentStatus.physiologicalTemperature)
      ? [agent.equipmentStatus.physiologicalTemperature]
      : []),
    ...(companyFeatures.bodyTemperatureSensor &&
    hasDysfunction(agent.equipmentStatus.bodyTemperature)
      ? [agent.equipmentStatus.bodyTemperature]
      : []), // deprecated
    ...(companyFeatures.gasSensor && hasDysfunction(agent.equipmentStatus.gas)
      ? [agent.equipmentStatus.gas]
      : []),
    ...(companyFeatures.emergencyButton && hasDysfunction(agent.equipmentStatus.emergencyButton)
      ? [agent.equipmentStatus.emergencyButton]
      : []),
    ...(companyFeatures.impactDetectionFront && hasDysfunction(agent.equipmentStatus.traakFront)
      ? [agent.equipmentStatus.traakFront]
      : []),
    ...(companyFeatures.impactDetectionBack && hasDysfunction(agent.equipmentStatus.traakBack)
      ? [agent.equipmentStatus.traakBack]
      : []),
    ...(companyFeatures.oxygenSupplySensor && hasDysfunction(agent.equipmentStatus.oxygenSupply)
      ? [agent.equipmentStatus.oxygenSupply]
      : []),
    ...(companyFeatures.radiationSensor && hasDysfunction(agent.equipmentStatus.radiation)
      ? [agent.equipmentStatus.radiation]
      : []),
  ];
}
