import { gql } from '@apollo/client';

import type { VideoRecording } from '~/types/videoRecording';

export interface CarrierVideoRecordingsQueryVariables {
  carrierId: string;
  startAfter: string | null;
  startBefore: string | null;
  limit: number;
  nextToken: string | null;
}

export interface CarrierVideoRecordingsQueryData {
  carrier: {
    __typename: 'Carrier_Cognito';
    id: string;
    video_recordings: {
      __typename: 'VideoRecordingConnection';
      nextToken: string | null;
      items: VideoRecording[];
    };
  };
}

export default gql`
  query QueryCarrierVideoRecordings(
    $carrierId: ID!
    $startAfter: AWSDateTime
    $startBefore: AWSDateTime
    $limit: Int
    $nextToken: String
  ) {
    carrier(id: $carrierId) {
      id
      video_recordings(
        startAfter: $startAfter
        startBefore: $startBefore
        limit: $limit
        nextToken: $nextToken
      ) {
        nextToken
        items {
          duration_ms
          id
          start_recording
          view_url
        }
      }
    }
  }
`;
