export enum AWS_AUTHENTICATION_RESPONSE_TYPE {
  // Successful response types
  ON_SUCCESS = 'ON_SUCCESS',
  MFA_SETUP = 'MFA_SETUP',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
  COMPLETE_NEW_PASSWORD_CHALLENGE_EXCEPTION = 'COMPLETE_NEW_PASSWORD_CHALLENGE_EXCEPTION',

  // Failure response types
  NotAuthorizedException = 'NotAuthorizedException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  UserNotFoundException = 'UserNotFoundException',
}

// export type APOLLO_AUTH_TYPE = Pick<AUTH_TYPE, 'AMAZON_COGNITO_USER_POOLS' | 'OPENID_CONNECT'>;

export declare enum APOLLO_AUTH_TYPE {
  // NONE = 'NONE',
  // API_KEY = 'API_KEY',
  // AWS_IAM = 'AWS_IAM',
  // AWS_LAMBDA = 'AWS_LAMBDA',
  AMAZON_COGNITO_USER_POOLS = 'AMAZON_COGNITO_USER_POOLS',
  OPENID_CONNECT = 'OPENID_CONNECT',
}

export interface ForgotPasswordParams {
  email: string;
}

export interface CompleteNewPasswordChallengeParams {
  newPassword: string;
}

export interface PasswordResetParams extends CompleteNewPasswordChallengeParams {
  confirmationCode: string;
}

export type VerifyCodeParams = Omit<PasswordResetParams, 'newPassword'>;

export type VerifyMFASetupParams = Omit<CompleteNewPasswordChallengeParams, 'newPassword'> & {
  code: string;
};

export type VerifyMFAParams = Omit<
  CompleteNewPasswordChallengeParams & PasswordResetParams,
  'newPassword'
> & {
  confirmationCode: string;
  mfaType?: AWS_AUTHENTICATION_RESPONSE_TYPE;
};

export interface AWSPasswordResetUserAttributes {
  email: string;
}

export interface AWSSetupMfaUserAttributes {
  base64Image: string;
}

export interface AWSLoginResponseOnConditionalSuccessData {
  requiredAttributes?: unknown;
  userAttributes: AWSPasswordResetUserAttributes | AWSSetupMfaUserAttributes;
}

export interface AWSLoginResponseOnConditionalSuccess {
  type: AWS_AUTHENTICATION_RESPONSE_TYPE;
  data: AWSLoginResponseOnConditionalSuccessData;
}

export interface AWSLoginOnSuccess {
  type: AWS_AUTHENTICATION_RESPONSE_TYPE.ON_SUCCESS;
}

export type AWSLoginResponse = AWSLoginOnSuccess | AWSLoginResponseOnConditionalSuccess;
