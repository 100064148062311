import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import FormOutlined from '@ant-design/icons/FormOutlined';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Button, Input, Space, Tooltip } from 'antd';
import Form from 'antd/lib/form';
import Result from 'antd/lib/result';
import Table from 'antd/lib/table/Table';
import orderBy from 'lodash/orderBy';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import QUERY_TOKENS from '~/apollo/hooks/m2mToken/queries/QueryCompanyM2MTokens';
import useMutationCreateM2MToken from '~/apollo/hooks/m2mToken/useMutationCreateM2MToken';
import useMutationDeleteM2MToken from '~/apollo/hooks/m2mToken/useMutationDeleteM2MToken';
import useQueryCompanyM2MTokens from '~/apollo/hooks/m2mToken/useQueryCompanyM2MTokens';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import { maxSectionWidth } from '~/components/settings/config/settingsTabsConfig';
import TimeAgo from '~/components/TimeAgo';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompany from '~/hooks/useCompany';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { M2MToken } from '~/types/m2mToken';
import { formatDateTime } from '~/utils/dateTime';
import notification from '~/utils/notification';

const StyledResult = styled(Result)`
  background: ${theme.colors.white};
  padding: 4rem 32px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  .ant-result-icon {
    margin-bottom: 0;

    .anticon {
      font-size: 48px;
    }
  }

  .ant-result-extra {
    margin-top: 16px;
  }
`;

const TokenValueDiv = styled.div`
  word-break: break-word;
  border: solid 2px ${theme.colors.green};
  border-radius: 16px;
  padding: 8px 16px;
`;

const TableDiv = styled.div`
  overflow-x: auto;
  background: ${theme.colors.white};
`;

interface FormFields {
  comment: string;
}

const SectionIntegrationTokens = memo(() => {
  const { isCompanyAdmin, isSuperAdmin } = useCurrentUserContext();
  const { currentCompany } = useCompany();
  const companyId = currentCompany?.id || '';
  const { tokens, loading } = useQueryCompanyM2MTokens(companyId, !companyId);
  const { createM2MToken, result, loading: isCreateLoading } = useMutationCreateM2MToken();
  const { deleteM2MToken, loading: isDeleteLoading } = useMutationDeleteM2MToken();
  const [form] = Form.useForm<FormFields>();

  const [shownToken, setShownToken] = useState<string | null>(null);

  const { openModal } = useModalsContext();

  useEffect(() => {
    if (result?.token) {
      setShownToken(result.token);
    }
  }, [result?.token]);

  const handleCopy = useCallback(() => {
    if (shownToken) {
      navigator.clipboard.writeText(shownToken);
      notification.success({
        message: i18n.t('general.clipboard.copySuccess'),
      });
    }
  }, [shownToken]);

  const handleSubmit = useCallback(
    (values: FormFields) => {
      createM2MToken({
        variables: { companyId, comment: values.comment },
        refetchQueries: [
          { query: QUERY_TOKENS, variables: { companyId } },
          'QueryCompanyM2MTokens',
        ],
      });
      form.resetFields();
    },
    [companyId, form, createM2MToken],
  );

  const handleDelete = useCallback(
    (token: M2MToken) => {
      openModal({
        type: 'confirmation',
        title: null,
        description: (
          <>
            <div style={{ marginBottom: '8px' }}>
              {i18n.t('integrations.deleteModalDescription')}
            </div>
            <b>{i18n.t('integrations.comment')}:</b> {token.comment}
            <br />
            <b>{i18n.t('common.createdAt')}:</b> <TimeAgo date={token.created_at} /> (
            {formatDateTime(token.created_at, 'standard')})
            <br />
            <b>{i18n.t('integrations.tokenId')}:</b> {token.id}
            <br />
            <b>{i18n.t('integrations.company')}:</b> {currentCompany?.name}
          </>
        ),
        actionType: 'danger',
        action: async () => {
          setShownToken(null);
          await deleteM2MToken({
            variables: { tokenId: token.id },
            refetchQueries: [
              { query: QUERY_TOKENS, variables: { companyId } },
              'QueryCompanyM2MTokens',
            ],
          });
          notification.success({
            message: i18n.t('common.success'),
            description: i18n.t('integrations.tokenDeletedSuccessfully'),
          });
        },
      });
    },
    [companyId, currentCompany?.name, deleteM2MToken, openModal],
  );

  const tableData = useMemo(
    () =>
      orderBy(tokens || [], ['created_at'], ['desc']).map((item) => ({
        ...item,
        key: item.id,
        createdAt: (
          <Tooltip title={formatDateTime(item.created_at, 'standard')}>
            <span style={{ cursor: 'help' }}>
              <TimeAgo date={item.created_at} />
            </span>
          </Tooltip>
        ),
        actions: (
          <Tooltip title={i18n.t('integrations.deleteToken')}>
            <Button
              danger
              onClick={() => handleDelete(item)}
              data-id="delete-btn"
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        ),
      })),
    [tokens, handleDelete],
  );

  const tableColumns = useMemo(
    () => [
      { dataIndex: 'id', title: i18n.t('integrations.tokenId') },
      { dataIndex: 'comment', title: i18n.t('integrations.comment') },
      { dataIndex: 'createdAt', title: i18n.t('common.created') },
      { dataIndex: 'actions', title: i18n.t('common.actions') },
    ],
    [],
  );

  const isLoading = loading || isDeleteLoading;

  if (!isCompanyAdmin && !isSuperAdmin) {
    return null;
  }

  return (
    <div>
      <TabSectionHeader title={i18n.t('integrations.createToken')} />
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        style={{ maxWidth: maxSectionWidth }}
      >
        <Form.Item name="comment" label={i18n.t('integrations.newTokenLabel')}>
          <Input
            required
            placeholder={i18n.t<string>('integrations.newTokenPlaceholder')}
            addonBefore={<FormOutlined style={{ color: theme.colors.black }} />}
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              loading={isCreateLoading}
              icon={<SaveOutlined />}
            >
              {i18n.t('integrations.create')}
            </Button>
          )}
        </Form.Item>
      </Form>
      <TabSectionHeader
        title={
          loading
            ? i18n.t('common.loading')
            : `${i18n.t('integrations.listOfTokens')} (${tokens.length})`
        }
      />
      {shownToken && (
        <StyledResult
          status="success"
          title={i18n.t('integrations.createSuccessTitle')}
          subTitle={i18n.t('integrations.createSuccessDescription')}
          extra={
            <Space direction="vertical" size="small">
              <TokenValueDiv>{shownToken}</TokenValueDiv>
              <Button size="large" type="default" onClick={handleCopy}>
                {i18n.t('integrations.copy')}
              </Button>
            </Space>
          }
        />
      )}
      <TableDiv>
        <Table
          columns={tableColumns}
          dataSource={tableData}
          sortDirections={['ascend', 'descend', 'ascend']}
          pagination={false}
          data-id="m2m-tokens-table"
          loading={isLoading}
        />
      </TableDiv>
    </div>
  );
});

SectionIntegrationTokens.displayName = 'SectionIntegrationTokens';

export default SectionIntegrationTokens;
