import { useQuery, useReactiveVar } from '@apollo/client';
import merge from 'lodash/merge';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { useMemo, useCallback, useEffect, useRef, useState } from 'react';

import SUBSCRIBE_TO_SUBSIDIARY_ALARM, {
  type SubsidiaryAlarmSubscriptionData,
  type SubsidiaryAlarmSubscriptionVariables,
} from '~/apollo/operations/subscriptions/SubscribeToSubsidiaryAlarm';
import { newAlertVar } from '~/apollo/reactiveVariables/newAlertVar';
import { refetchDataOnReconnectVar } from '~/apollo/reactiveVariables/refetchDataOnReconnectVar';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import useCompanyFeatures, { type CompanyFeatures } from '~/hooks/useCompanyFeatures';
import { ALARM_LEVEL, ALARM_TYPE, type AlarmWithCarrier } from '~/types/alarm';
import getAgentCompleteNameFromCarrier from '~/utils/agent/getAgentCompleteNameFromCarrier';
import isAlarmOngoing from '~/utils/alarm/isAlarmOngoing';
import logger from '~/utils/logger';
import { playNotificationSound } from '~/utils/sounds';

import QUERY_SUBSIDIARY_ALARMS, {
  type SubsidiaryAlarmsQueryData,
  type SubsidiaryAlarmsQueryVariables,
} from './queries/QuerySubsidiaryAlarms';

const DEFAULT_CARRIER_ITEM: SubsidiaryAlarmsQueryData['subsidiary']['carriers']['items'][number] = {
  __typename: 'Carrier_Cognito',
  id: '',
  name: '',
  attributes: [],
  device: {
    __typename: 'Device',
    name: '',
    alarms: {
      __typename: 'AlarmConnection',
      items: [],
    },
  },
};

export default function useQueryWithSubscriptionSubsidiaryAlarms({
  subsidiaryId,
}: {
  subsidiaryId: string | undefined;
}): {
  alarms: AlarmWithCarrier[];
  isLoading: boolean;
  isInitialLoading: boolean;
  refetchAlarms: () => void;
} {
  const { isAuthenticated } = useAuthenticationContext();
  const { companyFeatures } = useCompanyFeatures();

  const skip = !subsidiaryId;

  const {
    loading: isFastQueryLoading,
    data: fastQueryData,
    client: fastQueryClient,
    subscribeToMore,
  } = useQuery<SubsidiaryAlarmsQueryData, SubsidiaryAlarmsQueryVariables>(QUERY_SUBSIDIARY_ALARMS, {
    variables: {
      subsidiaryID: subsidiaryId || 'NO_SUBSIDIARY_ID',
      limit: 1,
    },
    skip,
    fetchPolicy: 'no-cache',
    onError: (e) => {
      logger.error('useQueryWithSubscriptionSubsidiaryAlarms: fast query error', {
        error: e,
        subsidiaryId,
      });
    },
  });

  const {
    loading: isSlowQueryLoading,
    data: slowQueryData,
    client: slowQueryClient,
    refetch: refetchSlowQuery,
  } = useQuery<SubsidiaryAlarmsQueryData, SubsidiaryAlarmsQueryVariables>(QUERY_SUBSIDIARY_ALARMS, {
    variables: {
      subsidiaryID: subsidiaryId || 'NO_SUBSIDIARY_ID',
      limit: 50,
    },
    skip,
    fetchPolicy: 'no-cache',
    onError: (e) => {
      logger.error('useQueryWithSubscriptionSubsidiaryAlarms: slow query error', {
        error: e,
        subsidiaryId,
      });
    },
  });

  const refetchDataOnReconnectValue = useReactiveVar(refetchDataOnReconnectVar);
  useEffect(() => {
    if (refetchDataOnReconnectValue) {
      (async () => {
        logger.log(
          'useQueryWithSubscriptionSubsidiaryAlarms: 🔁 refetching data on reconnect (alarms)',
        );
        await refetchSlowQuery();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchDataOnReconnectValue]);

  const [alarmsFromSubscriptions, setAlarmsFromSubscriptions] = useState<AlarmWithCarrier[]>([]);

  useEffect(() => {
    if (!isAuthenticated) {
      setAlarmsFromSubscriptions([]);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (subsidiaryId && !fastQueryData) {
      setAlarmsFromSubscriptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subsidiaryId]);

  const subscribeToMoreForAlarmType = useCallback(
    (subsidiaryID: string, alarmType: ALARM_TYPE) =>
      subscribeToMore<SubsidiaryAlarmSubscriptionData, SubsidiaryAlarmSubscriptionVariables>({
        document: SUBSCRIBE_TO_SUBSIDIARY_ALARM,
        variables: { subsidiaryID, type: alarmType },
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          const carrierId: string = subscriptionData.data.alarm?.carrier_id;
          const carrier: AlarmWithCarrier['carrier'] = {
            id: carrierId,
            completeName: carrierId,
          };
          const alarm: AlarmWithCarrier = {
            ...subscriptionData.data.alarm?.alarm,
            carrier,
          };
          if (isAlarmOngoing(alarm)) {
            logger.log('useQueryWithSubscriptionSubsidiaryAlarms: new ongoing alarm', {
              subsidiaryId: subsidiaryID,
              carrierId,
              alarm,
            });
            switch (alarm.level) {
              case ALARM_LEVEL.Critical:
                newAlertVar({ carrierId });
                break;
              case ALARM_LEVEL.Warning:
                playNotificationSound();
                break;
              default:
                break;
            }
          }
          setAlarmsFromSubscriptions((prevAlarms) => uniqBy([alarm, ...prevAlarms], 'id'));
          const result: SubsidiaryAlarmsQueryData = {
            ...previousResult,
            subsidiary: {
              ...previousResult.subsidiary,
              id: previousResult.subsidiary?.id || subsidiaryID,
              carriers: {
                ...previousResult.subsidiary?.carriers,
                nextToken: previousResult.subsidiary?.carriers?.nextToken || null,
                items: (previousResult?.subsidiary?.carriers?.items || [])?.map((item) =>
                  merge({}, DEFAULT_CARRIER_ITEM, item),
                ),
              },
            },
          };
          return result;
        },
      }),
    [subscribeToMore],
  );

  const subscriptionsRef = useRef<(() => void)[]>([]);
  const subscribedFeaturesRef = useRef<Set<keyof CompanyFeatures>>(new Set());

  useEffect(() => {
    if (subsidiaryId) {
      logger.log('useQueryWithSubscriptionSubsidiaryAlarms: subscribing', {
        subsidiaryId,
        date: new Date().toISOString(),
        subscriptionsLength: subscriptionsRef.current?.length,
      });
      subscriptionsRef.current = [
        subscribeToMoreForAlarmType(subsidiaryId, ALARM_TYPE.fall),
        subscribeToMoreForAlarmType(subsidiaryId, ALARM_TYPE.attack),
        subscribeToMoreForAlarmType(subsidiaryId, ALARM_TYPE.emergency),
        subscribeToMoreForAlarmType(subsidiaryId, ALARM_TYPE.stress),
        subscribeToMoreForAlarmType(subsidiaryId, ALARM_TYPE.abnormal_stops),
        subscribeToMoreForAlarmType(subsidiaryId, ALARM_TYPE.long_stops),
      ];
    }

    return () => {
      logger.log('useQueryWithSubscriptionSubsidiaryAlarms: unsubscribing', {
        subsidiaryId,
        date: new Date().toISOString(),
        subscriptionsLength: subscriptionsRef.current?.length,
      });
      subscriptionsRef.current.forEach((fn) => fn());
      subscriptionsRef.current = [];
      subscribedFeaturesRef.current = new Set();
    };
  }, [subsidiaryId, fastQueryClient, slowQueryClient, subscribeToMoreForAlarmType]);

  // needs to be separate from non-feature subscriptions in order to not cause
  // fast successive subscribing and unsubscribing (because features get loaded
  // at different time than other dependencies) which produces appsync error
  // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/509

  useEffect(() => {
    if (subsidiaryId) {
      logger.log('useQueryWithSubscriptionSubsidiaryAlarms: subscribing to company features', {
        subsidiaryId,
        date: new Date().toISOString(),
        subscriptionsLength: subscriptionsRef.current?.length,
        subscribedFeaturesSize: subscribedFeaturesRef.current?.size,
      });
      if (
        companyFeatures.impactDetectionFront &&
        !subscribedFeaturesRef.current.has('impactDetectionFront')
      ) {
        subscribedFeaturesRef.current.add('impactDetectionFront');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForAlarmType(subsidiaryId, ALARM_TYPE.traak_front),
        ];
      }
      if (
        companyFeatures.impactDetectionBack &&
        !subscribedFeaturesRef.current.has('impactDetectionBack')
      ) {
        subscribedFeaturesRef.current.add('impactDetectionBack');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForAlarmType(subsidiaryId, ALARM_TYPE.traak_back),
        ];
      }
      if (companyFeatures.gasSensor && !subscribedFeaturesRef.current.has('gasSensor')) {
        subscribedFeaturesRef.current.add('gasSensor');
        subscriptionsRef.current = [
          ...subscriptionsRef.current,
          subscribeToMoreForAlarmType(subsidiaryId, ALARM_TYPE.gas_danger),
          subscribeToMoreForAlarmType(subsidiaryId, ALARM_TYPE.gas_high),
        ];
      }
    }
  }, [
    subsidiaryId,
    companyFeatures.impactDetectionFront,
    companyFeatures.impactDetectionBack,
    companyFeatures.gasSensor,
    subscribeToMoreForAlarmType,
  ]);

  const refetchAlarms = useCallback(() => {
    if (!skip) {
      refetchSlowQuery();
    }
  }, [refetchSlowQuery, skip]);

  const alarmsFromFetch = useMemo(() => {
    const alarmsArray: AlarmWithCarrier[] = [];
    [
      ...(fastQueryData?.subsidiary?.carriers?.items || []),
      ...(slowQueryData?.subsidiary?.carriers?.items || []),
    ].forEach((carrier) => {
      (carrier?.device?.alarms?.items || []).forEach((alarm) => {
        alarmsArray.push({
          ...alarm,
          carrier: {
            id: carrier.id,
            completeName: getAgentCompleteNameFromCarrier(carrier),
          },
        });
      });
    });
    return uniqBy(alarmsArray, 'id');
  }, [fastQueryData?.subsidiary?.carriers?.items, slowQueryData?.subsidiary?.carriers?.items]);

  const alarms = useMemo(() => {
    const unsortedAlarms = uniqBy([...alarmsFromSubscriptions, ...alarmsFromFetch], 'id');
    return orderBy(unsortedAlarms, ['created_at'], ['desc']).map((alarm) => ({
      ...alarm,
      carrier: {
        ...alarm.carrier,
        completeName:
          window.agentsCompleteNameMap?.[alarm.carrier.id] || alarm.carrier.completeName,
      },
    }));
  }, [alarmsFromFetch, alarmsFromSubscriptions]);

  return useMemo(
    () => ({
      alarms,
      isLoading: isFastQueryLoading || isSlowQueryLoading,
      isInitialLoading: isFastQueryLoading && !fastQueryData,
      refetchAlarms,
    }),
    [alarms, fastQueryData, isFastQueryLoading, isSlowQueryLoading, refetchAlarms],
  );
}
