import { gql } from '@apollo/client';

import { ALARM_ITEMS_QUERY_BODY } from '~/apollo/operations/shared';
import type { Alarm } from '~/types/alarm';
import type { Attribute } from '~/types/attribute';

export interface SubsidiaryAlarmsQueryVariables {
  subsidiaryID: string;
  limit: number;
}

export interface SubsidiaryAlarmsQueryData {
  subsidiary: {
    __typename?: 'Subsidiary';
    id: string;
    carriers: {
      nextToken?: string | null;
      items: {
        __typename: 'Carrier_Cognito' | string;
        id: string;
        name: string;
        attributes: Attribute[];
        device: {
          __typename?: 'Device';
          name: string;
          alarms: {
            __typename?: 'AlarmConnection';
            items: Alarm[];
          };
        };
      }[];
    };
  };
}

export default gql`
  query QuerySubsidiaryAlarms($subsidiaryID: ID!, $limit: Int) {
    subsidiary(id: $subsidiaryID) {
      id
      carriers(limit: 1000) {
        nextToken
        items {
          id
          name
          attributes {
            name
            value
          }
          device {
            name
            alarms(limit: $limit) ${ALARM_ITEMS_QUERY_BODY}
          }
        }
      }
    }
  }
`;
