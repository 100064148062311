import { gql } from '@apollo/client';

export interface DeleteCompanyM2MTokenMutationVariables {
  tokenId: string;
}

export interface DeleteCompanyM2MTokenMutationData {
  deleteM2MToken: string;
}

export default gql`
  mutation MutationDeleteCompanyM2MToken($tokenId: ID!) {
    deleteM2MToken(token_id: $tokenId)
  }
`;
