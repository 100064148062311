import type { ALARM_LEVEL, ALARM_TYPE } from '~/types/alarm';
import type { ConfigurationOverrideScope } from '~/types/configuration';

export enum ALARM_CONFIGURATION_AUTHORITY {
  Backend = 'Backend',
  Device = 'Device',
}

export interface AlarmConfiguration {
  __typename?: 'AlarmConfiguration';
  type?: 'alarm';
  name?: ALARM_TYPE | string;
  last_updated?: string;
  activated: boolean | null;
  level: ALARM_LEVEL | null;
  authority: ALARM_CONFIGURATION_AUTHORITY | null;
  notify_sms: boolean | null;
  notify_voice_call: boolean | null;
  activate_video: boolean | null | null;
  vest_feedback: boolean | null;
  can_assign: boolean | null;
  can_view_other_carriers: boolean | null;
  can_view_teamleader: boolean | null;
  can_dismiss_dashboard: boolean | null;
  can_dismiss_teamleader: boolean | null;
  can_dismiss_carrier: boolean | null;
  can_cancel: boolean | null;
  trigger_parameters: string | null; // JSON
}

export interface AlarmConfigurationOverride extends AlarmConfiguration {
  scope: ConfigurationOverrideScope;
}
