import { gql } from '@apollo/client';

import type { LocationHistoryPoint } from '~/types/locationHistory';

export interface CarrierLocationHistoryQueryVariables {
  carrierId: string;
  nextToken: string | null;
}

export interface CarrierLocationHistoryQueryData {
  carrier: {
    __typename: 'Carrier_Cognito';
    id: string;
    device: {
      __typename: 'Device';
      name: string;
      location_history: {
        __typename: 'LocationHistoryConnection';
        nextToken: string | null;
        items: LocationHistoryPoint[];
      };
    };
  };
}

export default gql`
  query QueryCarrierLocationHistory($carrierId: ID!, $nextToken: String) {
    carrier(id: $carrierId) {
      id
      device {
        name
        location_history(limit: 1000, nextToken: $nextToken) {
          items {
            timestamp
            lat
            lng
          }
          nextToken
        }
      }
    }
  }
`;
