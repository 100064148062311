import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { Button, Modal, Select, Tooltip } from 'antd';
import { memo, useMemo, useState } from 'react';
import styled from 'styled-components';

import { UnderlineLink } from '~/components/Link';
import routes from '~/config/routes';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompanyEmergencyContacts from '~/hooks/useCompanyEmergencyContacts';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import OnlyCompanyAdminsAlert from '~/pages/CompanySettingsPage/components/OnlyCompanyAdminsAlert';
import theme from '~/theme';
import type { ModalProps } from '~/types/modal';
import formatPhoneNumber from '~/utils/phoneNumber/formatPhoneNumber';

const TopFlexDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, auto);
  align-items: center;
  grid-gap: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const StyledAgentSelect = styled(Select)`
  display: block;
  width: 100%;
`;

const H3 = styled.h3`
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  margin-bottom: 0.75rem;
`;

const EmergencyContactsUl = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`;

const EmergencyContactButton = styled(Button)`
  display: flex;
  align-items: center;
  text-align: left;
`;

const EmptyP = styled.p`
  color: rgba(0, 0, 0, 0.45);
  margin: 0;
  padding: 0;
  font-style: italic;
`;

const BottomP = styled.p`
  margin-top: 16px;
  margin-bottom: 0;
  color: ${theme.colors.primaryBlue};
`;

const EmergencyContactsOnCallModal = memo(({ isOpen, onClose }: ModalProps) => {
  const [phoneNumberText, setPhoneNumberText] = useState<string>('');

  const { isLoading: isCurrentUserLoading, isSuperAdmin, isCompanyAdmin } = useCurrentUserContext();
  const { companyFeatures } = useCompanyFeatures();
  const {
    onCallPhoneNumbers,
    setOnCallPhoneNumbers,
    emergencyContactsList,
    isLoading: isCompanyEmergencyContactsLoading,
  } = useCompanyEmergencyContacts();

  const contactListOptions = useMemo(
    () =>
      emergencyContactsList
        .map((contact) => ({
          value: contact.phone,
          label: `${contact.name} (${formatPhoneNumber(contact.phone)})`,
          disabled: onCallPhoneNumbers.includes(contact.phone),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [emergencyContactsList, onCallPhoneNumbers],
  );

  const isLoading = isCurrentUserLoading || isCompanyEmergencyContactsLoading;

  const isDisabled = !isSuperAdmin && !isCompanyAdmin;

  if (!companyFeatures.contactsOnCall) {
    return null;
  }

  return (
    <Modal
      title={i18n.t('contactsOnCallModal.title')}
      footer={null}
      centered
      width={640}
      open={isOpen}
      onCancel={onClose}
    >
      <OnlyCompanyAdminsAlert />
      <TopFlexDiv>
        <StyledAgentSelect
          disabled={isDisabled}
          loading={isLoading}
          showSearch
          placeholder={i18n.t('contactsOnCallModal.searchContacts')}
          optionFilterProp="children"
          value={phoneNumberText || undefined}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={contactListOptions}
          onChange={async (value) => {
            if (value) {
              setPhoneNumberText(value as string);
            }
          }}
        />
        <Button
          disabled={!phoneNumberText || isDisabled}
          loading={isLoading}
          type="primary"
          onClick={async () => {
            if (phoneNumberText) {
              await setOnCallPhoneNumbers([...onCallPhoneNumbers, phoneNumberText]);
              setPhoneNumberText('');
            }
          }}
        >
          {i18n.t('contactsOnCallModal.addContact')}
        </Button>
      </TopFlexDiv>
      <H3>
        {i18n.t('contactsOnCallModal.subtitle')} ({onCallPhoneNumbers.length})
      </H3>
      {onCallPhoneNumbers.length > 0 ? (
        <EmergencyContactsUl>
          {onCallPhoneNumbers.map((phoneNumber) => {
            const maybeAgent = emergencyContactsList.find(
              (contact) => contact.phone === phoneNumber,
            );
            const maybeAgentName = maybeAgent?.name;

            return (
              <li key={phoneNumber}>
                <Tooltip
                  title={isLoading || isDisabled ? undefined : i18n.t('contactsOnCallModal.remove')}
                  placement="right"
                >
                  <EmergencyContactButton
                    disabled={isLoading || isDisabled}
                    onClick={async () => {
                      await setOnCallPhoneNumbers(
                        onCallPhoneNumbers.filter((c) => c !== phoneNumber),
                      );
                    }}
                  >
                    {formatPhoneNumber(phoneNumber)}
                    {maybeAgentName ? ` (${maybeAgentName})` : ''} <CloseOutlined />
                  </EmergencyContactButton>
                </Tooltip>
              </li>
            );
          })}
        </EmergencyContactsUl>
      ) : (
        <EmptyP>{i18n.t('contactsOnCallModal.empty')}</EmptyP>
      )}
      <BottomP>
        <UnderlineLink
          to={routes.companySettings({ tab: 'emergency-contacts' })}
          onClick={() => {
            onClose();
          }}
        >
          {i18n.t('contactsOnCallModal.manageContactList')}
        </UnderlineLink>
      </BottomP>
    </Modal>
  );
});

EmergencyContactsOnCallModal.displayName = 'EmergencyContactsOnCallModal';

export default EmergencyContactsOnCallModal;
