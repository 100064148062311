type LoggerArgs = [title: `${string}: ${string}`, data?: Record<string, unknown>];

const logger = {
  log: (...args: LoggerArgs) => {
    console.log(...args); // eslint-disable-line no-console
  },
  error: (...args: LoggerArgs) => {
    console.error(...args); // eslint-disable-line no-console
  },
};

export default logger;
