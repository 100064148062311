import { EQUIPMENT_STATUS, type EquipmentStatusDetails } from '~/types/equipment';
import getEquipmentStatusDysfunctionsColor from '~/utils/equipment/getEquipmentStatusDysfunctionsColor';

export default function getEquipmentStatusDetails({
  status,
  isOffline,
}: {
  status: EQUIPMENT_STATUS | undefined;
  isOffline: boolean;
}): EquipmentStatusDetails {
  const actualStatus = isOffline
    ? EQUIPMENT_STATUS.no_status
    : status || EQUIPMENT_STATUS.no_sensor;

  return {
    status: actualStatus,
    hasDysfunction: getEquipmentStatusDysfunctionsColor(actualStatus).state !== 'dysfunction',
  };
}
