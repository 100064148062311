import PauseCircleOutlined from '@ant-design/icons/PauseCircleOutlined';
import PlayCircleOutlined from '@ant-design/icons/PlayCircleOutlined';
import { Button, Tooltip } from 'antd';
import { memo, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import useMutationRequestVideoStreamStatus from '~/apollo/hooks/videoStream/useMutationRequestVideoStreamStatus';
import useQueryWithSubscriptionCarrierVideoStream from '~/apollo/hooks/videoStream/useQueryWithSubscriptionCarrierVideoStream';
import useAlarmsContext from '~/context/useAlarmsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import { STREAM_REQUESTED_STATUS, STREAM_STATE } from '~/types/videoStream';
import logger from '~/utils/logger';
import notification from '~/utils/notification';
import getYesOrNo from '~/utils/parse/getYesOrNo';

import VideoStreamPlayerElement from './VideoStreamPlayerElement';

const BottomDiv = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, 1fr);
  align-items: center;
  grid-gap: 8px;
`;

const ActionsDiv = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  grid-gap: 8px;
  align-items: center;
`;

interface Props {
  agent: Agent | undefined;
}

const VideoStreamPlayer = memo(({ agent }: Props) => {
  const { isSuperAdmin } = useCurrentUserContext();
  const { companyFeatures } = useCompanyFeatures();
  const { ongoingAlarms } = useAlarmsContext();

  const {
    videoStream,
    error: videoStreamError,
    isLoading: isVideoStreamLoading,
  } = useQueryWithSubscriptionCarrierVideoStream({
    agent,
  });

  const {
    requestVideoStreamStatus,
    error: requestError,
    requestStatus: requestStatusFromMutation,
    isLoading: isRequestLoading,
  } = useMutationRequestVideoStreamStatus();

  const isLoading = isVideoStreamLoading || isRequestLoading;

  useEffect(() => {
    if (videoStreamError) {
      notification.error({
        message: videoStreamError?.message,
      });
    }
  }, [videoStreamError]);

  useEffect(() => {
    if (requestError) {
      notification.error({
        message: requestError?.message,
      });
    }
  }, [requestError]);

  const requestStatus = requestStatusFromMutation || agent?.requestedVideoStreamStatus;

  const isVideoSteamRunning =
    videoStream?.ivs_stream_state === STREAM_STATE.START ||
    requestStatus === STREAM_REQUESTED_STATUS.STARTED;

  /**
   * 3 States:
   * - Started by the Dashboard (can stop)
   * - Started by an alarm (can't stop)
   * - Started by an agent from the mobile app (can't stop)
   */

  const shouldPlayVideoFromAlarm = useMemo(
    () =>
      ongoingAlarms
        .filter((alarm) => alarm.activate_video)
        .some((alarm) => alarm.carrier.id === agent?.id),
    [ongoingAlarms, agent?.id],
  );

  const wasStartedByAgent =
    isVideoSteamRunning &&
    !shouldPlayVideoFromAlarm &&
    requestStatus !== STREAM_REQUESTED_STATUS.STARTED;

  const isStartDisabled = shouldPlayVideoFromAlarm || isVideoSteamRunning;
  const isStopDisabled = shouldPlayVideoFromAlarm || !isVideoSteamRunning;

  const isLoadingOrRunning = shouldPlayVideoFromAlarm || isVideoSteamRunning;

  const isStartOrRequestStartLoading =
    isLoading || requestStatus === STREAM_REQUESTED_STATUS.STARTED;

  const getStartTooltip = () => {
    if (shouldPlayVideoFromAlarm) {
      return i18n.t('carrierDetailsPopup.videoStreaming.startTooltipDisabledHasAlert');
    }
    if (isLoading) {
      return i18n.t('common.loading');
    }
    if (isStartDisabled || isVideoSteamRunning) {
      return i18n.t('carrierDetailsPopup.videoStreaming.startTooltipDisabledHasStream');
    }
    return i18n.t('carrierDetailsPopup.videoStreaming.startTooltip');
  };

  const getStopTooltip = () => {
    if (shouldPlayVideoFromAlarm) {
      return i18n.t('carrierDetailsPopup.videoStreaming.stopTooltipDisabledHasAlert');
    }
    if (isLoading) {
      return i18n.t('common.loading');
    }
    if (wasStartedByAgent) {
      return i18n.t('carrierDetailsPopup.videoStreaming.stopTooltipDisabledStartedByAgent');
    }
    if (!isVideoSteamRunning) {
      return i18n.t('carrierDetailsPopup.videoStreaming.stopTooltipDisabledNoStream');
    }
    return i18n.t('carrierDetailsPopup.videoStreaming.stopTooltip');
  };

  if (!agent) {
    return null;
  }

  return (
    <>
      <VideoStreamPlayerElement
        key={`${isStartDisabled}${isStopDisabled}${requestStatus}${JSON.stringify(videoStream)}`}
        agent={agent}
        videoStream={videoStream}
        isLoadingOrRunning={isLoadingOrRunning}
        isStartOrRequestStartLoading={isStartOrRequestStartLoading}
        shouldPlayVideoFromAlarm={shouldPlayVideoFromAlarm}
      />
      {companyFeatures.remoteVideoStreamingControl && (
        <BottomDiv>
          <ActionsDiv>
            <Tooltip title={getStartTooltip()} placement="bottomLeft">
              <Button
                icon={<PlayCircleOutlined data-id="video-play-btn" />}
                loading={isLoading}
                disabled={isStartDisabled}
                onClick={async () => {
                  try {
                    if (agent?.id) {
                      await requestVideoStreamStatus({
                        variables: {
                          carrierId: agent.id,
                          requestedStatus: STREAM_REQUESTED_STATUS.STARTED,
                        },
                      });
                    }
                  } catch (error) {
                    logger.error('VideoStreamPlayerLowLatency: Failed to start video stream', {
                      error,
                      videoStream,
                    });
                  }
                }}
              >
                {i18n.t('carrierDetailsPopup.videoStreaming.start')}
              </Button>
            </Tooltip>
            <Tooltip title={getStopTooltip()} placement="bottomLeft">
              <Button
                icon={<PauseCircleOutlined data-id="video-pause-btn" />}
                loading={isLoading}
                disabled={isStopDisabled}
                onClick={async () => {
                  try {
                    if (agent?.id) {
                      await requestVideoStreamStatus({
                        variables: {
                          carrierId: agent.id,
                          requestedStatus: STREAM_REQUESTED_STATUS.STOPPED,
                        },
                      });
                    }
                  } catch (error) {
                    logger.error('VideoStreamPlayerLowLatency: Failed to stop video stream', {
                      error,
                      videoStream,
                    });
                  }
                }}
              >
                {i18n.t('carrierDetailsPopup.videoStreaming.stop')}
              </Button>
            </Tooltip>
          </ActionsDiv>
          <div style={{ fontSize: '11px', opacity: 0.75 }}>
            {i18n.t('carrierDetailsPopup.videoStreaming.streaming')}:{' '}
            {isVideoStreamLoading
              ? i18n.t('common.loading')
              : getYesOrNo(videoStream?.ivs_stream_state === STREAM_STATE.START)}
            <br />
            {i18n.t('carrierDetailsPopup.videoStreaming.requested')}:{' '}
            {isRequestLoading
              ? i18n.t('common.loading')
              : getYesOrNo(requestStatus === STREAM_REQUESTED_STATUS.STARTED)}
            {isSuperAdmin && !isRequestLoading && videoStream?.ivs_stream_type && (
              <>
                <br />
                {i18n.t('common.type')}: {videoStream?.ivs_stream_type}
              </>
            )}
          </div>
        </BottomDiv>
      )}
    </>
  );
});

VideoStreamPlayer.displayName = 'VideoStreamPlayer';

export default VideoStreamPlayer;
