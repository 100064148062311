export default function getAgentCompleteNameFromCarrier(carrier: {
  name: string;
  attributes?: { name: string; value: string }[];
}): string {
  return (
    [
      carrier.attributes?.find((attribute) => attribute.name === 'first_name')?.value?.trim(),
      carrier.attributes?.find((attribute) => attribute.name === 'last_name')?.value?.trim(),
    ]
      .filter(Boolean)
      .join(' ') || carrier.name?.trim()
  );
}
