import { memo, type ReactNode } from 'react';
import styled from 'styled-components';

const ContentDiv = styled.div`
  img {
    max-width: 100%;
  }

  p,
  ul,
  code {
    font-size: 16px;
  }

  ul {
    margin-top: 0;
    padding-inline-start: 30px;
  }

  code {
    background: #f3f2f2;
    padding: 1px 3px;
  }
`;

interface Props {
  children: ReactNode;
}

const EditorialContent = memo(({ children }: Props) => <ContentDiv>{children}</ContentDiv>);

EditorialContent.displayName = 'EditorialContent';

export default EditorialContent;
