import { gql } from '@apollo/client';

import { getDeviceConfigurationQueryBody } from '~/apollo/operations/shared';
import type { DeviceConfigurationOverride } from '~/types/configurationDevice';

export interface DeviceConfigurationOverrideForCompanyQueryVariables {
  companyId: string;
  nextToken: string | null;
}

export interface DeviceConfigurationOverrideForCompanyQueryData {
  company: {
    configuration: DeviceConfigurationOverride;
  };
}

export default gql`
  query QueryDeviceConfigurationOverrideForCompany($companyId: ID!) {
    company(id: $companyId) {
      configuration(type: "device", name: "base") ${getDeviceConfigurationQueryBody({ isOverride: true })}
    }
  }
`;
