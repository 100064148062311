import { gql, useMutation, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

interface RequestAssociationBrainToAgentInput {
  carrier_id: string;
  device_name: string;
}

interface RequestAssociationBrainToAgentOutput {
  requestAssociation: {
    id: string;
  };
}

export default function useMutationRequestAssociationBrainToAgent(): {
  requestAssociationBrainToAgent: (params: {
    variables: RequestAssociationBrainToAgentInput;
  }) => Promise<unknown>;
  isRequestAssociationBrainToAgentLoading: boolean;
  error?: ApolloError;
} {
  const [requestAssociationBrainToAgent, { loading, error }] = useMutation<
    RequestAssociationBrainToAgentOutput,
    RequestAssociationBrainToAgentInput
  >(
    gql`
      mutation MutationRequestAssociationBrainToAgent($carrier_id: ID!, $device_name: String!) {
        requestAssociation(carrier_id: $carrier_id, device_name: $device_name) {
          id
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return useMemo(
    () => ({
      requestAssociationBrainToAgent,
      isRequestAssociationBrainToAgentLoading: loading,
      error,
    }),
    [requestAssociationBrainToAgent, error, loading],
  );
}
