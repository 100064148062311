import { CONFIGURATION_SCOPE_LEVEL, type ConfigurationClientScope } from '~/types/configuration';

export default function getConfigurationScope(
  itemScope: ConfigurationClientScope,
): CONFIGURATION_SCOPE_LEVEL {
  switch (itemScope) {
    case 'company':
      return CONFIGURATION_SCOPE_LEVEL.company;
    case 'subsidiary':
      return CONFIGURATION_SCOPE_LEVEL.subsidiary;
    case 'agent':
      return CONFIGURATION_SCOPE_LEVEL.agent;
    case 'platform':
    default:
      return CONFIGURATION_SCOPE_LEVEL.platform;
  }
}
