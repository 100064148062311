import { datadogRum } from '@datadog/browser-rum';
import { createContext, useContext, useEffect, useMemo, type ReactNode } from 'react';

import useQueryCurrentUser from '~/apollo/hooks/auth/useQueryCurrentUser';
import { USER_TYPE, type UserType } from '~/types/user';
import notification from '~/utils/notification';

interface CurrentUserContextType {
  currentUser: UserType | undefined;
  isLoading: boolean;
  isSuperAdmin: boolean;
  isCompanyAdmin: boolean;
  isSubsidiaryAdmin: boolean;
}

const initialState: CurrentUserContextType = {
  currentUser: undefined,
  isLoading: true,
  isSuperAdmin: false,
  isCompanyAdmin: false,
  isSubsidiaryAdmin: false,
};

const CurrentUserContext = createContext<CurrentUserContextType>(initialState);

CurrentUserContext.displayName = 'CurrentUserContext';

export function CurrentUserContextProvider({ children }: { children: ReactNode }) {
  const { currentUser, isLoading, error } = useQueryCurrentUser();

  useEffect(() => {
    if (currentUser?.id && datadogRum.getUser()?.id !== currentUser?.id) {
      datadogRum.setUser({ id: currentUser.id });
    }
  }, [currentUser?.id]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: error.name,
        description: error.message,
        placement: 'top',
      });
    }
  }, [error]);

  const isSuperAdmin = currentUser?.type === USER_TYPE.SuperAdmin;
  const isCompanyAdmin = currentUser?.type === USER_TYPE.CompanyAdmin;
  const isSubsidiaryAdmin = currentUser?.type === USER_TYPE.SubsidiaryAdmin;

  const value = useMemo(
    () => ({
      currentUser,
      isLoading,
      isSuperAdmin,
      isCompanyAdmin,
      isSubsidiaryAdmin,
    }),
    [currentUser, isLoading, isSuperAdmin, isCompanyAdmin, isSubsidiaryAdmin],
  );

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>;
}

export default function useCurrentUserContext() {
  return useContext(CurrentUserContext);
}
