import { ConfigProvider, Collapse } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { AlarmConfiguration } from '~/types/configurationAlarm';

import AlarmConfigurationDetails from './AlarmConfigurationDetails';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 12px;
`;

interface Props {
  alarmConfigurations: AlarmConfiguration[];
}

const AlarmConfigurationCollapseList = memo(({ alarmConfigurations }: Props) => (
  <GridDiv>
    {alarmConfigurations.map((alarmConfiguration) => (
      <ConfigProvider
        key={alarmConfiguration.name}
        theme={{
          components: {
            Collapse: {
              headerBg: theme.colors.lightGrey,
            },
          },
        }}
      >
        <Collapse
          defaultActiveKey={[]}
          expandIconPosition="start"
          items={[
            {
              key: alarmConfiguration.name,
              label: (
                <span
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {alarmConfiguration.name}{' '}
                  <span
                    style={{
                      color: alarmConfiguration.activated ? theme.colors.green : theme.colors.red,
                    }}
                  >
                    (
                    {alarmConfiguration.activated
                      ? i18n.t('alarmConfigurations.active')
                      : i18n.t('alarmConfigurations.inactive')}
                    )
                  </span>
                </span>
              ),
              children: <AlarmConfigurationDetails alarmConfiguration={alarmConfiguration} />,
            },
          ]}
        />
      </ConfigProvider>
    ))}
  </GridDiv>
));

AlarmConfigurationCollapseList.displayName = 'AlarmConfigurationCollapseList';

export default AlarmConfigurationCollapseList;
