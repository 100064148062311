import CompassOutlined from '@ant-design/icons/CompassOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { Button, Switch, Tooltip } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import { DETAILS_POPUP_TYPE } from '~/components/DetailsPopup/config/types';
import Link from '~/components/Link';
import routes from '~/config/routes';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import useMapSettings from '~/store/useMapSettings';
import theme from '~/theme';
import type { Agent } from '~/types/agent';

const WrapperDiv = styled.div`
  margin-top: 16px;
  padding: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;

  ${theme.medias.extraSmall} {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;

const FollowingSwitchDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const BottomDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  ${theme.medias.extraSmall} {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;

export interface Props {
  type: DETAILS_POPUP_TYPE;
  agent: Agent | undefined;
}

const DetailsPopupFooter = memo(({ type, agent }: Props) => {
  const { isSuperAdmin } = useCurrentUserContext();
  const { companyFeatures, showLocationHistoryFeature } = useCompanyFeatures();
  const { openModal } = useModalsContext();
  const { showRealtimeLocationFeature } = useCompanyFeatures();

  const shouldFollowCarrier = useMapSettings((state) => state.shouldFollowCarrier);
  const setShouldFollowCarrier = useMapSettings((state) => state.setShouldFollowCarrier);

  const followingAgentTranslation = shouldFollowCarrier
    ? i18n.t('carrierDetailsPopup.followingCarrierOnMap.followingAgentOnMap')
    : i18n.t('carrierDetailsPopup.followingCarrierOnMap.notFollowingAgentOnMap');

  const followingVehicleTranslation = shouldFollowCarrier
    ? i18n.t('carrierDetailsPopup.followingCarrierOnMap.followingVehicleOnMap')
    : i18n.t('carrierDetailsPopup.followingCarrierOnMap.notFollowingVehicleOnMap');

  const showFollowingSwitch = showRealtimeLocationFeature(agent);

  return (
    <WrapperDiv>
      <div>
        {showFollowingSwitch && (
          <FollowingSwitchDiv>
            <Switch
              checked={shouldFollowCarrier}
              disabled={false}
              loading={false}
              onChange={(checked) => {
                setShouldFollowCarrier(checked);
              }}
            />
            <span>
              {type === DETAILS_POPUP_TYPE.vehicle
                ? followingVehicleTranslation
                : followingAgentTranslation}
            </span>
          </FollowingSwitchDiv>
        )}
      </div>
      <BottomDiv>
        {companyFeatures.agentDebugPanel && isSuperAdmin && (
          <Tooltip title={i18n.t('agentDebugModal.buttonTooltip')} placement="left">
            <Button
              data-id="debug-modal-btn"
              type="dashed"
              icon={<LockOutlined />}
              onClick={() => {
                if (agent?.id) {
                  openModal({
                    type: 'agentDebug',
                    agentId: agent.id,
                  });
                }
              }}
            />
          </Tooltip>
        )}
        {showLocationHistoryFeature && (
          <Button
            type="default"
            size="middle"
            icon={<CompassOutlined />}
            onClick={() => {
              if (agent?.id) {
                openModal({
                  type: 'locationHistory',
                  agentId: agent.id,
                });
              }
            }}
          >
            {i18n.t('carrierDetailsPopup.locationHistory')}
          </Button>
        )}
        <Link
          data-id="view-on-dashboard-btn"
          to={routes.map({
            id: agent?.id,
            vehicle: agent?.attributes?.plate_number,
          })}
        >
          <Button type="primary" size="middle" icon={<SearchOutlined />}>
            {i18n.t('carrierDetailsPopup.viewOnMap')}
          </Button>
        </Link>
      </BottomDiv>
    </WrapperDiv>
  );
});

DetailsPopupFooter.displayName = 'DetailsPopupFooter';

export default DetailsPopupFooter;
