import { gql } from '@apollo/client';

import { getAlarmConfigurationQueryBody } from '~/apollo/operations/shared';
import type { ALARM_TYPE } from '~/types/alarm';
import type { AlarmConfigurationOverride } from '~/types/configurationAlarm';

export interface AlarmConfigurationOverrideForCompanyQueryVariables {
  companyId: string;
  alarmConfigurationName: ALARM_TYPE;
  nextToken: string | null;
}

export interface AlarmConfigurationOverrideForCompanyQueryData {
  company: {
    configuration: AlarmConfigurationOverride;
  };
}

export default gql`
  query QueryAlarmConfigurationOverrideForCompany($companyId: ID!, $alarmConfigurationName: String!) {
    company(id: $companyId) {
      configuration(type: "alarm", name: $alarmConfigurationName) ${getAlarmConfigurationQueryBody({ isOverride: true })}
    }
  }
`;
