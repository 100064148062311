export enum LOCALE_CODE {
  ENGLISH = 'en',
  FRENCH = 'fr',
}

export enum LOCALE_TEXT {
  ENGLISH = 'English',
  FRENCH = 'Français',
}

export interface LocaleType {
  code: LOCALE_CODE;
  label: LOCALE_TEXT;
}
