import theme from '~/theme';
import { EQUIPMENT_STATUS, type EquipmentState } from '~/types/equipment';

export default function getEquipmentStatusDysfunctionsColor(status: EQUIPMENT_STATUS | undefined): {
  isWorking: boolean;
  color: string;
  twoToneColor: string | [string, string];
  state: EquipmentState;
} {
  switch (status) {
    case EQUIPMENT_STATUS.no_error:
      return {
        isWorking: true,
        color: theme.colors.brightGreen,
        twoToneColor: theme.colors.brightGreen,
        state: 'ok',
      };
    case EQUIPMENT_STATUS.no_status:
    case EQUIPMENT_STATUS.no_sensor:
    case EQUIPMENT_STATUS.sensor_disconnected:
    case EQUIPMENT_STATUS.sensor_unpaired:
    case EQUIPMENT_STATUS.sensor_inactive:
      return {
        isWorking: false,
        color: theme.colors.midGrey,
        twoToneColor: [theme.colors.midGrey, theme.colors.grey],
        state: 'regular',
      };
    case EQUIPMENT_STATUS.ble_disabled:
    case EQUIPMENT_STATUS.phone_ble_disabled:
    case EQUIPMENT_STATUS.sensor_connecting:
    case EQUIPMENT_STATUS.sensor_data_error:
    case EQUIPMENT_STATUS.sensor_error:
    default:
      return {
        isWorking: false,
        color: theme.colors.yellow,
        twoToneColor: theme.colors.yellow,
        state: 'dysfunction',
      };
  }
}
