import { gql } from '@apollo/client';

import { STREAM_REQUESTED_STATUS } from '~/types/videoStream';

export interface RequestVideoStreamStatusMutationVariables {
  carrierId: string;
  requestedStatus: STREAM_REQUESTED_STATUS;
}

export interface RequestVideoStreamStatusMutationData {
  requestVideoStreamStatus: {
    __typename: 'Carrier_Cognito';
    id: string;
    requested_video_stream_status: STREAM_REQUESTED_STATUS;
  };
}

export default gql`
  mutation MutationRequestVideoStreamStatus(
    $carrierId: ID!
    $requestedStatus: RequestedVideoStreamStatus!
  ) {
    requestVideoStreamStatus(carrier_id: $carrierId, requested_status: $requestedStatus) {
      id
      requested_video_stream_status
    }
  }
`;
