import type { Agent } from '~/types/agent';
import { EQUIPMENT_STATUS } from '~/types/equipment';

export type PopupSectionStatus = 'connected' | 'disconnected' | 'error';

export default function getPopupSectionStatus({
  agent,
  status,
}: {
  agent: Agent | undefined;
  status: EQUIPMENT_STATUS | undefined;
}): PopupSectionStatus {
  const isDisconnected = !agent || agent.isOffline || agent.isConnectionLost;

  if (isDisconnected) {
    return 'disconnected';
  }

  const hasError = !!status && status !== EQUIPMENT_STATUS.no_error;

  if (hasError) {
    return 'disconnected';
  }

  return 'connected';
}
