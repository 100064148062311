import { gql } from '@apollo/client';

export interface SetAgentAttributesMutationVariables {
  agentId: string;
  attributes: { name: string; value: string | null }[];
}

export interface SetAgentAttributesMutationData {
  setCarrierAttributes: { id: string };
}

export default gql`
  mutation MutationSetAgentAttributes($agentId: ID!, $attributes: [AttributeInputNullable!]) {
    setCarrierAttributes(carrier_id: $agentId, attributes: $attributes) {
      id
    }
  }
`;
