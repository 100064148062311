import parseBoolean from '~/utils/parse/parseBoolean';

export default function transformAttributes<T>(
  attributes: { name: string; value: string; __typename?: string }[],
): T {
  return attributes
    ?.filter(
      // eslint-disable-next-line dot-notation
      (obj) => obj.name && obj['__typename'] === 'Attribute',
    )
    .reduce(
      (obj, item) => ({
        ...obj,
        [item.name]: parseBoolean(item.value),
      }),
      {} as T,
    );
}
