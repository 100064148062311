import { gql } from '@apollo/client';

export interface CompanyM2MTokensQueryVariables {
  companyId: string;
}

export interface CompanyM2MTokensQueryData {
  company: {
    __typename: 'Company';
    id: string;
    m2m_tokens: {
      __typename: 'M2MTokenConnection';
      nextToken: string | null;
      items: {
        __typename: 'M2MToken';
        id: string;
        comment: string;
        created_at: string;
      }[];
    };
  };
}

export default gql`
  query QueryCompanyM2MTokens($companyId: ID!, $nextToken: String) {
    company(id: $companyId) {
      id
      m2m_tokens(limit: 1000, nextToken: $nextToken) {
        nextToken
        items {
          id
          comment
          created_at
        }
      }
    }
  }
`;
