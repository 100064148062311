import { useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import type { ALARM_TYPE } from '~/types/alarm';
import type { AlarmConfigurationOverride } from '~/types/configurationAlarm';

import QUERY_ALARM_CONFIGURATION_OVERRIDE_FOR_COMPANY, {
  type AlarmConfigurationOverrideForCompanyQueryData,
  type AlarmConfigurationOverrideForCompanyQueryVariables,
} from './queries/QueryAlarmConfigurationOverrideForCompany';

export default function useQueryAlarmConfigurationOverrideForCompany({
  companyId,
  alarmConfigurationName,
  skip,
}: {
  companyId: string | undefined;
  alarmConfigurationName: ALARM_TYPE;
  skip: boolean;
}): {
  alarmConfigurationOverrideForCompany: AlarmConfigurationOverride | undefined;
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => void;
} {
  const { data, loading, error, refetch } = useQuery<
    AlarmConfigurationOverrideForCompanyQueryData,
    AlarmConfigurationOverrideForCompanyQueryVariables
  >(QUERY_ALARM_CONFIGURATION_OVERRIDE_FOR_COMPANY, {
    variables: {
      companyId: companyId || '',
      alarmConfigurationName,
      nextToken: null,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: skip || !companyId,
  });

  return useMemo(
    () => ({
      alarmConfigurationOverrideForCompany: data?.company?.configuration,
      isLoading: loading,
      error,
      refetch,
    }),
    [data?.company?.configuration, loading, error, refetch],
  );
}
