import { memo, type CSSProperties } from 'react';
import styled from 'styled-components';

import variables from '~/config/variables';
import i18n from '~/locales/i18n';

const TextP = styled.p`
  margin: 0;
  line-height: 1.5;
`;

const CopyrightP = styled.p`
  margin: 16px 0 0;
`;

interface Props {
  className?: string;
  style?: CSSProperties;
}

const AboutContent = memo(({ className, style }: Props) => (
  <div className={className} style={style}>
    <TextP>
      {`Wearin'`}
      <br />
      {i18n.t('aboutContent.version', { version: variables.version || 'development' })}
      <br />
      {i18n.t('aboutContent.buildNumber', { number: variables.commitHash || 'development' })}
      <br />
      {i18n.t('aboutContent.deployTime', { date: variables.commitDate || 'development' })}
    </TextP>
    <CopyrightP>{i18n.t('footer.copyright', { currentYear: new Date().getFullYear() })}</CopyrightP>
  </div>
));

AboutContent.displayName = 'AboutContent';

export default AboutContent;
