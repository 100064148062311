import { Select } from 'antd';
import { memo, useMemo, useCallback } from 'react';
import styled from 'styled-components';

import { CURRENT_SUBSIDIARY_IDENTIFIER_DEFAULT_VALUE } from '~/apollo/reactiveVariables/currentSubsidiaryIdentifierVar';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import theme from '~/theme';

export const StyledSelect = styled(Select<string>)`
  width: 320px;
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

interface Props {
  disabled?: boolean;
  isLoading?: boolean;
}

const SettingsSelectCompany = memo(({ disabled, isLoading }: Props) => {
  const { setCurrentCompanyId, currentCompany, companyList } = useCompany();
  const { setCurrentSubsidiaryId } = useSubsidiary();

  const options = useMemo(() => {
    if (!companyList.length) {
      return [
        {
          label: currentCompany?.name || '',
          value: currentCompany?.id || '',
        },
      ];
    }
    return companyList.map((company) => ({
      label: company.name,
      value: company.id,
    }));
  }, [companyList, currentCompany?.id, currentCompany?.name]);

  const value: string = useMemo(() => {
    if (!companyList.length && currentCompany?.name) {
      return currentCompany?.name;
    }
    return (
      companyList.find((company) => company.id === currentCompany?.id)?.name ||
      i18n.t('footer.selectCompany')
    );
  }, [companyList, currentCompany?.id, currentCompany?.name]);

  const handleChange = useCallback(
    (companyId: string) => {
      setCurrentCompanyId(companyId);
      setCurrentSubsidiaryId(CURRENT_SUBSIDIARY_IDENTIFIER_DEFAULT_VALUE);
    },
    [setCurrentCompanyId, setCurrentSubsidiaryId],
  );

  return (
    <StyledSelect
      disabled={disabled || isLoading}
      value={value}
      onChange={handleChange}
      placeholder={i18n.t('footer.selectCompany')}
      options={options}
      loading={isLoading}
    />
  );
});

SettingsSelectCompany.displayName = 'SettingsSelectCompany';

export default SettingsSelectCompany;
