import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { Table, Tooltip, Button, Tag, type TableColumnsType } from 'antd';
import orderBy from 'lodash/orderBy';
import { memo, useCallback, useMemo, type Key, type ReactNode } from 'react';
import ReactAvatar from 'react-avatar';
import styled from 'styled-components';

import useMutationDeleteSubsidiary from '~/apollo/hooks/subsidiary/useMutationDeleteSubsidiary';
import useQueryCompanySubsidiaryListWithDetails, {
  CompanySubsidiaryListWithDetailsQueryData,
} from '~/apollo/hooks/subsidiary/useQueryCompanySubsidiaryListWithDetails';
import SettingsSelectCompany from '~/components/settings/components/SettingsSelectCompany';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import {
  SelectCompanySubsidiaryDiv,
  SettingsButtonsFlexDiv,
  SettingsTopFlexDiv,
} from '~/components/settings/shared';
import TableInfoListButton from '~/components/TableInfoListButton';
import TimeAgo from '~/components/TimeAgo';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import useTableSearch from '~/hooks/useTableSearch';
import i18n from '~/locales/i18n';
import { TextAvatarDiv } from '~/pages/BackofficePage/components/TabSuperAdmins/TabSuperAdmins';
import theme from '~/theme';
import type { GpsCoordinates } from '~/types/sensor';
import getAgentCompleteNameFromCarrier from '~/utils/agent/getAgentCompleteNameFromCarrier';
import { formatDateTime } from '~/utils/dateTime';
import notification from '~/utils/notification';
import defaultTablePaginationProps from '~/utils/table/defaultTablePaginationProps';

import LocationItem from './components/LocationItem';

interface TableDataType {
  key: Key;
  name: string;
  id: string;
  subsidiaryAdmins: CompanySubsidiaryListWithDetailsQueryData['company']['subsidiaries']['items'][0]['admins']['items'];
  subsidiaryAdminsCount: number;
  carriers: CompanySubsidiaryListWithDetailsQueryData['company']['subsidiaries']['items'][0]['carriers']['items'];
  carriersCount: number;
  location: GpsCoordinates | null | undefined;
  createdAt: string;
  actions: ReactNode;
}

const WrapperDiv = styled.div`
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

const StyledTable = styled(Table<TableDataType>)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const ActionsDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

interface Props {
  isBackofficePage: boolean;
}

const TabSubsidiaries = memo(({ isBackofficePage }: Props) => {
  const { isSuperAdmin, isLoading: isLoadingCurrentUser } = useCurrentUserContext();
  const { openModal } = useModalsContext();

  const { currentCompany, refetchCompanyList, refetchCompanyDetails } = useCompany();
  const { deleteSubsidiary, isLoading: isLoadingDeleteSubsidiary } = useMutationDeleteSubsidiary();

  const { currentSubsidiary, refetchSubsidiaryList } = useSubsidiary();
  const {
    subsidiaryListWithDetails,
    isLoading: isLoadingSubsidiaryListWithDetails,
    refetchSubsidiaryListWithDetails,
  } = useQueryCompanySubsidiaryListWithDetails({
    companyId: currentCompany?.id || '',
  });

  const isLoading =
    isLoadingCurrentUser || isLoadingSubsidiaryListWithDetails || isLoadingDeleteSubsidiary;

  const totalRefetch = useCallback(async () => {
    await Promise.all([refetchSubsidiaryList(), refetchSubsidiaryListWithDetails()]);
  }, [refetchSubsidiaryList, refetchSubsidiaryListWithDetails]);

  const { getColumnSearchProps } = useTableSearch<TableDataType>();

  const columns: TableColumnsType<TableDataType> = useMemo(
    () => [
      {
        dataIndex: 'name',
        title: i18n.t('common.name'),
        sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        defaultSortOrder: 'ascend',
        ...getColumnSearchProps({
          dataIndex: 'name',
          title: i18n.t('common.name'),
          renderWithHighlight: ({ text, record, highlightedNode }) =>
            text ? (
              <TextAvatarDiv>
                <ReactAvatar name={text} size="26px" /> {highlightedNode}
                {currentSubsidiary?.id === record.id && (
                  <Tag color="green">{i18n.t('common.selected')}</Tag>
                )}
              </TextAvatarDiv>
            ) : (
              '-'
            ),
        }),
      },
      {
        dataIndex: 'location',
        title: i18n.t('common.location'),
        render: (text, record) => <LocationItem coordinates={record.location} />,
      },
      {
        dataIndex: 'subsidiaryAdminsCount',
        title: i18n.t('companySettingsPage.subsidiariesTab.subsidiaryAdmins'),
        sorter: (a, b) => a.subsidiaryAdminsCount - b.subsidiaryAdminsCount,
        render: (text, record) => {
          const sortedAdmins = orderBy(
            record.subsidiaryAdmins,
            [(item) => item.email.toLowerCase()],
            ['asc'],
          );
          return (
            <TableInfoListButton
              buttonText={text}
              modalTitle={record.name}
              modalSubtitle={
                <>
                  {i18n.t('companySettingsPage.subsidiariesTab.subsidiaryAdmins')} ({text})
                </>
              }
              modalList={sortedAdmins.map((admin) => admin.email)}
            />
          );
        },
      },
      {
        dataIndex: 'carriersCount',
        title: i18n.t('companySettingsPage.subsidiariesTab.agents'),
        sorter: (a, b) => a.carriersCount - b.carriersCount,
        render: (text, record) => {
          const sortedCarriers = orderBy(
            record.carriers,
            [(item) => item.name.toLowerCase()],
            ['asc'],
          );
          return (
            <TableInfoListButton
              buttonText={text}
              modalTitle={record.name}
              modalSubtitle={
                <>
                  {i18n.t('companySettingsPage.subsidiariesTab.agents')} ({text})
                </>
              }
              modalList={sortedCarriers
                .map((carrier) => [
                  getAgentCompleteNameFromCarrier(carrier) || carrier.id,
                  carrier.email ? `(${carrier.email})` : undefined,
                ])
                .map((carrier) => carrier.filter(Boolean).join(' '))}
            />
          );
        },
      },
      {
        dataIndex: 'createdAt',
        title: i18n.t('common.created'),
        sorter: (a, b) => a.createdAt.toLowerCase().localeCompare(b.createdAt.toLowerCase()),
        render: (text) => (
          <Tooltip title={formatDateTime(text)}>
            <span style={{ cursor: 'help' }}>
              <TimeAgo date={text} />
            </span>
          </Tooltip>
        ),
      },
      {
        dataIndex: 'actions',
        title: i18n.t('common.actions'),
        fixed: 'right',
      },
    ],
    [currentSubsidiary?.id, getColumnSearchProps],
  );

  const data: TableDataType[] = useMemo(
    () =>
      subsidiaryListWithDetails.map((subsidiaryDetails) => {
        const canBeDeleted =
          subsidiaryDetails.carriers.items.length === 0 &&
          subsidiaryDetails.admins.items.length === 0;

        return {
          key: subsidiaryDetails.id,
          name: subsidiaryDetails.name,
          id: subsidiaryDetails.id,
          subsidiaryAdmins: subsidiaryDetails.admins.items,
          subsidiaryAdminsCount: subsidiaryDetails.admins.items.length,
          carriers: subsidiaryDetails.carriers.items,
          carriersCount: subsidiaryDetails.carriers.items.length,
          createdAt: subsidiaryDetails.created_at,
          location: subsidiaryDetails.location,
          actions: (
            <ActionsDiv>
              <Tooltip title={i18n.t('companySettingsPage.subsidiariesTab.editSubsidiary')}>
                <Button
                  onClick={() => {
                    openModal({
                      type: 'subsidiaryAddEdit',
                      subsidiaryId: subsidiaryDetails.id,
                      refetchOnCompleted: totalRefetch,
                    });
                  }}
                  icon={<EditOutlined />}
                />
              </Tooltip>
              <Tooltip
                title={
                  canBeDeleted
                    ? i18n.t('companySettingsPage.subsidiariesTab.deleteSubsidiary')
                    : i18n.t('companySettingsPage.subsidiariesTab.cannotDeleteSubsidiary')
                }
              >
                <Button
                  danger
                  disabled={!canBeDeleted}
                  onClick={() => {
                    openModal({
                      type: 'confirmation',
                      title: i18n.t('companySettingsPage.subsidiariesTab.deleteSubsidiary'),
                      description: (
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: i18n.t<string>(
                              'companySettingsPage.subsidiariesTab.areYouSureYouWantToDeleteSubsidiaryHtml',
                              {
                                subsidiaryName: subsidiaryDetails.name,
                              },
                            ),
                          }}
                        />
                      ),
                      actionType: 'danger',
                      action: async () => {
                        try {
                          await deleteSubsidiary({
                            variables: {
                              subsidiary_id: subsidiaryDetails.id,
                            },
                          });
                          await totalRefetch();
                          if (isSuperAdmin) {
                            refetchCompanyList();
                          } else {
                            refetchCompanyDetails();
                          }
                          notification.success({
                            message: i18n.t('common.success'),
                            description: i18n.t(
                              'companySettingsPage.subsidiariesTab.subsidiaryDeletedSuccess',
                            ),
                          });
                        } catch (error) {
                          notification.error({
                            message: (error as any)?.message || i18n.t('common.error'),
                          });
                        }
                      },
                    });
                  }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </ActionsDiv>
          ),
        };
      }),
    [
      subsidiaryListWithDetails,
      isSuperAdmin,
      openModal,
      totalRefetch,
      deleteSubsidiary,
      refetchCompanyList,
      refetchCompanyDetails,
    ],
  );

  return (
    <WrapperDiv>
      <SelectCompanySubsidiaryDiv>
        {isBackofficePage && (
          <div>
            <TabSectionHeader title={i18n.t('companyFeatures.selectedCompany')} />
            <SettingsSelectCompany isLoading={isLoading} />
          </div>
        )}
      </SelectCompanySubsidiaryDiv>
      <SettingsTopFlexDiv>
        <TabSectionHeader
          title={
            isLoading
              ? i18n.t('common.loading')
              : `${i18n.t('companySettingsPage.subsidiariesTab.listOfSubsidiaries')} (${subsidiaryListWithDetails.length})`
          }
        />
        <SettingsButtonsFlexDiv>
          <Button
            loading={isLoading}
            icon={<ReloadOutlined />}
            onClick={async () => {
              await totalRefetch();
            }}
          >
            {i18n.t('common.refresh')}
          </Button>
          <Button
            loading={isLoading}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              openModal({
                type: 'subsidiaryAddEdit',
                subsidiaryId: undefined,
                refetchOnCompleted: totalRefetch,
              });
            }}
          >
            {i18n.t('companySettingsPage.subsidiariesTab.createSubsidiary')}
          </Button>
        </SettingsButtonsFlexDiv>
      </SettingsTopFlexDiv>
      <StyledTable
        tableLayout="auto"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        sortDirections={['ascend', 'descend', 'ascend']}
        pagination={defaultTablePaginationProps}
      />
    </WrapperDiv>
  );
});

TabSubsidiaries.displayName = 'TabSubsidiaries';

export default TabSubsidiaries;
