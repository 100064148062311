import { memo } from 'react';
import styled from 'styled-components';

import type { Agent } from '~/types/agent';

import EquipmentItemCard from './components/EquipmentItemCard';
import useAgentEquipment from './hooks/useAgentEquipment';

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 12px;
`;

interface Props {
  agent: Agent | undefined;
}

const SectionEquipment = memo(({ agent }: Props) => {
  const { equipmentItems, hasImpactDetectionAlarm } = useAgentEquipment(agent);

  return (
    <GridDiv data-id="SectionEquipment" key={hasImpactDetectionAlarm.toString()}>
      {equipmentItems.map((item) => (
        <EquipmentItemCard key={item.key} item={item} />
      ))}
    </GridDiv>
  );
});

SectionEquipment.displayName = 'SectionEquipment';

export default SectionEquipment;
