import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

import type { ALARM_TYPE } from '~/types/alarm';
import type { AlarmConfiguration } from '~/types/configurationAlarm';

export interface SetConfigurationAlarmOverrideVariables {
  configuration: Partial<AlarmConfiguration> | undefined;
  name: ALARM_TYPE | undefined;
  companyId: string | undefined;
  subsidiaryId: string | undefined;
  carrierId: string | undefined;
}

export interface SetConfigurationAlarmOverrideData {
  setConfigurationAlarmOverride: {
    name: string;
  };
}

export default function useMutationSetConfigurationAlarmOverride(): {
  setConfigurationAlarmOverride: (
    variables: SetConfigurationAlarmOverrideVariables,
  ) => Promise<void>;
  result: SetConfigurationAlarmOverrideData['setConfigurationAlarmOverride'] | undefined;
  isLoading: boolean;
  error: Error | undefined;
} {
  const [setConfigurationAlarmOverride, { data, loading, error }] =
    useMutation<SetConfigurationAlarmOverrideData>(
      gql`
        mutation MutationSetConfigurationAlarmOverride(
          $name: String!
          $companyId: ID
          $subsidiaryId: ID
          $carrierId: ID
          $configuration: setConfigurationAlarmOverrideInput
        ) {
          setConfigurationAlarmOverride(
            name: $name
            company_id: $companyId
            subsidiary_id: $subsidiaryId
            carrier_id: $carrierId
            configuration: $configuration
          ) {
            name
          }
        }
      `,
      {
        fetchPolicy: 'no-cache',
      },
    );

  const setConfigurationAlarmOverrideCurated = useMemo(
    () => async (variables: SetConfigurationAlarmOverrideVariables) => {
      // Logic:
      // Value -> set
      // Null -> remove
      // Undefined -> ignore
      await setConfigurationAlarmOverride({
        variables: {
          name: variables.name,
          companyId: variables.companyId,
          subsidiaryId: variables.subsidiaryId,
          carrierId: variables.carrierId,
          configuration: variables.configuration
            ? {
                ...variables.configuration,
                activated: variables.configuration?.activated,
                level: variables.configuration?.level,
                authority: variables.configuration?.authority,
                notify_sms: variables.configuration?.notify_sms,
                notify_voice_call: variables.configuration?.notify_voice_call,
                activate_video: variables.configuration?.activate_video,
                vest_feedback: variables.configuration?.vest_feedback,
                can_assign: variables.configuration?.can_assign,
                can_view_other_carriers: variables.configuration?.can_view_other_carriers,
                can_view_teamleader: variables.configuration?.can_view_teamleader,
                can_dismiss_dashboard: variables.configuration?.can_dismiss_dashboard,
                can_dismiss_teamleader: variables.configuration?.can_dismiss_teamleader,
                can_dismiss_carrier: variables.configuration?.can_dismiss_carrier,
                can_cancel: variables.configuration?.can_cancel,
                trigger_parameters: variables.configuration?.trigger_parameters,
              }
            : null,
        },
      });
    },
    [setConfigurationAlarmOverride],
  );

  return useMemo(
    () => ({
      setConfigurationAlarmOverride: setConfigurationAlarmOverrideCurated,
      result: data?.setConfigurationAlarmOverride,
      isLoading: loading,
      error,
    }),
    [setConfigurationAlarmOverrideCurated, data?.setConfigurationAlarmOverride, error, loading],
  );
}
