import { gql } from '@apollo/client';

import { getDeviceConfigurationQueryBody } from '~/apollo/operations/shared';
import type { DeviceConfigurationOverride } from '~/types/configurationDevice';

export interface DeviceConfigurationOverrideForSubsidiaryQueryVariables {
  subsidiaryId: string;
  nextToken: string | null;
}

export interface DeviceConfigurationOverrideForSubsidiaryQueryData {
  subsidiary: {
    configuration: DeviceConfigurationOverride;
  };
}

export default gql`
  query QueryDeviceConfigurationOverrideForSubsidiary($subsidiaryId: ID!) {
    subsidiary(id: $subsidiaryId) {
      configuration(type: "device", name: "base") ${getDeviceConfigurationQueryBody({ isOverride: true })}
    }
  }
`;
