import { memo } from 'react';
import styled from 'styled-components';

import theme from '~/theme';

const Div = styled.div`
  max-width: 210px;
  padding: 0 16px;

  ${theme.medias.lteSmall} {
    padding: 10px 0 0;
    max-width: 100%;
  }
`;

const H2 = styled.h2`
  color: ${theme.colors.white};
  font-size: 14px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface Props {
  name: string | undefined;
}

const NavbarEntityName = memo(({ name }: Props) => {
  if (!name) {
    return null;
  }

  return (
    <Div>
      <H2>{name}</H2>
    </Div>
  );
});

NavbarEntityName.displayName = 'NavbarEntityName';

export default NavbarEntityName;
