import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Input, Button, Form } from 'antd';
import { memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import useMutationUpdateCompany from '~/apollo/hooks/company/useMutationUpdateCompany';
import CompanyFeaturesButtonList from '~/components/CompanyFeaturesButtonList';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompany from '~/hooks/useCompany';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import notification from '~/utils/notification';

import OnlyCompanyAdminsAlert from '../OnlyCompanyAdminsAlert';

export const CompanySettingsWrapperDiv = styled.div`
  width: 600px;
  max-width: 100%;

  ${theme.medias.lteSmall} {
    width: 100%;
  }
`;

interface FormValues {
  name: string;
}

const TabCompanyDetails = memo(() => {
  const { isSubsidiaryAdmin, isSuperAdmin } = useCurrentUserContext();
  const { currentCompany, refetchCompanyDetails, refetchCompanyList } = useCompany();
  const { companyFeatures } = useCompanyFeatures();
  const { updateCompany, isLoading } = useMutationUpdateCompany();

  const [form] = Form.useForm<FormValues>();

  useEffect(() => {
    form.resetFields();
  }, [form, currentCompany?.id]);

  const onFinish = useCallback(
    async (values: FormValues) => {
      try {
        if (currentCompany?.id) {
          await updateCompany({
            variables: {
              company_id: currentCompany?.id,
              name: values.name.trim(),
            },
          });
          notification.success({
            message: i18n.t('common.success'),
            description: i18n.t('backofficeCompaniesPage.companyUpdatedSuccess'),
          });
          if (isSuperAdmin) {
            refetchCompanyList();
          } else {
            refetchCompanyDetails();
          }
        }
      } catch (error) {
        notification.error({
          message: (error as any)?.message || i18n.t('common.error'),
        });
      }
    },
    [currentCompany?.id, updateCompany, isSuperAdmin, refetchCompanyList, refetchCompanyDetails],
  );

  if (!currentCompany?.id) {
    return <p>{i18n.t('companySettingsPage.companyTab.missingCompany')}</p>;
  }

  return (
    <CompanySettingsWrapperDiv>
      <OnlyCompanyAdminsAlert />
      <Form
        style={{ marginTop: '20px' }}
        form={form}
        onFinish={onFinish}
        initialValues={{
          name: currentCompany?.name || '',
        }}
        layout="vertical"
      >
        <Form.Item label={i18n.t('companySettingsPage.companyTab.companyName')} name="name">
          <Input type="text" required disabled={isSubsidiaryAdmin || isLoading} />
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            disabled={isSubsidiaryAdmin}
            loading={isLoading}
            icon={<SaveOutlined />}
          >
            {i18n.t('common.save')}
          </Button>
        </Form.Item>
      </Form>
      <TabSectionHeader title={i18n.t('companySettingsPage.companyTab.companyFeatures')} />
      <CompanyFeaturesButtonList
        companyFeaturesToShow={{
          safeZone: companyFeatures.safeZone,
          issueTracking: companyFeatures.issueTracking,
        }}
      />
    </CompanySettingsWrapperDiv>
  );
});

TabCompanyDetails.displayName = 'TabCompanyDetails';

export default TabCompanyDetails;
