import { Space } from 'antd';
import { memo } from 'react';
import { BsClock } from 'react-icons/bs';

import useLiveDuration from '~/hooks/useLiveDuration';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import { computeMissionTime } from '~/utils/dateTime';

import LabelWithValue from './LabelWithValue';

interface Props {
  agent: Agent | undefined;
}

const InfoMissionDuration = memo(({ agent }: Props) => {
  const timestamp = agent?.missionStartTimeISO;

  const missionDuration = useLiveDuration({
    startTime: timestamp,
    endTime: undefined,
  });

  if (!agent || agent?.isConnectionLost || !timestamp) {
    return null;
  }

  const missionStarted = computeMissionTime(timestamp);

  return (
    <Space align="center">
      <BsClock color={theme.colors.darkBlue} size={18} />
      <LabelWithValue
        data-id="DetailsPopup-InfoMissionDuration-missionStarted"
        label={i18n.t('carrierDetailsPopup.missionStarted')}
        value={missionStarted}
        hasCopyToClipboard={false}
      />
      <LabelWithValue
        data-id="DetailsPopup-InfoMissionDuration-duration"
        label={i18n.t('carrierDetailsPopup.duration')}
        value={missionDuration}
        hasCopyToClipboard={false}
      />
    </Space>
  );
});

InfoMissionDuration.displayName = 'InfoMissionDuration';

export default InfoMissionDuration;
