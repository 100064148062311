import { gql } from '@apollo/client';

export interface DisAssociateAlarmAdminMutationVariables {
  alarmId: string;
  adminId: string;
}

export interface DisassociateAlarmAdminMutationData {
  disassociateAlarmAdministrator: {
    __typename: 'Alarm';
    id: string;
  };
}

export default gql`
  mutation MutationDisassociateAlarmAdmin($alarmId: ID!, $adminId: ID!) {
    disassociateAlarmAdministrator(alarm_id: $alarmId, administrator_id: $adminId) {
      id
    }
  }
`;
