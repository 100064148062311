import { gql } from '@apollo/client';

import { getDeviceConfigurationQueryBody } from '~/apollo/operations/shared';
import type { DeviceConfiguration } from '~/types/configurationDevice';

export interface DeviceConfigurationsQueryVariables {
  nextToken: string | null;
}

export interface DeviceConfigurationsQueryData {
  configurations: {
    nextToken: string | null;
    items: DeviceConfiguration[];
  };
}

export default gql`
  query QueryDeviceConfigurations($nextToken: String) {
    configurations(type: "device", nextToken: $nextToken) {
      items ${getDeviceConfigurationQueryBody({ isOverride: false })}
      nextToken
    }
  }
`;
