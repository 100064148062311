import EyeOutlined from '@ant-design/icons/EyeOutlined';
import { Button } from 'antd';
import { memo, useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Link from '~/components/Link';
import routes from '~/config/routes';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';

const AGENT_HEADER_SPACING = '0.25rem';

const CIRCLE_WIDTH = '38px';

const WrapperDiv = styled.div<{ $isSelected: boolean }>`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 32px);
  align-items: center;
  grid-gap: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: ${(props) => (props.$isSelected ? theme.colors.lightGrey : theme.colors.white)};
  padding-right: 8px;

  &:hover {
    background: ${theme.colors.lightGrey};
  }
`;

const StyledLink = styled(Link)`
  display: grid;
  grid-template-columns: minmax(0, ${CIRCLE_WIDTH}) minmax(0, 1fr);
  align-items: center;
  padding: 8px;
  grid-gap: 12px;
`;

const ProfileCircleDiv = styled.div`
  align-items: center;
  display: flex;
  font-weight: regular;
  justify-content: center;
  border-radius: 32px;
  margin-right: 0;
  font-size: 12px;
  height: ${CIRCLE_WIDTH};
  width: ${CIRCLE_WIDTH};
  margin: 0;
`;

const RightDiv = styled.div``;

const TitleH1 = styled.h1`
  margin: 0;
  font-size: 16px;
  color: ${theme.colors.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubtitleH2 = styled.h2`
  margin: ${AGENT_HEADER_SPACING} 0 0;
  font-size: 12px;
  opacity: 0.7;
  color: rgba(0, 0, 0, 0.75);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  agent: Agent;
  searchText: string;
  idFromQuery: string | undefined;
  vehicleFromQuery: string | undefined;
}

const AgentButton = memo(({ agent, searchText, idFromQuery, vehicleFromQuery }: Props) => {
  const navigate = useNavigate();
  const agentStatusColor = useMemo(() => getAgentStatusColor(agent), [agent]);

  const currentUrl = routes.map({
    id: idFromQuery,
    vehicle: vehicleFromQuery,
  });

  const navigationUrl = routes.map({
    id: agent?.id,
    vehicle: agent?.attributes?.plate_number,
  });

  const isSelected = currentUrl === navigationUrl;

  return (
    <WrapperDiv $isSelected={isSelected}>
      <StyledLink to={isSelected ? routes.map() : navigationUrl}>
        <ProfileCircleDiv
          style={{
            backgroundColor: agentStatusColor,
            borderColor: agentStatusColor,
            color: theme.colors.white,
          }}
          data-id="details-popup-bullet-label"
        >
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText?.trim()]}
            autoEscape
            textToHighlight={agent?.attributes?.acronym || ''}
          />
        </ProfileCircleDiv>
        <RightDiv>
          <TitleH1 data-id="details-popup-title">
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText?.trim()]}
              autoEscape
              textToHighlight={agent?.completeName || ''}
            />
          </TitleH1>
          {(agent?.team || agent?.attributes?.plate_number) && (
            <SubtitleH2 data-id="details-popup-subtitle">
              {agent?.team && (
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[searchText?.trim()]}
                  autoEscape
                  textToHighlight={agent?.team || ''}
                />
              )}
              {agent?.attributes?.plate_number && (
                <>
                  {' | '}
                  <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText?.trim()]}
                    autoEscape
                    textToHighlight={agent?.attributes?.plate_number || ''}
                  />
                </>
              )}
            </SubtitleH2>
          )}
        </RightDiv>
      </StyledLink>
      <Button
        onClick={() => {
          navigate(
            routes.status({
              id: agent?.id,
            }),
          );
        }}
      >
        <EyeOutlined />
      </Button>
    </WrapperDiv>
  );
});

AgentButton.displayName = 'AgentButton';

export default AgentButton;
