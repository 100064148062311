import { useEffect } from 'react';

export default function usePreventPageScroll(): void {
  useEffect(() => {
    const body = document.querySelector('body');
    body?.style?.setProperty('overflow-y', 'hidden');

    return () => {
      body?.style?.removeProperty('overflow-y');
    };
  }, []);
}
