import { gql, useQuery } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

import type { MergedAdmin } from './useQueryCompanyAndSubsidiaryAdmins';

interface QuerySubsidiaryAdminsInput {
  subsidiaryId: string;
  nextToken: string | null;
  limit: number;
}

interface QuerySubsidiaryAdminsOutput {
  subsidiary: {
    __typename: 'Subsidiary';
    id: string;
    name: string;
    admins: {
      __typename: 'AdministratorConnection';
      nextToken: string | null;
      items: {
        __typename: 'SubsidiaryAdmin';
        id: string;
        email: string;
        first_name: string | null;
        last_name: string | null;
      }[];
    };
  };
}

export default function useQuerySubsidiaryAdmins({
  subsidiaryId,
  nextToken,
  limit,
}: {
  subsidiaryId: string | undefined;
  nextToken: string | null;
  limit?: number;
}) {
  const { data, loading, error, refetch } = useQuery<
    QuerySubsidiaryAdminsOutput,
    QuerySubsidiaryAdminsInput
  >(
    gql`
      query QueryAdmins($subsidiaryId: ID!, $limit: Int, $nextToken: String) {
        subsidiary(id: $subsidiaryId) {
          id
          name
          admins(limit: $limit, nextToken: $nextToken) {
            nextToken
            items {
              id
              email
              first_name
              last_name
            }
          }
        }
      }
    `,
    {
      skip: !subsidiaryId,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      variables: {
        subsidiaryId: subsidiaryId || '',
        limit: limit || 1000,
        nextToken,
      },
    },
  );

  const subsidiaryAdmins: MergedAdmin[] = useMemo(
    () =>
      (data?.subsidiary?.admins?.items || [])?.map((admin) => ({
        ...admin,
        subsidiaryName: data?.subsidiary?.name || null,
      })),
    [data?.subsidiary?.admins?.items, data?.subsidiary?.name],
  );

  return useMemo(
    () => ({
      admins: orderBy(subsidiaryAdmins, ['email'], ['asc']),
      isLoading: loading,
      error,
      refetchSubsidiaryAdmins: refetch,
    }),
    [subsidiaryAdmins, loading, error, refetch],
  );
}
