import { useLazyQuery, type ApolloError } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import type { Agent } from '~/types/agent';
import logger from '~/utils/logger';
import getVideoStreamLogId from '~/utils/video/getVideoStreamLogId';

import QUERY_CARRIER_VIDEO_STREAM, {
  type CarrierVideoStreamQueryData,
  type CarrierVideoStreamQueryVariables,
} from './queries/QueryCarrierVideoStream';

export default function useQueryWithSubscriptionCarrierVideoStream({
  agent,
}: {
  agent: Agent | undefined;
}): {
  videoStream?: CarrierVideoStreamQueryData['carrier']['video_stream'];
  isLoading: boolean;
  error?: ApolloError;
} {
  // We need to fetch key and url for video stream and these are only available when streaming starts
  const [fetchVideoStream, { data, error, loading }] = useLazyQuery<
    CarrierVideoStreamQueryData,
    CarrierVideoStreamQueryVariables
  >(QUERY_CARRIER_VIDEO_STREAM, {
    // Video stream details (url and key) can change when the stream state changes
    fetchPolicy: 'network-only',
    onCompleted: ({ carrier }) => {
      const streamState = carrier?.video_stream?.ivs_stream_state ?? 'NONE';

      logger.log('useQueryWithSubscriptionCarrierVideoStream: loaded details', {
        carrier,
        agent,
        streamState,
        videoStreamLogId: getVideoStreamLogId(carrier?.video_stream?.playback_url),
      });
    },
    onError: (e) => {
      logger.error('useQueryWithSubscriptionCarrierVideoStream: query error', { error: e, agent });
    },
  });

  useEffect(() => {
    if (!agent?.id) {
      return;
    }

    logger.log('useQueryWithSubscriptionCarrierVideoStream: refetching');
    fetchVideoStream({ variables: { carrierID: agent?.id || '' } });
  }, [fetchVideoStream, agent?.id, agent?.video_stream?.ivs_stream_state]);

  return useMemo(
    () => ({
      videoStream: data?.carrier?.video_stream,
      isLoading: loading,
      error,
    }),
    [data?.carrier?.video_stream, loading, error],
  );
}
