import { memo } from 'react';
import styled from 'styled-components';

import NavbarDesktopConnectionIndicator from '~/components/Navbar//components/NavbarDesktopConnectionIndicator';
import NavbarAlarmsLink from '~/components/Navbar/components/NavbarAlarmsLink';
import NavbarContactsOnCall from '~/components/Navbar/components/NavbarContactsOnCall';
import NavbarEntityName from '~/components/Navbar/components/NavbarEntityName';
import NavbarHeader from '~/components/Navbar/components/NavbarHeader';
import NavbarLogo from '~/components/Navbar/components/NavbarLogo';
import NavbarSelectCompany from '~/components/Navbar/components/NavbarSelectCompany';
import NavbarSelectSubsidiary from '~/components/Navbar/components/NavbarSelectSubsidiary';
import NavbarUserDropdown from '~/components/Navbar/components/NavbarUserDropdown';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompany from '~/hooks/useCompany';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useSubsidiary from '~/hooks/useSubsidiary';

import NavbarDivider from './NavbarDesktopDivider';
import NavbarMenu from './NavbarDesktopMenu';

const RightFlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const NavbarDesktop = memo(() => {
  const { companyFeatures } = useCompanyFeatures();
  const { currentUser } = useCurrentUserContext();
  const { companyList } = useCompany();
  const { subsidiaryList } = useSubsidiary();

  const hasLoaded = !!currentUser;

  return (
    <NavbarHeader>
      {hasLoaded && (
        <>
          <NavbarLogo />
          <NavbarDivider />
          {companyList.length > 0 ? (
            <NavbarSelectCompany />
          ) : (
            <NavbarEntityName name={currentUser?.company?.name} />
          )}
          <NavbarDivider />
          {subsidiaryList.length > 0 ? (
            <NavbarSelectSubsidiary />
          ) : (
            <NavbarEntityName name={currentUser?.subsidiary?.name} />
          )}
          <NavbarDivider />
          <NavbarMenu />
          <RightFlexDiv>
            <NavbarDesktopConnectionIndicator />
            {companyFeatures.contactsOnCall && <NavbarContactsOnCall />}
            <NavbarAlarmsLink />
            <NavbarUserDropdown />
          </RightFlexDiv>
        </>
      )}
    </NavbarHeader>
  );
});

NavbarDesktop.displayName = 'NavbarDesktop';

export default NavbarDesktop;
