export default function formatNumberWithUnit({
  number,
  unit,
  decimals = 2,
}: {
  number: number | null | undefined;
  unit?: string;
  decimals?: number;
}): string {
  const isValidNumber = typeof number === 'number' && number >= 0;

  const hasDecimals = isValidNumber && decimals && number ? number % 1 !== 0 : false;

  return isValidNumber
    ? `${hasDecimals ? number.toFixed(decimals) : number.toString()}${unit ? ` ${unit}` : ''}`
    : 'n/a';
}
