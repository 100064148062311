import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { createRoot } from 'react-dom/client';

import { datadogRumConfig, datadogLogsConfig } from '~/config/datadog';

import App from './App';

if (window.location.hostname !== 'localhost' && !window.Cypress) {
  datadogRum.init(datadogRumConfig);
  datadogLogs.init(datadogLogsConfig);

  datadogRum.startSessionReplayRecording();
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
