export type ConfigurationClientScope = 'platform' | 'company' | 'subsidiary' | 'agent';

export interface ConfigurationOverrideScope {
  __typename?: 'Company' | 'Subsidiary' | 'Carrier_Cognito' | 'Carrier_NonCognito';
  id: string;
  name: string;
  // For typename Subsidiary
  company?: {
    id: string;
    name: string;
  };
  // For typename Carrier_Cognito and Carrier_NonCognito
  attributes?: {
    name: string;
    value: string;
  }[];
  subsidiary?: {
    id: string;
    name: string;
    company: {
      id: string;
      name: string;
    };
  };
}

export enum CONFIGURATION_SCOPE_LEVEL {
  platform = 1,
  company = 2,
  subsidiary = 3,
  agent = 4,
}
