import BookOutlined from '@ant-design/icons/BookOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import CloudOutlined from '@ant-design/icons/CloudOutlined';
import CustomerServiceOutlined from '@ant-design/icons/CustomerServiceOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';
import NotificationOutlined from '@ant-design/icons/NotificationOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import { Badge, Dropdown, message, type MenuProps } from 'antd';
import type { MenuDividerType } from 'antd/es/menu/interface';
import { memo, useMemo, useState, useEffect } from 'react';
import ReactAvatar from 'react-avatar';
import { BiFullscreen } from 'react-icons/bi';
import styled from 'styled-components';

import Link from '~/components/Link';
import routes from '~/config/routes';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import usePathname from '~/hooks/usePathname';
import i18n from '~/locales/i18n';
import useHighlightedFeatures, { getHighlightedItems } from '~/store/useHighlightedFeatures';
import theme from '~/theme';
import logger from '~/utils/logger';
import notification from '~/utils/notification';
import getUserName from '~/utils/user/getUserName';

const WrapperDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserButton = styled.button`
  color: white;
  font-size: 15px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: transparent;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  ${theme.medias.lteSmall} {
    & > span:last-of-type {
      display: none;
    }
  }
`;

const AccountStyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
  padding-right: 12px;
`;

const AccountDetailsDiv = styled.div`
  & p {
    width: 135px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const NavbarUserDropdown = memo(() => {
  const pathname = usePathname();
  const { getUser, logout } = useAuthenticationContext();
  const { currentUser, isSuperAdmin } = useCurrentUserContext();
  const { companyFeatures, showLogsPageFeature } = useCompanyFeatures();
  const { openModal } = useModalsContext();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const acknowledgedHighlightedFeatures = useHighlightedFeatures(
    (state) => state.acknowledgedHighlightedFeatures,
  );

  const activeHighlightIds = useMemo(
    () =>
      getHighlightedItems({ companyFeatures, isSuperAdmin })
        .filter((item) => item.active)
        .map((item) => item.id),
    [companyFeatures, isSuperAdmin],
  );

  const highlightedFeaturesCount =
    activeHighlightIds.length -
    acknowledgedHighlightedFeatures.filter((item) => activeHighlightIds.includes(item)).length;

  const userDisplayName = getUserName(currentUser) ?? getUser()?.getUsername();

  const menu: MenuProps = useMemo(
    () => ({
      activeKey: pathname,
      onClick: async ({ key }) => {
        switch (key) {
          case 'highlightedFeatures':
            openModal({ type: 'highlightedFeatures' });
            break;
          case 'about':
            openModal({ type: 'about' });
            break;
          case 'fullscreen':
            if (!document.fullscreenEnabled) {
              message.error(i18n.t('header.user.fullscreenNotSupported'));
            } else if (document.fullscreenElement) {
              document.exitFullscreen();
            } else {
              document.documentElement.requestFullscreen().catch(() => {
                message.error(i18n.t('header.user.fullscreenNotSupported'));
              });
            }
            break;
          case 'logout':
            openModal({
              type: 'confirmation',
              title: i18n.t('header.logoutModal.title'),
              description: (
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: i18n.t<string>('header.logoutModal.descriptionHtml', {
                      userCompleteName: userDisplayName,
                    }),
                  }}
                />
              ),
              actionType: 'primary',
              action: async () => {
                try {
                  await logout();
                  notification.warning({
                    message: i18n.t('general.auth.loggedOutTitle'),
                    description: i18n.t('general.auth.loggedOutDescription'),
                  });
                } catch (error) {
                  notification.error({ message: i18n.t('general.auth.logoutFailTitle') });
                  logger.error('NavbarUserDropdown: menu logout error', { error });
                }
              },
            });
            break;
          default:
            break;
        }
      },
      theme: 'light',
      items: [
        {
          key: 'username',
          label: (
            <AccountStyledLink to={routes.myAccount({ tab: 'profile' })} data-id="my-account-link">
              <ReactAvatar name={userDisplayName} round size="48px" />
              <AccountDetailsDiv>
                <p>
                  <b>{userDisplayName}</b>
                </p>
                <p>{currentUser?.email}</p>
              </AccountDetailsDiv>
            </AccountStyledLink>
          ),
        },
        {
          type: 'divider' as MenuDividerType['type'],
        },
        ...(isSuperAdmin
          ? [
              {
                key: routes.backoffice(),
                label: (
                  <Link to={routes.backoffice({ tab: 'companies' })} data-id="backoffice-link">
                    {i18n.t('header.user.backoffice')}
                  </Link>
                ),
                icon: <CloudOutlined />,
              },
              {
                type: 'divider' as MenuDividerType['type'],
              },
            ]
          : []),
        {
          key: routes.myAccount(),
          label: (
            <Link to={routes.myAccount({ tab: 'profile' })} data-id="my-account-settings-link">
              {i18n.t('header.user.myAccount')}
            </Link>
          ),
          icon: <UserOutlined />,
        },
        {
          key: routes.companySettings(),
          label: (
            <Link
              to={routes.companySettings({ tab: 'company-details' })}
              data-id="company-settings-link"
            >
              {i18n.t('header.user.companySettings')}
            </Link>
          ),
          icon: <SettingOutlined />,
        },
        ...(showLogsPageFeature
          ? [
              {
                key: routes.logs,
                label: (
                  <Link to={routes.logs} data-id="logs-link">
                    {i18n.t('header.user.logs')}
                  </Link>
                ),
                icon: <ClockCircleOutlined />,
              },
            ]
          : []),
        {
          type: 'divider' as MenuDividerType['type'],
        },
        {
          key: routes.customerSupport,
          label: (
            <Link to={routes.customerSupport} data-id="user-support-link">
              {i18n.t('header.user.customerSupport')}
            </Link>
          ),
          icon: <CustomerServiceOutlined />,
        },
        {
          key: 'highlightedFeatures',
          label: (
            <div style={{ marginRight: '20px' }} data-id="highlighted-features-btn">
              <Badge
                count={highlightedFeaturesCount}
                offset={[14, 7]}
                style={{ boxShadow: 'none' }}
                size="small"
              >
                {i18n.t('header.user.highlightedFeatures')}
              </Badge>
            </div>
          ),
          icon: <NotificationOutlined />,
        },
        {
          key: routes.userManual,
          label: (
            <Link to={routes.userManual} data-id="user-manual-link">
              {i18n.t('header.user.userManual')}
            </Link>
          ),
          icon: <BookOutlined />,
        },
        {
          key: 'about',
          label: <div data-id="about-btn">{i18n.t('header.user.about')}</div>,
          icon: <InfoCircleOutlined />,
        },
        {
          type: 'divider' as MenuDividerType['type'],
        },
        {
          key: 'fullscreen',
          label: (
            <div data-id="fullscreen-btn">
              {isFullscreen
                ? i18n.t('header.user.exitFullscreen')
                : i18n.t('header.user.enterFullscreen')}
            </div>
          ),
          icon: <BiFullscreen />,
        },
        {
          type: 'divider' as MenuDividerType['type'],
        },
        {
          key: 'logout',
          label: <div data-id="logout-btn">{i18n.t('header.user.logoutButton')}</div>,
          icon: <LogoutOutlined />,
        },
      ],
    }),
    [
      currentUser?.email,
      highlightedFeaturesCount,
      isSuperAdmin,
      isFullscreen,
      logout,
      openModal,
      pathname,
      showLogsPageFeature,
      userDisplayName,
    ],
  );

  return (
    <WrapperDiv>
      <Dropdown trigger={['click']} menu={menu} placement="bottomRight">
        <UserButton data-id="navbar-user-dropdown-btn">
          <ReactAvatar name={userDisplayName} round size="30px" />
          <span>{userDisplayName}</span>
        </UserButton>
      </Dropdown>
    </WrapperDiv>
  );
});

NavbarUserDropdown.displayName = 'NavbarUserDropdown';

export default NavbarUserDropdown;
