import orderBy from 'lodash/orderBy';

import type { Agent } from '~/types/agent';

export default function getAgentsOptions(agents: Agent[]): {
  value: string;
  label: string;
}[] {
  return orderBy(
    agents.map((agent) => {
      const label = agent.completeName;
      return {
        value: agent.id,
        label,
        labelNormalized: label.toLocaleLowerCase(),
      };
    }),
    ['labelNormalized'],
    ['asc'],
  );
}
