import { memo, useMemo } from 'react';

import SectionList, {
  type SectionListItem,
} from '~/components/DetailsPopup/components/SectionList';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import getEquipmentStatusDysfunctionsColor from '~/utils/equipment/getEquipmentStatusDysfunctionsColor';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';

interface Props {
  agent: Agent | undefined;
}

const ContentHeartRate = memo(({ agent }: Props) => {
  const { companyFeatures } = useCompanyFeatures();

  const { isWorking } = getEquipmentStatusDysfunctionsColor(
    agent?.equipmentStatus?.heartRate?.status,
  );

  const heartRateValue = formatNumberWithUnit({
    number: isWorking ? agent?.sensors?.heartRate?.value : undefined,
    unit: 'BPM',
  });

  const list: SectionListItem[] = useMemo(
    () => [
      ...(companyFeatures.heartRateSensor
        ? [
            {
              style: {
                opacity: heartRateValue === 'n/a' ? 0.4 : 1,
              },
              labelText: i18n.t('carrierDetailsPopup.physiologicalSensors.heartRate'),
              valueText: heartRateValue,
              'data-id': 'heartRatePhysiologicalSensor',
            },
          ]
        : []),
    ],
    [companyFeatures.heartRateSensor, heartRateValue],
  );

  if (!agent) {
    return null;
  }

  return <SectionList data-id="SectionPhysiologicalSensors" elements={list} />;
});

export default ContentHeartRate;
