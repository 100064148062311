import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  type ChallengeName,
  type CognitoIdToken,
  type CognitoUserSession,
  type IAuthenticationDetailsData,
} from 'amazon-cognito-identity-js';
import * as OTPAuth from 'otpauth';
import * as QRCode from 'qrcode';
import { useCallback, useMemo, useReducer, useRef } from 'react';

import { APPLICATION_NAME } from '~/config/constants';
import variables from '~/config/variables';
import i18n from '~/locales/i18n';
import {
  AWS_AUTHENTICATION_RESPONSE_TYPE,
  type AWSLoginResponse,
  type AWSPasswordResetUserAttributes,
  type CompleteNewPasswordChallengeParams,
  type ForgotPasswordParams,
  type PasswordResetParams,
  type VerifyCodeParams,
  type VerifyMFAParams,
  type VerifyMFASetupParams,
} from '~/types/awsService';
import logger from '~/utils/logger';

interface IdToken extends CognitoIdToken {
  jwtToken: string;
}

export interface AWSCognitoService {
  getUser: (Username?: string) => CognitoUser | null;
  setUserAttributes: (attributes: Record<string, string>) => void;
  getIdToken: () => Promise<IdToken | null | undefined>;
  getSession: () => Promise<CognitoUserSession | null>;
  refreshSession: () => Promise<CognitoUserSession | null>;
  isSessionValid: () => Promise<boolean>;
  login: (params: IAuthenticationDetailsData) => Promise<AWSLoginResponse>;
  logout: () => Promise<boolean>;
  verifyMFASetup: (params: VerifyMFASetupParams) => Promise<boolean>;
  verifyMFA: (params: VerifyMFAParams) => Promise<string | undefined>;
  verifyConfirmationCode: (params: VerifyCodeParams) => Promise<string | undefined>;
  completeNewPasswordChallenge: (
    params: CompleteNewPasswordChallengeParams,
  ) => Promise<AWSLoginResponse | undefined>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<'SUCCESS' | undefined>;
  forgotPassword: (params: ForgotPasswordParams) => Promise<unknown>;
  passwordReset: (params: PasswordResetParams) => Promise<string | undefined>;
}

export default function useAWSCognitoService(): AWSCognitoService | undefined {
  const [, forceUpdate] = useReducer((state: boolean) => !state, false);
  const cognitoUser = useRef<CognitoUser | null>(null);
  const userEmail = useRef<string | null>(null);

  const userPool = useMemo(
    () =>
      new CognitoUserPool({
        UserPoolId: variables.adminUserPoolId,
        ClientId: variables.dashboardClientId,
      }),
    [],
  );

  const getUser = useCallback(
    (Username?: string): CognitoUser | null => {
      if (!Username) {
        if (!cognitoUser.current) {
          cognitoUser.current = userPool?.getCurrentUser();
        }
        return cognitoUser.current;
      }
      userEmail.current = Username;
      return new CognitoUser({ Username, Pool: userPool });
    },
    [userPool],
  );

  const setUserAttributes = useCallback(
    (attributes: Record<string, string>): Promise<void> =>
      new Promise((resolve, reject) => {
        if (!cognitoUser.current) {
          reject(new Error(i18n.t<string>('general.auth.userUnableToVerify')));
          return;
        }
        const userAttributesMap: Record<string, string> = {
          first_name: 'given_name',
          last_name: 'family_name',
        };
        const attributeList = Object.entries(attributes).map(
          ([key, value]) =>
            new CognitoUserAttribute({ Name: userAttributesMap[key] || key, Value: value }),
        );
        cognitoUser.current.updateAttributes(attributeList, (error) => {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        });
      }),

    [],
  );

  const getIdToken = useCallback(
    (): Promise<IdToken | null | undefined> =>
      new Promise((resolve) => {
        if (!cognitoUser.current) {
          resolve(null);
          return;
        }
        cognitoUser.current.getSession(
          (error: Error, cognitoUserSession: CognitoUserSession | null) => {
            if (error) {
              resolve(null);
            } else {
              resolve(cognitoUserSession?.getIdToken() as IdToken);
            }
          },
        );
      }),
    [],
  );

  const getSession = useCallback(
    (): Promise<CognitoUserSession | null> =>
      new Promise((resolve, reject) => {
        if (!cognitoUser.current) {
          reject(new Error(i18n.t<string>('general.auth.userUnableToVerify')));
          return;
        }
        cognitoUser.current.getSession((error: Error, userSession: CognitoUserSession | null) => {
          if (error) {
            reject(error);
          } else {
            forceUpdate();
            resolve(userSession);
          }
        });
      }),
    [],
  );

  const refreshSession = useCallback(
    (): Promise<CognitoUserSession | null> =>
      new Promise((resolve, reject) => {
        logger.log('useAWSCognitoService: trying to refresh session');
        if (!cognitoUser.current) {
          reject(new Error('invalid cognito user'));
          return;
        }
        getSession()
          .then((session) => {
            if (session != null) {
              const refreshToken = session?.getRefreshToken();
              if (!refreshToken) {
                reject(new Error('invalid refresh token'));
                return;
              }
              cognitoUser.current?.refreshSession(
                refreshToken,
                (error: Error, userSession: CognitoUserSession | null) => {
                  if (error) {
                    reject(error);
                  } else {
                    logger.log('useAWSCognitoService: session successfully refreshed');
                    forceUpdate();
                    resolve(userSession);
                  }
                },
              );
            } else {
              forceUpdate();
              reject(new Error('session is null'));
            }
          })
          .catch((error) => reject(error));
      }),
    [getSession],
  );

  const isSessionValid = useCallback(
    (): Promise<boolean> =>
      new Promise((resolve) => {
        if (!cognitoUser.current) {
          resolve(false);
          return;
        }
        cognitoUser.current.getSession((error: Error, session: CognitoUserSession | null) => {
          forceUpdate();
          if (error || !session) {
            resolve(false);
          } else {
            resolve(session.isValid());
          }
        });
      }),
    [],
  );

  const performMfaSetup = useCallback(
    (cognitoUserLocal: CognitoUser) =>
      new Promise((resolve, reject) => {
        cognitoUserLocal.associateSoftwareToken({
          associateSecretCode: (code: string) => {
            const totp = new OTPAuth.TOTP({
              issuer: APPLICATION_NAME,
              label: userEmail.current || undefined,
              algorithm: 'SHA1',
              digits: 6,
              period: 30,
              secret: code,
            });
            QRCode.toDataURL(totp.toString())
              .then((base64Image) => resolve(base64Image))
              .catch((error: Error) => reject(error));
          },
          onFailure: (error: Error) => reject(error),
        });
      }),
    [],
  );

  const login = useCallback(
    ({ Username, Password }: IAuthenticationDetailsData): Promise<AWSLoginResponse> =>
      new Promise((resolve, reject) => {
        if (!userPool) {
          reject(new Error(i18n.t<string>('general.auth.userUnableToVerify')));
        }
        const authenticationDetails = new AuthenticationDetails({ Username, Password });
        cognitoUser.current = getUser(Username);
        cognitoUser.current?.authenticateUser(authenticationDetails, {
          onSuccess: () => {
            forceUpdate();
            resolve({ type: AWS_AUTHENTICATION_RESPONSE_TYPE.ON_SUCCESS });
          },
          onFailure: (error: Error) => {
            switch (error?.name) {
              case AWS_AUTHENTICATION_RESPONSE_TYPE.PasswordResetRequiredException:
                resolve({
                  type: error.name,
                  data: {
                    userAttributes: { email: Username },
                  },
                });
                break;
              case AWS_AUTHENTICATION_RESPONSE_TYPE.UserNotFoundException:
              case AWS_AUTHENTICATION_RESPONSE_TYPE.NotAuthorizedException:
              case AWS_AUTHENTICATION_RESPONSE_TYPE.UserNotConfirmedException:
              default:
                reject(error);
                break;
            }
          },
          newPasswordRequired: (
            userAttributes: AWSPasswordResetUserAttributes,
            requiredAttributes: unknown,
          ) => {
            forceUpdate();
            resolve({
              type: AWS_AUTHENTICATION_RESPONSE_TYPE.COMPLETE_NEW_PASSWORD_CHALLENGE_EXCEPTION,
              data: {
                userAttributes,
                requiredAttributes,
              },
            });
          },
          totpRequired: (challengeName) => {
            resolve({ type: challengeName as AWS_AUTHENTICATION_RESPONSE_TYPE.ON_SUCCESS });
          },
          mfaSetup: () => {
            if (!cognitoUser.current) {
              reject(new Error(i18n.t<string>('general.auth.userUnableToVerify')));
              return;
            }
            performMfaSetup(cognitoUser.current)
              .then((base64Image) => {
                resolve({
                  type: AWS_AUTHENTICATION_RESPONSE_TYPE.MFA_SETUP,
                  data: {
                    userAttributes: {
                      base64Image: base64Image as string,
                    },
                  },
                });
              })
              .catch((error: Error) => {
                reject(error);
              });
          },
          mfaRequired: () => {
            reject(new Error(`mfaRequired: ${i18n.t('general.defaults.featureNotSupported')}`));
          },
          selectMFAType: () => {
            reject(new Error(`selectMFAType: ${i18n.t('general.defaults.featureNotSupported')}`));
          },
          customChallenge: () => {
            reject(new Error(`customChallenge: ${i18n.t('general.defaults.featureNotSupported')}`));
          },
        });
      }),
    [userPool, getUser, performMfaSetup],
  );

  const logout = useCallback(
    (): Promise<boolean> =>
      new Promise((resolve) => {
        if (!cognitoUser.current) {
          resolve(false);
        } else {
          // GlobalSignOut does the same as RevokeToken: identity and access tokens
          // can be used until they expire (1 hour lifetime) for graphql queries
          // against our BE but they can't be used for Amazon Cognito user APIs.
          cognitoUser.current.globalSignOut({
            onSuccess: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
            onFailure: () => {
              logger.error('useAWSCognitoService: globalSignOut failure');
            },
          });
          cognitoUser.current.signOut();
          forceUpdate();
          resolve(true);
        }
      }),
    [],
  );

  const verifyMFASetup = useCallback(
    ({ code }: VerifyMFASetupParams): Promise<boolean> =>
      new Promise((resolve, reject) => {
        if (!cognitoUser.current || !userPool) {
          reject(new Error(i18n.t<string>('general.auth.userUnableToVerify')));
          return;
        }
        cognitoUser.current.verifySoftwareToken(
          code,
          userEmail.current ?? cognitoUser.current.getUsername(),
          {
            onSuccess: () => {
              forceUpdate();
              resolve(true);
            },
            onFailure: (error: Error) => reject(error),
          },
        );
      }),
    [userPool],
  );

  const verifyMFA = useCallback(
    ({ confirmationCode, mfaType }: VerifyMFAParams): Promise<string | undefined> =>
      new Promise((resolve, reject) => {
        if (!cognitoUser.current || !userPool) {
          reject(new Error(i18n.t<string>('general.auth.userUnableToVerify')));
          return;
        }
        cognitoUser.current.sendMFACode(
          confirmationCode,
          {
            onSuccess: (): void => {
              forceUpdate();
              resolve(i18n.t<string>('general.auth.codeVerifiedSuccessfully'));
            },
            onFailure: (error: Error): void => {
              forceUpdate();
              reject(error);
            },
          },
          mfaType || cognitoUser.current.getAuthenticationFlowType(),
        );
      }),
    [userPool],
  );

  const verifyConfirmationCode = useCallback(
    ({ confirmationCode }: VerifyCodeParams): Promise<string | undefined> =>
      new Promise((resolve, reject) => {
        if (!userPool || !cognitoUser.current) {
          reject(new Error(i18n.t<string>('general.auth.userUnableToVerify')));
          return;
        }
        cognitoUser.current.verifyAttribute(
          userEmail.current ?? cognitoUser.current.getUsername(),
          confirmationCode,
          {
            onSuccess: (result) => {
              forceUpdate();
              resolve(result);
            },
            onFailure: (error: Error) => {
              forceUpdate();
              reject(error);
            },
          },
        );
      }),
    [userPool],
  );

  const completeNewPasswordChallenge = useCallback(
    ({ newPassword }: CompleteNewPasswordChallengeParams): Promise<AWSLoginResponse | undefined> =>
      new Promise((resolve, reject) => {
        if (!cognitoUser.current || !userPool) {
          reject(new Error(i18n.t<string>('general.auth.userUnableToVerify')));
          return;
        }
        cognitoUser.current.completeNewPasswordChallenge(
          newPassword,
          {},
          {
            onSuccess: () => {
              forceUpdate();
              resolve({ type: AWS_AUTHENTICATION_RESPONSE_TYPE.ON_SUCCESS });
            },
            onFailure: (error: Error) => {
              forceUpdate();
              reject(error);
            },
            mfaSetup: (challengeName: ChallengeName) => {
              forceUpdate();
              if (!cognitoUser.current) {
                reject(new Error(i18n.t<string>('general.auth.userUnableToVerify')));
                return;
              }
              performMfaSetup(cognitoUser.current)
                .then((base64Image) => {
                  resolve({
                    type: challengeName as AWS_AUTHENTICATION_RESPONSE_TYPE,
                    data: {
                      userAttributes: {
                        base64Image: base64Image as string,
                      },
                    },
                  });
                })
                .catch((error: Error) => {
                  reject(error);
                });
            },
          },
        );
      }),
    [userPool, performMfaSetup],
  );

  const changePassword = useCallback(
    (oldPassword: string, newPassword: string): Promise<'SUCCESS' | undefined> =>
      new Promise((resolve, reject) => {
        if (!cognitoUser.current) {
          reject(new Error(i18n.t<string>('general.auth.userUnableToVerify')));
          return;
        }
        cognitoUser.current.changePassword(oldPassword, newPassword, (error, result) => {
          forceUpdate();
          if (error) {
            reject(error);
          } else {
            resolve(result);
          }
        });
      }),
    [],
  );

  const forgotPassword = useCallback(
    ({ email }: ForgotPasswordParams): Promise<unknown> =>
      new Promise((resolve, reject) => {
        cognitoUser.current = getUser(email);
        if (!userPool || !cognitoUser.current) {
          reject(new Error(i18n.t<string>('general.auth.userUnableToVerify')));
          return;
        }
        cognitoUser.current.forgotPassword({
          onSuccess: (result: string) => resolve(result),
          onFailure: (error: Error) => reject(error),
          inputVerificationCode: (data) => resolve(data),
        });
      }),
    [userPool, getUser],
  );

  const passwordReset = useCallback(
    ({ newPassword, confirmationCode }: PasswordResetParams): Promise<string | undefined> =>
      new Promise((resolve, reject) => {
        if (!userPool || !cognitoUser.current) {
          reject(new Error(i18n.t<string>('general.auth.userUnableToVerify')));
          return;
        }
        cognitoUser.current.confirmPassword(confirmationCode, newPassword, {
          onSuccess: (result) => {
            forceUpdate();
            resolve(result);
          },
          onFailure: (error: Error) => {
            forceUpdate();
            reject(error);
          },
        });
      }),
    [userPool],
  );

  if (!userPool) {
    return undefined;
  }

  const result: AWSCognitoService = {
    getUser,
    setUserAttributes,
    getIdToken,
    getSession,
    refreshSession,
    isSessionValid,
    login,
    logout,
    verifyMFASetup,
    verifyMFA,
    verifyConfirmationCode,
    completeNewPasswordChallenge,
    changePassword,
    forgotPassword,
    passwordReset,
  };

  return result;
}
