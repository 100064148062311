import * as colors from './colors';
import * as dimensions from './dimensions';
import * as keyframes from './keyframes';
import * as layers from './layers';
import * as medias from './medias';

const theme = {
  colors,
  dimensions,
  keyframes,
  layers,
  medias,
};

export default theme;
