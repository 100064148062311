import i18n from '~/locales/i18n';
import { ALARM_DISMISS_TYPE, type Alarm } from '~/types/alarm';

export default function getTranslatedAlarmDismissReason(
  dismissReason: Alarm['dismiss_reason'],
): string {
  const hasTranslation =
    dismissReason &&
    [ALARM_DISMISS_TYPE.Acknowledged, ALARM_DISMISS_TYPE.Cancelled].includes(
      dismissReason as ALARM_DISMISS_TYPE,
    );

  return hasTranslation
    ? i18n.t(`general.alarm.dismiss_reason.${dismissReason.toLowerCase()}`)
    : dismissReason || '';
}
