import { memo, type CSSProperties } from 'react';
import styled from 'styled-components';

const H3 = styled.h3`
  font-size: 16px;
  font-weight: 600;
`;

interface Props {
  title: string;
  'data-id'?: string;
  className?: string;
  style?: CSSProperties;
}

const TabSectionHeader = memo(({ title, 'data-id': dataId, className, style }: Props) => (
  <H3 data-id={dataId} className={className} style={style}>
    {title}
  </H3>
));

TabSectionHeader.displayName = 'TabSectionHeader';

export default TabSectionHeader;
