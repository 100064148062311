import { Space, Tooltip } from 'antd';
import { memo, Fragment, type ReactNode } from 'react';
import styled from 'styled-components';

import Text, { type Props as TextProps } from '~/components/Text';

const FullWidthDiv = styled.div<{ align?: string }>`
  position: relative;
  display: flex;
  width: 100%;
  text-align: ${(props) => (props.align ? props.align : '')};
`;

const StyledSpace = styled(Space)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 250px;
`;

const StyledTooltip = styled(Tooltip)`
  cursor: help;
`;

export interface SectionListItem {
  labelText: string;
  valueText?: ReactNode;
  tooltip?: ReactNode;
  content?: ReactNode;
  style?: TextProps['style'];
  'data-id'?: string;
}

interface Props {
  elements: SectionListItem[];
}

const SectionList = memo(({ elements }: Props) => (
  <StyledSpace>
    {elements
      .filter(({ labelText }) => labelText)
      .map((element) => {
        const item = (
          <FullWidthDiv data-id={element['data-id']}>
            <Space style={{ flexGrow: 1 }}>
              <Text weight="light">{element.labelText}</Text>
            </Space>
            {element.valueText && (
              <Text weight="light" style={element.style}>
                {element.valueText}
              </Text>
            )}
            {element.content}
          </FullWidthDiv>
        );
        return (
          <Fragment key={element.labelText}>
            {element.tooltip ? (
              <StyledTooltip placement="right" title={element.tooltip}>
                {item}
              </StyledTooltip>
            ) : (
              item
            )}
          </Fragment>
        );
      })}
  </StyledSpace>
));

SectionList.displayName = 'SectionList';

export default SectionList;
