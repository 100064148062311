import { create } from 'zustand';

import browserStorage from '~/utils/browserStorage';
import logger from '~/utils/logger';

export const mapThemeKeys = ['light', 'dark', 'standard', 'satellite'] as const;

export type MapThemeKey = (typeof mapThemeKeys)[number];

const DEFAULT_MAP_THEME: MapThemeKey = 'light';

const MAP_THEME_STORAGE_KEY = 'mapSettings_mapTheme';
const SHOW_MAP_TRAFFIC_LAYER_STORAGE_KEY = 'mapSettings_showMapTrafficLayer';
const SHOULD_FOLLOW_CARRIER_STORAGE_KEY = 'mapSettings_shouldFollowCarrier';

const useMapSettings = create<{
  // Map theme
  mapTheme: MapThemeKey;
  setMapTheme: (theme: MapThemeKey) => void;
  // Show map traffic layer
  showMapTrafficLayer: boolean;
  setShowMapTrafficLayer: (showMapTrafficLayer: boolean) => void;
  // Should follow carrier
  shouldFollowCarrier: boolean;
  setShouldFollowCarrier: (shouldFollowCarrier: boolean) => void;
}>((set) => ({
  // Map theme
  mapTheme: (browserStorage.local.get(MAP_THEME_STORAGE_KEY, true) ||
    DEFAULT_MAP_THEME) as MapThemeKey,
  setMapTheme: (theme: MapThemeKey) =>
    set((state) => {
      logger.log('useMapSettings: setMapTheme', { theme });
      const mapTheme = mapThemeKeys.includes(theme) ? theme : DEFAULT_MAP_THEME;
      browserStorage.local.set(MAP_THEME_STORAGE_KEY, mapTheme, true);
      return { ...state, mapTheme };
    }),
  // Show map traffic layer
  showMapTrafficLayer: (browserStorage.local.get(SHOW_MAP_TRAFFIC_LAYER_STORAGE_KEY, true) ||
    false) as boolean,
  setShowMapTrafficLayer: (showMapTrafficLayer: boolean) =>
    set((state) => {
      logger.log('useMapSettings: setShowMapTrafficLayer', { showMapTrafficLayer });
      browserStorage.local.set(SHOW_MAP_TRAFFIC_LAYER_STORAGE_KEY, showMapTrafficLayer, true);
      return { ...state, showMapTrafficLayer };
    }),
  // Should follow carrier
  shouldFollowCarrier: browserStorage.local.get(SHOULD_FOLLOW_CARRIER_STORAGE_KEY, true) ?? true,
  setShouldFollowCarrier: (shouldFollowCarrier: boolean) =>
    set((state) => {
      logger.log('useMapSettings: setShouldFollowCarrier', { shouldFollowCarrier });
      browserStorage.local.set(SHOULD_FOLLOW_CARRIER_STORAGE_KEY, shouldFollowCarrier, true);
      return { ...state, shouldFollowCarrier };
    }),
}));

export default useMapSettings;
