import LockOutlined from '@ant-design/icons/LockOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import { memo, useCallback, useEffect, useState, type ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from '~/components/Container';
import PageContentWrapper from '~/components/PageContentWrapper';
import PageHeader from '~/components/PageHeader';
import PageSideTabs from '~/components/PageSideTabs';
import {
  SettingsGridDiv,
  SettingsStickyDiv,
  SettingsTabContentDiv,
  SettingsTabTitleH3,
} from '~/components/settings/shared';
import routes, { type MyAccountPageTab } from '~/config/routes';
import useQueryParams from '~/hooks/useQueryParams';
import i18n from '~/locales/i18n';

import TabProfile from './components/TabProfile';
import TabSecurity from './components/TabSecurity';

const tabs: {
  key: MyAccountPageTab;
  icon: ReactNode;
}[] = [
  { key: 'profile', icon: <UserOutlined /> },
  { key: 'security', icon: <LockOutlined /> },
];

const MyAccountPage = memo(() => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const [tab, setTab] = useState<MyAccountPageTab>(
    (queryParams.tab as MyAccountPageTab) || 'profile',
  );

  useEffect(() => {
    if (queryParams.tab) {
      setTab(queryParams.tab as MyAccountPageTab);
    }
  }, [queryParams.tab]);

  const handleTabSelect = useCallback(
    (selectedTab: MyAccountPageTab) => {
      setTab(selectedTab);
      navigate(routes.myAccount({ ...queryParams, tab: selectedTab }));
    },
    [navigate, queryParams],
  );

  return (
    <PageContentWrapper>
      <Container>
        <SettingsGridDiv>
          <aside>
            <PageHeader
              title={i18n.t('myAccountPage.title')}
              subtitle={i18n.t('myAccountPage.subtitle')}
            />
            <SettingsStickyDiv>
              <PageSideTabs
                activeItemKey={tab}
                items={tabs.map(({ key, icon }) => ({
                  key,
                  dataId: `my-account-${key}-link`,
                  icon,
                  label: i18n.t(`myAccountPage.tabs.${key}`),
                  onClick: () => handleTabSelect(key),
                }))}
              />
            </SettingsStickyDiv>
          </aside>
          <SettingsTabContentDiv>
            <SettingsTabTitleH3 data-id="my-account-page-tab-title">
              {i18n.t(`myAccountPage.tabs.${tab}`)}
            </SettingsTabTitleH3>
            {tab === 'profile' && <TabProfile />}
            {tab === 'security' && <TabSecurity />}
          </SettingsTabContentDiv>
        </SettingsGridDiv>
      </Container>
    </PageContentWrapper>
  );
});

MyAccountPage.displayName = 'MyAccountPage';

export default MyAccountPage;
