import { useReactiveVar } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import useQueryAlarmConfigurationOverrideForCompany from '~/apollo/hooks/configurationAlarm/useQueryAlarmConfigurationOverrideForCompany';
import useQueryAlarmConfigurationOverrideForSubsidiary from '~/apollo/hooks/configurationAlarm/useQueryAlarmConfigurationOverrideForSubsidiary';
import useQueryAlarmConfigurationOverridesForAgents from '~/apollo/hooks/configurationAlarm/useQueryAlarmConfigurationOverridesForAgents';
import useQueryAlarmConfigurations from '~/apollo/hooks/configurationAlarm/useQueryAlarmConfigurations';
import { currentCompanyIdentifierVar } from '~/apollo/reactiveVariables/currentCompanyIdentifierVar';
import { currentSubsidiaryIdentifierVar } from '~/apollo/reactiveVariables/currentSubsidiaryIdentifierVar';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import { ALARM_TYPE } from '~/types/alarm';
import type { AlarmConfiguration } from '~/types/configurationAlarm';

interface AlarmConfigurationsReturnType {
  allAlarmPlatformConfigurations: AlarmConfiguration[];
  alarmConfigurations: AlarmConfiguration[];
  isLoading: boolean;
  refetchAlarmConfigurations: () => Promise<void>;
}

export default function useAlarmConfigurations({
  alarmType,
}: {
  alarmType: ALARM_TYPE;
}): AlarmConfigurationsReturnType {
  const { isLoading: isCurrentUserContextLoading, isSubsidiaryAdmin } = useCurrentUserContext();
  const currentCompanyIdentifier = useReactiveVar(currentCompanyIdentifierVar);
  const currentSubsidiaryIdentifier = useReactiveVar(currentSubsidiaryIdentifierVar);

  const skip = isCurrentUserContextLoading;

  // Global
  const {
    alarmConfigurations: allAlarmPlatformConfigurations,
    isLoading: isQueryAlarmConfigurationsLoading,
    refetch: refetchQueryAlarmConfigurations,
  } = useQueryAlarmConfigurations({
    skip,
  });

  // Company (Override)
  const {
    alarmConfigurationOverrideForCompany,
    isLoading: isQueryAlarmConfigurationOverrideForCompanyLoading,
    refetch: refetchQueryAlarmConfigurationOverrideForCompany,
  } = useQueryAlarmConfigurationOverrideForCompany({
    companyId: currentCompanyIdentifier,
    alarmConfigurationName: alarmType,
    skip: skip || isSubsidiaryAdmin,
  });

  // Subsidiary (Override)
  const {
    alarmConfigurationOverrideForSubsidiary,
    isLoading: isQueryAlarmConfigurationOverrideForSubsidiaryLoading,
    refetch: refetchQueryAlarmConfigurationOverrideForSubsidiary,
  } = useQueryAlarmConfigurationOverrideForSubsidiary({
    subsidiaryId: currentSubsidiaryIdentifier,
    alarmConfigurationName: alarmType,
    skip,
  });

  // Agents (Overrides)
  const {
    alarmConfigurationOverridesForAgents,
    isLoading: isQueryAlarmConfigurationOverridesForAgentsLoading,
    refetch: refetchQueryAlarmConfigurationOverridesForAgents,
  } = useQueryAlarmConfigurationOverridesForAgents({
    subsidiaryId: currentSubsidiaryIdentifier,
    alarmConfigurationName: alarmType,
    skip,
  });

  const refetchAlarmConfigurations = useCallback(async () => {
    await Promise.all([
      refetchQueryAlarmConfigurations(),
      refetchQueryAlarmConfigurationOverrideForCompany(),
      refetchQueryAlarmConfigurationOverrideForSubsidiary(),
      refetchQueryAlarmConfigurationOverridesForAgents(),
    ]);
  }, [
    refetchQueryAlarmConfigurations,
    refetchQueryAlarmConfigurationOverrideForCompany,
    refetchQueryAlarmConfigurationOverrideForSubsidiary,
    refetchQueryAlarmConfigurationOverridesForAgents,
  ]);

  const alarmConfigurations = useMemo(() => {
    if (!currentCompanyIdentifier || !currentSubsidiaryIdentifier) {
      return [];
    }
    return [
      ...allAlarmPlatformConfigurations.filter((item) => item.name === alarmType),
      alarmConfigurationOverrideForCompany,
      alarmConfigurationOverrideForSubsidiary,
      ...alarmConfigurationOverridesForAgents,
    ].filter((item) => !!item);
  }, [
    alarmType,
    currentCompanyIdentifier,
    currentSubsidiaryIdentifier,
    allAlarmPlatformConfigurations,
    alarmConfigurationOverrideForCompany,
    alarmConfigurationOverrideForSubsidiary,
    alarmConfigurationOverridesForAgents,
  ]);

  const isLoading =
    isQueryAlarmConfigurationsLoading ||
    isQueryAlarmConfigurationOverrideForCompanyLoading ||
    isQueryAlarmConfigurationOverrideForSubsidiaryLoading ||
    isQueryAlarmConfigurationOverridesForAgentsLoading;

  const value: AlarmConfigurationsReturnType = useMemo(
    () => ({
      allAlarmPlatformConfigurations,
      alarmConfigurations,
      isLoading,
      refetchAlarmConfigurations,
    }),
    [allAlarmPlatformConfigurations, alarmConfigurations, isLoading, refetchAlarmConfigurations],
  );

  return value;
}
