import { gql, useMutation } from '@apollo/client';
import { useMemo } from 'react';

export interface CreateCompanyVariables {
  name: string;
}

export interface CreateCompanyData {
  createCompany: {
    __typename: 'Company';
    id: string;
  };
}

export default function useMutationCreateCompany() {
  const [createCompany, { data, loading, error }] = useMutation<
    CreateCompanyData,
    CreateCompanyVariables
  >(
    gql`
      mutation MutationCreateCompany($name: String!) {
        createCompany(name: $name) {
          id
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return useMemo(
    () => ({
      createCompany,
      result: data?.createCompany,
      isLoading: loading,
      error,
    }),
    [createCompany, data?.createCompany, error, loading],
  );
}
