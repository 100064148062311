import { gql } from '@apollo/client';

import type { Attribute } from '~/types/attribute';

export interface CompanyDetailsQueryVariables {
  companyID: string;
}

export interface CompanyDetailsQueryData {
  company: {
    __typename: 'Company';
    id: string;
    name: string;
    attributes: Attribute[];
  };
}

export default gql`
  query QueryCompanyDetails($companyID: ID!) {
    company(id: $companyID) {
      id
      name
      attributes {
        name
        value
      }
    }
  }
`;
