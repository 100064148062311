import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export type QueryParams = Record<string, string>;

export default function useQueryParams(): QueryParams {
  const { search } = useLocation();

  return useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());

    return queryParams;
  }, [search]);
}
