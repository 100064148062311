import { gql } from '@apollo/client';

export interface SubsidiaryLogsQueryVariables {
  subsidiaryId: string;
  nextToken: string | null;
}

export interface SubsidiaryCarrierListQueryData {
  subsidiary: {
    id: string;
    logs: {
      nextToken: string | null;
      items: {
        __typename: 'Log';
        timestamp: string;
        msg: string;
        data: string; // JSON
      }[];
    };
  };
}

export default gql`
  query QuerySubsidiaryLogs($subsidiaryId: ID!, $nextToken: String) {
    subsidiary(id: $subsidiaryId) {
      id
      logs(nextToken: $nextToken, limit: 100, ascending: false) {
        nextToken
        items {
          timestamp
          msg
          data
        }
      }
    }
  }
`;
