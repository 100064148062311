import { gql, useMutation, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

interface CreateSuperAdminInput {
  email: string;
}

interface CreateSuperAdminOutput {
  createAdministrator: {
    administrator: {
      id: string;
      email: string;
      first_name: string | null;
      last_name: string | null;
    };
  };
}

// TODO: Currently not working (due to lack of permissions)

export default function useMutationCreateSuperAdmin(): {
  createSuperAdmin: (params: { variables: CreateSuperAdminInput }) => Promise<unknown>;
  isLoading: boolean;
  error?: ApolloError;
  createdSuperAdmin?: CreateSuperAdminOutput['createAdministrator'];
} {
  const [createSuperAdmin, { loading, error, data }] = useMutation<
    CreateSuperAdminOutput,
    CreateSuperAdminInput
  >(
    gql`
      mutation MutationCreateSuperAdmin($email: AWSEmail!) {
        createAdministrator(email: $email) {
          administrator {
            id
            email
            first_name
            last_name
          }
        }
      }
    `,
    {
      fetchPolicy: 'no-cache',
    },
  );

  return useMemo(
    () => ({
      createSuperAdmin,
      isLoading: loading,
      error,
      createdSuperAdmin: data?.createAdministrator,
    }),
    [createSuperAdmin, data?.createAdministrator, error, loading],
  );
}
