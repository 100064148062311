import { Modal } from 'antd';
import { memo } from 'react';

import SubsidiaryAddEditForm from '~/components/forms/SubsidiaryAddEditForm';
import i18n from '~/locales/i18n';
import type { ModalProps } from '~/types/modal';

export interface SubsidiaryAddEditModalProps {
  subsidiaryId: string | undefined;
  refetchOnCompleted: () => Promise<void>;
}

const SubsidiaryAddEditModal = memo(
  ({
    isOpen,
    onClose,
    subsidiaryId,
    refetchOnCompleted,
  }: ModalProps & SubsidiaryAddEditModalProps) => (
    <Modal
      title={i18n.t(
        `companySettingsPage.subsidiariesTab.${subsidiaryId ? 'edit' : 'create'}Subsidiary`,
      )}
      footer={null}
      centered
      width={500}
      open={isOpen}
      onCancel={onClose}
    >
      <SubsidiaryAddEditForm
        subsidiaryId={subsidiaryId}
        refetchOnCompleted={refetchOnCompleted}
        onClose={onClose}
        isInsideModal
      />
    </Modal>
  ),
);

SubsidiaryAddEditModal.displayName = 'SubsidiaryAddEditModal';

export default SubsidiaryAddEditModal;
