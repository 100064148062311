import { memo } from 'react';

import { DetailsPopupContextProvider } from './context/useDetailsPopupContext';
import DetailsPopupContent from './DetailsPopupContent';

interface Props {
  queryAgentId: string;
  isLoading: boolean;
}

const DetailsPopup = memo(({ queryAgentId, isLoading }: Props) => (
  <DetailsPopupContextProvider queryAgentId={queryAgentId}>
    <DetailsPopupContent isLoading={isLoading} />
  </DetailsPopupContextProvider>
));

DetailsPopup.displayName = 'DetailsPopup';

export default DetailsPopup;
