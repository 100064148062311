import { memo, useCallback, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';

import routes from '~/config/routes';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useUserInteractions from '~/store/useUserInteractions';
import type { RouteProps } from '~/types/route';
import browserStorage, { BROWSER_STORAGE_KEY } from '~/utils/browserStorage';
import logger from '~/utils/logger';

const PrivateRoute = memo(({ element }: RouteProps) => {
  const { isAuthenticated, getIdToken, logout, refreshSession } = useAuthenticationContext();
  const { currentUser } = useCurrentUserContext();
  const tokenExpirationTimeoutIdRef = useRef<number>();
  const { openModal } = useModalsContext();

  const userInteractedWithDocument = useUserInteractions(
    (state) => state.userInteractedWithDocument,
  );

  useEffect(() => {
    if (isAuthenticated && !userInteractedWithDocument) {
      openModal({
        type: 'welcomeBack',
      });
    }
  }, [isAuthenticated, userInteractedWithDocument, openModal]);

  const handleTokenExpiration = useCallback(async () => {
    window.clearTimeout(tokenExpirationTimeoutIdRef.current);

    if (!isAuthenticated) {
      return;
    }

    const expiresAt = ((await getIdToken())?.getExpiration() || 0) * 1000;
    const expiresIn = expiresAt - Date.now();

    logger.log(`PrivateRoute: expired token handler set up at ${new Date().toISOString()}`, {
      expiresAt,
      expiresAtDate: new Date(expiresAt),
      expiresIn,
    });

    if (expiresAt === 0) {
      return;
    }

    const handleToken = async () => {
      const latestExpiresAt = ((await getIdToken())?.getExpiration() || 0) * 1000;
      const latestExpiresIn = latestExpiresAt - Date.now();

      logger.log(`PrivateRoute: expired token handler called at ${new Date()}`, {
        expiresAtDate: new Date(expiresAt),
        latestExpiresAtDate: new Date(latestExpiresAt),
      });

      if (latestExpiresAt !== 0 && latestExpiresIn > expiresIn) {
        tokenExpirationTimeoutIdRef.current = window.setTimeout(handleToken, latestExpiresIn);
      } else {
        try {
          logger.log('PrivateRoute: refreshing session');

          const freshSession = await refreshSession();

          if (freshSession?.isValid()) {
            logger.log('PrivateRoute: token successfully refreshed');
            handleTokenExpiration();
          } else {
            throw new Error('Refreshed session not valid');
          }
        } catch (error) {
          logger.log('PrivateRoute: refreshing token failed, logging user out', { error });
          browserStorage.session.set(BROWSER_STORAGE_KEY.TOKEN_EXPIRED, true);
          logout();
        }
      }
    };

    tokenExpirationTimeoutIdRef.current = window.setTimeout(handleToken, expiresIn);
  }, [isAuthenticated, getIdToken, logout, refreshSession]);

  useEffect(
    () => () => {
      window.clearTimeout(tokenExpirationTimeoutIdRef.current);
    },
    [],
  );

  useEffect(() => {
    handleTokenExpiration();
  }, [isAuthenticated, handleTokenExpiration]);

  // Waiting for currentUser to be fetched after user authentication
  if (isAuthenticated && !currentUser?.id) {
    return null;
  }

  if (!isAuthenticated) {
    return <Navigate to={routes.login} />;
  }

  return element;
});

PrivateRoute.displayName = 'PrivateRoute';

export default PrivateRoute;
