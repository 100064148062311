import { gql } from '@apollo/client';

import type { ALARM_DISMISS_TYPE } from '~/types/alarm';

export interface DismissAlarmMutationVariables {
  alarmId: string;
  reason: ALARM_DISMISS_TYPE;
}

export interface DismissAlarmMutationData {
  dismissAlarm: {
    __typename: 'Alarm';
    id: string;
  };
}

export default gql`
  mutation MutationDismissAlarm($alarmId: ID!, $reason: AlarmDismissType!) {
    dismissAlarm(alarm_id: $alarmId, reason: $reason) {
      id
    }
  }
`;
