import LockOutlined from '@ant-design/icons/LockOutlined';
import { Input, Form } from 'antd';
import { memo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthFormButton from '~/components/forms/components/AuthFormButton';
import AuthFormHeader from '~/components/forms/components/AuthFormHeader';
import PasswordPolicyPopover from '~/components/PasswordPolicyPopover';
import routes from '~/config/routes';
import useAuthenticationContext from '~/context/useAuthenticationContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import {
  AWS_AUTHENTICATION_RESPONSE_TYPE,
  type AWSSetupMfaUserAttributes,
} from '~/types/awsService';
import browserStorage, { BROWSER_STORAGE_KEY } from '~/utils/browserStorage';
import notification from '~/utils/notification';

interface FormFields {
  newPassword: string;
  confirmPassword: string;
}

const NewPasswordChallengePage = memo(() => {
  const { completeNewPasswordChallenge } = useAuthenticationContext();
  const navigate = useNavigate();
  const [form] = Form.useForm<FormFields>();

  const [isLoading, setLoading] = useState<boolean>(false);

  const compareToFirstPassword = useCallback(
    (rule: object, value: string) => {
      if (value && value !== form.getFieldValue('newPassword')) {
        return Promise.reject(Error(i18n.t<string>('newPasswordChallenge.passwordsMismatch')));
      }
      return Promise.resolve();
    },
    [form],
  );

  const handleFinish = useCallback(
    ({ newPassword }: FormFields) => {
      setLoading(true);
      completeNewPasswordChallenge({ newPassword: newPassword.trim() })
        .then((response) => {
          switch (response?.type) {
            case AWS_AUTHENTICATION_RESPONSE_TYPE.MFA_SETUP:
              notification.success({
                message: i18n.t('newPasswordChallenge.successTitle'),
                description: i18n.t('newPasswordChallenge.mfaSetupDescription'),
              });
              navigate(
                routes.mfa({
                  base64Image: (response.data?.userAttributes as AWSSetupMfaUserAttributes)
                    .base64Image,
                }),
              );
              break;
            case AWS_AUTHENTICATION_RESPONSE_TYPE.ON_SUCCESS:
            default:
              notification.success({
                message: i18n.t('newPasswordChallenge.successTitle'),
                description: i18n.t('newPasswordChallenge.successDescription'),
              });
              browserStorage.local.set(BROWSER_STORAGE_KEY.LOGIN_TIME, Date.now());
              navigate(routes.map());
              break;
          }
        })
        .catch((catchedError: Error) => {
          notification.error({
            message: i18n.t('newPasswordChallenge.failTitle'),
            description: catchedError.message,
          });
          setLoading(false);
        });
    },
    [completeNewPasswordChallenge, navigate],
  );

  return (
    <Form form={form} onFinish={handleFinish}>
      <AuthFormHeader
        title={i18n.t('newPasswordChallenge.title')}
        description={i18n.t('newPasswordChallenge.description')}
      />
      <PasswordPolicyPopover>
        <Form.Item
          name="newPassword"
          rules={[
            {
              required: true,
              message: i18n.t<string>('newPasswordChallenge.invalidNewPassword'),
            },
          ]}
        >
          <Input.Password
            type="password"
            required
            addonBefore={<LockOutlined style={{ color: theme.colors.black }} />}
            placeholder={i18n.t<string>('newPasswordChallenge.newPassword')}
          />
        </Form.Item>
      </PasswordPolicyPopover>
      <Form.Item
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[
          {
            required: true,
            message: i18n.t<string>('newPasswordChallenge.invalidConfirmPassword'),
          },
          { validator: compareToFirstPassword },
        ]}
      >
        <Input.Password
          type="password"
          required
          addonBefore={<LockOutlined style={{ color: theme.colors.black }} />}
          placeholder={i18n.t<string>('newPasswordChallenge.confirmPassword')}
        />
      </Form.Item>
      <AuthFormButton loading={isLoading}>{i18n.t('newPasswordChallenge.submit')}</AuthFormButton>
    </Form>
  );
});

NewPasswordChallengePage.displayName = 'NewPasswordChallengePage';

export default NewPasswordChallengePage;
