import { memo, useMemo } from 'react';

import theme from '~/theme';

interface Props {
  bars: number | undefined;
  status: 'connected' | 'error';
}

const ConnectionIcon = memo(({ bars, status }: Props) => {
  const { colorFilled, colorEmpty } = useMemo(() => {
    if (status === 'connected') {
      return {
        colorFilled: theme.colors.primaryBlue,
        colorEmpty: '#A7BFF1',
      };
    }
    return {
      colorFilled: theme.colors.red,
      colorEmpty: '#A7BFF1',
    };
  }, [status]);

  const activeBarsPath = useMemo(() => {
    if (!bars) {
      return null;
    }
    if (bars >= 4) {
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3787 0.47052H21.967C21.1873 0.47052 20.5552 1.10259 20.5552 1.8823V14.1177C20.5552 14.8974 21.1873 15.5295 21.967 15.5295H23.3787C24.1585 15.5295 24.7905 14.8974 24.7905 14.1177V1.8823C24.7905 1.10259 24.1585 0.47052 23.3787 0.47052ZM15.379 3.76464H16.7908C17.5705 3.76464 18.2026 4.39672 18.2026 5.17642V14.1177C18.2026 14.8974 17.5705 15.5295 16.7908 15.5295H15.379C14.5993 15.5295 13.9673 14.8974 13.9673 14.1177V5.17642C13.9673 4.39672 14.5993 3.76464 15.379 3.76464ZM10.2022 7.05877H8.79039C8.01069 7.05877 7.37862 7.69084 7.37862 8.47055V14.1177C7.37862 14.8974 8.01069 15.5294 8.79039 15.5294H10.2022C10.9819 15.5294 11.6139 14.8974 11.6139 14.1177V8.47055C11.6139 7.69084 10.9819 7.05877 10.2022 7.05877ZM3.61408 9.88217H2.2023C1.4226 9.88217 0.790527 10.5142 0.790527 11.2939V14.1175C0.790527 14.8972 1.4226 15.5293 2.2023 15.5293H3.61408C4.39378 15.5293 5.02586 14.8972 5.02586 14.1175V11.2939C5.02586 10.5142 4.39378 9.88217 3.61408 9.88217Z"
          fill={colorFilled}
        />
      );
    }
    if (bars >= 3) {
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3787  3.76464H16.7908C17.5705 3.76464 18.2026 4.39672 18.2026 5.17642V14.1177C18.2026 14.8974 17.5705 15.5295 16.7908 15.5295H15.379C14.5993 15.5295 13.9673 14.8974 13.9673 14.1177V5.17642C13.9673 4.39672 14.5993 3.76464 15.379 3.76464ZM10.2022 7.05877H8.79039C8.01069 7.05877 7.37862 7.69084 7.37862 8.47055V14.1177C7.37862 14.8974 8.01069 15.5294 8.79039 15.5294H10.2022C10.9819 15.5294 11.6139 14.8974 11.6139 14.1177V8.47055C11.6139 7.69084 10.9819 7.05877 10.2022 7.05877ZM3.61408 9.88217H2.2023C1.4226 9.88217 0.790527 10.5142 0.790527 11.2939V14.1175C0.790527 14.8972 1.4226 15.5293 2.2023 15.5293H3.61408C4.39378 15.5293 5.02586 14.8972 5.02586 14.1175V11.2939C5.02586 10.5142 4.39378 9.88217 3.61408 9.88217Z"
          fill={colorFilled}
        />
      );
    }
    if (bars >= 2) {
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3787  7.05877H8.79039C8.01069 7.05877 7.37862 7.69084 7.37862 8.47055V14.1177C7.37862 14.8974 8.01069 15.5294 8.79039 15.5294H10.2022C10.9819 15.5294 11.6139 14.8974 11.6139 14.1177V8.47055C11.6139 7.69084 10.9819 7.05877 10.2022 7.05877ZM3.61408 9.88217H2.2023C1.4226 9.88217 0.790527 10.5142 0.790527 11.2939V14.1175C0.790527 14.8972 1.4226 15.5293 2.2023 15.5293H3.61408C4.39378 15.5293 5.02586 14.8972 5.02586 14.1175V11.2939C5.02586 10.5142 4.39378 9.88217 3.61408 9.88217Z"
          fill={colorFilled}
        />
      );
    }
    if (bars >= 1) {
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3787 9.88217H2.2023C1.4226 9.88217 0.790527 10.5142 0.790527 11.2939V14.1175C0.790527 14.8972 1.4226 15.5293 2.2023 15.5293H3.61408C4.39378 15.5293 5.02586 14.8972 5.02586 14.1175V11.2939C5.02586 10.5142 4.39378 9.88217 3.61408 9.88217Z"
          fill={colorFilled}
        />
      );
    }
    return null;
  }, [bars, colorFilled]);

  if (typeof bars === 'undefined') {
    return null;
  }

  return (
    <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3787 0.47052H21.967C21.1873 0.47052 20.5552 1.10259 20.5552 1.8823V14.1177C20.5552 14.8974 21.1873 15.5295 21.967 15.5295H23.3787C24.1585 15.5295 24.7905 14.8974 24.7905 14.1177V1.8823C24.7905 1.10259 24.1585 0.47052 23.3787 0.47052ZM15.379 3.76464H16.7908C17.5705 3.76464 18.2026 4.39672 18.2026 5.17642V14.1177C18.2026 14.8974 17.5705 15.5295 16.7908 15.5295H15.379C14.5993 15.5295 13.9673 14.8974 13.9673 14.1177V5.17642C13.9673 4.39672 14.5993 3.76464 15.379 3.76464ZM10.2022 7.05877H8.79039C8.01069 7.05877 7.37862 7.69084 7.37862 8.47055V14.1177C7.37862 14.8974 8.01069 15.5294 8.79039 15.5294H10.2022C10.9819 15.5294 11.6139 14.8974 11.6139 14.1177V8.47055C11.6139 7.69084 10.9819 7.05877 10.2022 7.05877ZM3.61408 9.88217H2.2023C1.4226 9.88217 0.790527 10.5142 0.790527 11.2939V14.1175C0.790527 14.8972 1.4226 15.5293 2.2023 15.5293H3.61408C4.39378 15.5293 5.02586 14.8972 5.02586 14.1175V11.2939C5.02586 10.5142 4.39378 9.88217 3.61408 9.88217Z"
        fill={colorEmpty}
      />
      {activeBarsPath}
    </svg>
  );
});

ConnectionIcon.displayName = 'ConnectionIcon';

export default ConnectionIcon;
