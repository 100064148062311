import { AGENT_STATUS } from '~/types/agent';

export default function computeAgentStatus({
  hasAlarm,
  isConnectionLost,
  inSafeZone,
}: {
  hasAlarm: boolean;
  isConnectionLost: boolean;
  inSafeZone: boolean;
}): AGENT_STATUS {
  if (hasAlarm) {
    return AGENT_STATUS.alert;
  }

  if (isConnectionLost) {
    return AGENT_STATUS.connectionLost;
  }

  return inSafeZone ? AGENT_STATUS.inSafeZone : AGENT_STATUS.inMission;
}
